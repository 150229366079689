import { db } from "../Config";

// Verifica si la empresa tiene medicamentos configurados
export const tieneMedicamentosConfigurados = async (empresaId) => {
  try {
    const snapshot = await db
      .collection("medicamentos")
      .where("empresaId", "==", empresaId)
      .limit(1)
      .get();
    return !snapshot.empty;
  } catch (error) {
    console.error("Error al verificar medicamentos:", error);
    throw error;
  }
};

// Prepara los datos del medicamento para guardar en la base de datos
export const prepararDatosMedicamento = (medicamentoData, empresaId) => {
  return {
    medicina: medicamentoData.medicina,
    concentracion: medicamentoData.concentracion,
    dosisYFrecuencia: medicamentoData.dosisYFrecuencia,
    duracion: medicamentoData.duracion,
    cantidad: typeof medicamentoData.cantidad === 'string' 
      ? parseInt(medicamentoData.cantidad, 10) || 0 
      : medicamentoData.cantidad || 0,
    formato: medicamentoData.formato,
    viaAdministracion: medicamentoData.viaAdministracion,
    empresaId: empresaId,
    updatedAt: new Date(),
    createdAt: medicamentoData.createdAt || new Date(),
    fechaRegistro: medicamentoData.fechaRegistro || new Date()
  };
};

// Obtiene los medicamentos genéricos (empresaId = '0')
export const getMedicamentosGenericos = async () => {
  try {
    const snapshot = await db
      .collection("medicamentos")
      .where("empresaId", "==", "0")
      .get();
    
    if (snapshot.empty) {
      console.log("No hay medicamentos genéricos disponibles.");
      return [];
    }
    
    const medicamentosGenericos = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    
    return medicamentosGenericos;
  } catch (error) {
    console.error("Error al obtener medicamentos genéricos:", error);
    throw error;
  }
};
export const copiarMedicamentosGenericos = async (empresaId) => {
  try {
    // Paso 1: Obtener medicamentos genéricos (empresaId = '0')
    const snapshot = await db
      .collection("medicamentos")
      .where("empresaId", "==", "0")
      .get();

    // Verificar si hay medicamentos genéricos
    if (snapshot.empty) {
      console.log("No hay medicamentos genéricos para copiar.");
      return [];
    }

    // Preparar el batch y un arreglo para los IDs de los nuevos documentos
    const batch = db.batch();
    const nuevosMedicamentosIds = [];

    // Paso 2: Crear copias de los medicamentos genéricos
    snapshot.forEach((doc) => {
      const medicamentoGenerico = doc.data();
      
      // Crear un nuevo objeto para cada medicamento, manteniendo solo los campos necesarios
      const nuevoMedicamento = {
        medicina: medicamentoGenerico.medicina || "",
        concentracion: medicamentoGenerico.concentracion || "",
        formato: medicamentoGenerico.formato || "",
        cantidad: medicamentoGenerico.cantidad || 1,
        dosisYFrecuencia: medicamentoGenerico.dosisYFrecuencia || "",
        duracion: medicamentoGenerico.duracion || "",
        viaAdministracion: medicamentoGenerico.viaAdministracion || "",
        empresaId: empresaId, // Asignar el nuevo empresaId
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      // Generar una nueva referencia de documento
      const newDocRef = db.collection("medicamentos").doc();
      batch.set(newDocRef, nuevoMedicamento); // Agregar al batch
      nuevosMedicamentosIds.push(newDocRef.id); // Guardar el ID
    });

    // Paso 3: Ejecutar todas las operaciones en lote
    await batch.commit();

    console.log("Medicamentos genéricos copiados exitosamente para la empresa:", empresaId);
    return nuevosMedicamentosIds; // Devolver los IDs de los nuevos documentos
  } catch (error) {
    console.error("Error al copiar medicamentos genéricos:", error);
    throw error; // Lanzar el error para manejarlo en el componente que llama la función
  }
};
export const addMedicamentosM = (idEmpresa, medicamentoData) => {
  return async function (dispatch, getState) {
    try {
     
      
      
      // Agregar el nuevo medicamento
      const nuevoMedicamento = {
        ...medicamentoData,
        empresaId: idEmpresa,
        createdAt: new Date(),  // Fecha local como respaldo
        updatedAt: new Date(),  // Fecha local como respaldo
        cantidad: parseInt(medicamentoData.cantidad) || 0,
      };

      // Agregar documento con ID auto-generado
      const docRef = await db.collection('medicamentos').add(nuevoMedicamento);
      
    

      return docRef.id;
    } catch (error) {
      console.error("Error al guardar medicamento:", error);
      throw error;
    }
  };
};

// Obtiene los medicamentos por empresa
export const fetchMedicamentosByEmpresa = async (empresaId) => {
  try {
    const snapshot = await db
      .collection("medicamentos")
      .where("empresaId", "==", empresaId)
      .get();
    
    let medicamentos = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    
    // Ordenar los resultados en JavaScript después de obtenerlos
    medicamentos.sort((a, b) => {
      // Asegurarse de que sean objetos Date para comparación correcta
      const dateA = a.createdAt instanceof Date ? a.createdAt : new Date(a.createdAt);
      const dateB = b.createdAt instanceof Date ? b.createdAt : new Date(b.createdAt);
      return dateB - dateA; // Orden descendente
    });
    
    return medicamentos;
  } catch (error) {
    console.error("Error al obtener medicamentos:", error);
    throw error;
  }
};

// Función para obtener un medicamento por ID - No debe ser parte de los componentes React
export const fetchMedicamentoById = async (empresaId, medicamentoId) => {
  try {
    const doc = await db.collection("medicamentos").doc(medicamentoId).get();
    if (!doc.exists) {
      throw new Error("El medicamento no existe");
    }
    return {
      id: doc.id,
      ...doc.data(),
    };
  } catch (error) {
    console.error("Error al cargar el medicamento:", error);
    throw error;
  }
};
// Actualiza un medicamento
export const updateMedicamentosM = async (id, empresaId, medicamentoData) => {
  try {
    const updateData = {
      ...medicamentoData,
      
      updatedAt: new Date(),
    };
    await db.collection("medicamentos").doc(id).update(updateData);
    return id;
  } catch (error) {
    console.error("Error al actualizar medicamento:", error);
    throw error;
  }
};

export const deleteMedicamento = (id, idEmpresa) => {
  return async (dispatch) => {
    try {
      // 1. Validación básica de los parámetros
      if (!id || typeof id !== "string") {
        throw new Error("ID de medicamento inválido");
      }
      if (!idEmpresa || typeof idEmpresa !== "string") {
        throw new Error("ID de empresa inválido");
      }

      // 2. Obtener referencia al documento
      const docRef = db.collection('medicamentos').doc(id);
      
      // 3. Verificar existencia del documento
      const docSnapshot = await docRef.get();
      if (!docSnapshot.exists) {
        throw new Error("El medicamento no existe en la base de datos");
      }

      // 4. Verificar que el empresaId coincida con idEmpresa
      const medicamentoData = docSnapshot.data();
      if (medicamentoData.empresaId !== idEmpresa) {
        throw new Error("No se puede eliminar este medicamento porque no pertenece a la empresa especificada");
      }

      // 5. Ejecutar la eliminación
      await docRef.delete();

      // 6. Actualizar el estado global
      dispatch({
        type: 'MEDICAMENTO_DELETED',
        payload: id
      });

      // 7. Retornar el ID para posibles usos posteriores
      return id;

    } catch (error) {
      console.error("Error en deleteMedicamento:", error);
      throw error; // Propagar el error para manejo superior
    }
  };
};