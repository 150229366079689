import React, { useCallback, useEffect, useState } from "react";
import { useBuscarPacientes } from "../../hooks/useBuscarPacientesDebounce";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import DatePicker from 'react-datepicker';
import esLocale from '@fullcalendar/core/locales/es';
import { useToasts } from "react-toast-notifications";
import { AutocompletePaciente } from "../Autocomplete-Paciente";
import { getHorarioEspecialistaById } from "../../services/firebase/horariosEspecialistas";
import { saveCites, getCitesByBusinessId, updateCiteById, deleteCiteById } from "../../services/firebase/citas";
import { savePaciente } from "../../services/firebase/pacientes";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./styles.css";
import Spinner from "react-bootstrap/Spinner";

const CitaItem = () => {
    const [citasCalendar, setCitasCalendar] = useState([]);
    const [ajustesHorariosReservada, setAjustesHorariosReservada] = useState({});
    const [usuario] = useLocalStorage("usuario");
    const [showModal, setShowModal] = useState(false);
    const [showModalReserva, setShowModalReserva] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [paciente, setPaciente] = useState({});
    const [editedTimeInit, setEditedTimeInit] = useState("");
    const [editedTimeEnd, setEditedTimeEnd] = useState("");
    const [editedDateInit, setEditedDateInit] = useState("");
    const [editedDateEnd, setEditedDateEnd] = useState("");
    const [error, setError] = useState(null);
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        horariosEspecialistasConfig();
    }, [usuario]);

    const handleChange = (e) => {
        if (!e || !e.target) return;
        const { name, value } = e.target;
        setPaciente((prevPaciente) => ({
            ...prevPaciente,
            [name]: value,
        }));
    };

    const horariosEspecialistasConfig = async () => {
        try {
            if (!usuario?.empresaId) return;
            const empresaId = usuario.empresaId;
            const [citas, ajustes] = await Promise.all([
                getCitesByBusinessId(empresaId),
                getHorarioEspecialistaById(empresaId)
            ]);
            setCitasCalendar(citas);
            setAjustesHorariosReservada(ajustes);
        } catch (error) {
            setError('Error al consultar a la base de datos');
        }
    };

    const convertToDate = (timestamp) => new Date(timestamp.seconds * 1000);

    const createEvent = ({ id, title, start, end, backgroundColor, textColor, editable, extendedProps }) => ({
        id,
        title,
        start: new Date(start),
        end: new Date(end),
        backgroundColor,
        textColor,
        editable,
        extendedProps
    });

    const isOverlapping = (startTime, endTime, citas) =>
        citas.find(cita => {
            const citaStart = convertToDate(cita.start.dateTime);
            const citaEnd = convertToDate(cita.end.dateTime);
            return (startTime >= citaStart && startTime < citaEnd) || (endTime > citaStart && endTime <= citaEnd);
        });

    const getDisponibilidadDia = (ajustes, dayName) =>
        ajustes?.disponibilidad.find(d => d.dia === dayName)?.horarios || [];



    const generateAvailability = () => {
        if (!ajustesHorariosReservada?.disponibilidad || !citasCalendar) return [];
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        const citasPasadas = citasCalendar
            .filter(cita => {
                const citaStart = convertToDate(cita.start.dateTime);
                return citaStart >= oneMonthAgo && citaStart <= today;
            })
            .map(cita => createEvent({
                id: cita.id,
                title: cita.attendees[0].name.split(" ")[0].toUpperCase(),
                start: convertToDate(cita.start.dateTime),
                end: convertToDate(cita.end.dateTime),
                backgroundColor: "RGB(96, 191, 255)",
                textColor: "white",
                editable: false,
                extendedProps: { isBlocked: true }
            }));

        const dias = Number(ajustesHorariosReservada?.franjaProgramacion?.anticipacionMaxima?.dias) || 0;
        const fechaLimite = new Date(today);
        fechaLimite.setDate(fechaLimite.getDate() + dias);

        let currentDate = new Date(today);

        const disponibilidadEventos = [];

        while (currentDate <= fechaLimite) {
            const dayName = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"][currentDate.getDay()];
            const horarios = getDisponibilidadDia(ajustesHorariosReservada, dayName);

            horarios.forEach(({ inicio, fin }) => {
                let startTime = setTimeToDate(currentDate, inicio);
                const horarioFin = setTimeToDate(currentDate, fin);

                while (startTime < horarioFin) {
                    const endTime = new Date(startTime.getTime() + ajustesHorariosReservada.duracionCitaMin * 60000);
                    if (endTime > horarioFin) break;

                    const citaOcupada = isOverlapping(startTime, endTime, citasCalendar);
                    disponibilidadEventos.push(createEvent({
                        id: citaOcupada ? citaOcupada.id : `${currentDate.toISOString().split("T")[0]}-${formatTime(startTime)}`,
                        title: citaOcupada ? citaOcupada.attendees[0].name.split(" ")[0].toUpperCase() : "Disponible",
                        start: citaOcupada ? convertToDate(citaOcupada.start.dateTime) : startTime,
                        end: citaOcupada ? convertToDate(citaOcupada.end.dateTime) : endTime,
                        backgroundColor: citaOcupada ? "RGB(3, 155, 229)" : "RGB(234, 234, 234)",
                        textColor: citaOcupada ? "white" : "black",
                        editable: true,
                        extendedProps: citaOcupada ? { ...citaOcupada } : {}
                    }));

                    startTime = new Date(endTime.getTime() + ajustesHorariosReservada.ajustesCitaReservada.duracionPeriodoEntreCitas.tiempo * 60000);
                }
            });

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return [...citasPasadas, ...disponibilidadEventos];
    };

    const setTimeToDate = (date, time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return new Date(date.setHours(hours, minutes, 0, 0));
    };

    const formatTime = date => date.toTimeString().split(" ")[0];


    const handleDateClick = (eventClickInfo) => {
        setPaciente({});
        const selectedEvent = eventClickInfo.event;
        const isMobile = window.innerWidth <= 768;
        if (selectedEvent.title === "Disponible") {
            setSelectedEvent(selectedEvent);
            setShowModalReserva(true);
        } else {
            setTimeout(() => {
                setSelectedEvent({
                    id: selectedEvent.id,
                    title: selectedEvent.title,
                    start: selectedEvent.startStr,
                    end: selectedEvent.endStr,
                    x: isMobile
                        ? window.innerWidth / 2
                        : (eventClickInfo.jsEvent?.clientX || window.innerWidth / 2) - 400,
                    y: isMobile
                        ? window.innerHeight / 2
                        : (eventClickInfo.jsEvent?.clientY || window.innerHeight / 2) - 10,
                    extendedProps: selectedEvent.extendedProps
                });
                setShowModal(true);
            }, 0);
        }
    };


    const closeModalEvent = (e) => {
        if (!e.target.closest(".event-modal")) {
            setShowModal(false);
        }
    };

    const handleSaveAppointment = async () => {
        try {
            if (!paciente.nombres || !paciente.correo || !paciente.telefono || !paciente.numDoc) {
                addToast("Completar todos los campos", { appearance: "error" });
                return false;
            }
            if (!paciente.id) {
                const { id } = await savePaciente({ ...paciente, empresaId: usuario.empresaId });
                paciente.id = id;
            }
            const citas = mapperEventData();
            await saveCites(citas);
            setShowModalReserva(false);
            addToast("Guardado satisfactorio", { appearance: "success" });
            await Promise.all([horariosEspecialistasConfig(), generateAvailability()]);
        } catch (error) {
            addToast(error.message, { appearance: "error" });
        }

    };

    const handleEditedAppointment = async () => {
        try {
            if (!validateTimeDateCites()) return;
            const updatedEvent = {
                ...selectedEvent,
                attendees: [mapperEventAttendees()],
                start: {
                    dateTime: combineDateAndTime(editedDateInit, editedTimeInit),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                end: {
                    dateTime: combineDateAndTime(editedDateEnd, editedTimeEnd),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                updated_at: new Date(),
            };
            await updateCiteById(updatedEvent.id, updatedEvent);
            setShowModalReserva(false);
            addToast("Se actualizó el evento", { appearance: "success" });
            await Promise.all([horariosEspecialistasConfig(), generateAvailability()]);
        } catch (error) {
            addToast(error.message || "Error al actualizar la cita", { appearance: "error" });
        }
    };


    const validateTimeDateCites = () => {
        if (!validateSameDay()) {
            addToast("Las fechas deben ser del mismo día", { appearance: "error" });
            return false;
        }
        if (editedTimeEnd <= editedTimeInit) {
            addToast("La hora seleccionada es inválida", { appearance: "error" });
            return false;
        }
        if (validateAppointmentTime()) {
            addToast("La hora seleccionada se encuentra ocupada", { appearance: "error" });
            return false;
        }

        return true;
    }

    const combineDateAndTime = (date, time) => {
        if (!date || !time) return null;
        const dateObj = date instanceof Date ? date : new Date(date);
        const timeObj = typeof time === "number" ? new Date(time) : time instanceof Date ? time : new Date(time);
        if (isNaN(dateObj.getTime()) || isNaN(timeObj.getTime())) return null;
        return new Date(
            dateObj.getFullYear(), dateObj.getMonth(),
            dateObj.getDate(), timeObj.getHours(), timeObj.getMinutes(), 0, 0
        );
    };

    const mapperEventData = () => {
        return {
            calendarId: usuario.empresaId,
            summary: "Cita médica",
            description: "Consulta general",
            start: {
                dateTime: selectedEvent.start,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            end: {
                dateTime: selectedEvent.end,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            attendees: [
                mapperEventAttendees(),
            ],
            create_at: new Date(),
            reminders: {
                useDefault: false,
                overrides: [
                    {
                        method: "email",
                        minutes: 24 * 60
                    }
                ]
            },
            extendedProperties: {
                private: {
                    businessId: usuario.empresaId,
                    specialistId: usuario.id
                }
            }
        }
    };

    const validateSameDay = () => {
        if (!editedDateInit || !editedDateEnd) return true;
        const fechaInicio = editedDateInit.toISOString().split('T')[0];
        const fechaFin = editedDateEnd.toISOString().split('T')[0];

        return fechaInicio === fechaFin;
    };


    const validateAppointmentTime = () => {
        if (!editedTimeInit || !editedTimeEnd) return true;

        const editedStart = combineDateAndTime(editedDateInit, editedTimeInit);
        const editedEnd = combineDateAndTime(editedDateEnd, editedTimeEnd);

        const citasFiltradas = citasCalendar.filter(cita => {
            if (!cita.start?.dateTime?.seconds || !cita.end?.dateTime?.seconds) return false;
            const citaDate = new Date(cita.start.dateTime.seconds * 1000).toISOString().split("T")[0];
            const editedDate = new Date(editedDateInit).toISOString().split("T")[0];
            return citaDate === editedDate && cita.id !== selectedEvent.id;
        });

        const isOverlapping = citasFiltradas.some(cita => {
            const citaStart = new Date(cita.start.dateTime.seconds * 1000);
            const citaEnd = new Date(cita.end.dateTime.seconds * 1000);

            return (
                (editedStart >= citaStart && editedStart < citaEnd) ||
                (editedEnd > citaStart && editedEnd <= citaEnd) ||
                (editedStart <= citaStart && editedEnd >= citaEnd)
            );
        });

        return isOverlapping;
    };



    const mapperEventAttendees = () => {
        return {
            patientId: paciente.id || null,
            name: paciente.nombres,
            email: paciente.correo,
            phone: paciente.telefono,
            dni: paciente.numDoc
        }
    };

    const closeModalReserva = () => {
        setShowModalReserva(false);
    };

    const handleEdit = (event) => {
        if (!event?.extendedProps) return;
        const { start, end, attendees = [] } = event.extendedProps;
        const attendee = attendees.length > 0 ? attendees[0] : null;
        const dateTimeInit = start?.dateTime?.seconds ? new Date(start.dateTime.seconds * 1000) : new Date();
        const dateTimeEnd = end?.dateTime?.seconds ? new Date(end.dateTime.seconds * 1000) : new Date();
        setSelectedEvent({
            ...event.extendedProps,
            start: dateTimeInit,
            end: dateTimeEnd,
        });
        setEditedTimeInit(new Date(dateTimeInit)
            .setHours(dateTimeInit.getHours(), dateTimeInit.getMinutes()));
        setEditedTimeEnd(new Date(dateTimeEnd)
            .setHours(dateTimeEnd.getHours(), dateTimeEnd.getMinutes()));
        setEditedDateInit(new Date(dateTimeInit.getFullYear(), dateTimeInit.getMonth(), dateTimeInit.getDate()));
        setEditedDateEnd(new Date(dateTimeEnd.getFullYear(), dateTimeEnd.getMonth(), dateTimeEnd.getDate()));
        if (attendee) {
            setPaciente({
                id: attendee.patientId || "",
                nombres: attendee.name || "",
                correo: attendee.email || "",
                telefono: attendee.phone || "",
                numDoc: attendee.dni || "",
            });
        }
        setShowModalReserva(true);
        setShowModal(false);
    };


    const handleDeleteClick = async (event) => {
        try {
            const eventId = event.id;
            const response = await deleteCiteById(eventId);
            setShowModal(false);
            addToast(response.message, { appearance: "success" });
            await Promise.all([horariosEspecialistasConfig(), generateAvailability()]);
        } catch (error) {
            console.error("Error al eliminar el evento:", error);
        }
    }

    const formatEventDate = (start, end) => {
        const optionsDate = { weekday: 'long', day: 'numeric', month: 'long' };
        const optionsTime = { hour: 'numeric', minute: '2-digit', hour12: true };
        const formattedDate = new Date(start).toLocaleDateString('es-ES', optionsDate);
        const formattedStartTime = new Date(start).toLocaleTimeString('es-ES', optionsTime);
        const formattedEndTime = new Date(end).toLocaleTimeString('es-ES', optionsTime);

        return `${formattedDate} · ${formattedStartTime} – ${formattedEndTime}`;
    };

    const getTimeZone = () => {
        const timeZone = new Intl.DateTimeFormat('es-PE', { timeZoneName: 'long' })
            .formatToParts(new Date())
            .find(part => part.type === 'timeZoneName')?.value || '';
        return `(GMT-05:00) ${timeZone.replace('hora estándar', 'Hora estándar')}`;
    };

    const handleSelected = (item) => {
        if (item.onSelected) {
            const pacienteSeleccionado = {
                ...item,
                nombres: `${item.nombres} ${item.apellidos || ''}`,
            };
            setPaciente(pacienteSeleccionado);
        } else {
            paciente.nombres = item.nombres
        }
    };

    const toString = (item) => {
        return `${item.nombres} ${item.apellidos || ''}`;
    };


    const headerToolbar = window.innerWidth < 768
        ? { start: "prev,next", center: "title", end: "" }
        : { start: "today prev,next", center: "title", end: "timeGridWeek,timeGridDay" };

    if (loading)
        return (

            <Spinner animation="border" role="status">
                <span className="visually-hidden"></span>
            </Spinner>

        );

    return (
        <>
            <div className="cita-item-container" onClick={closeModalEvent}>
                <div className="calendar">
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView="timeGridWeek"
                        headerToolbar={headerToolbar}
                        locale={esLocale}
                        events={generateAvailability()}
                        eventClick={handleDateClick}
                        editable={true}
                        slotDuration="00:15:00"
                        slotMinTime="08:00:00"
                        slotMaxTime="21:00:00"
                        allDaySlot={false}
                        themeSystem="Litera"
                        eventDisplay="block"
                        slotLabelFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                        }}
                        eventOverlap={true}
                        eventBorderColor="#ffffff"
                        eventBackgroundColor="#ff5733"
                        height={window.innerWidth < 768 ? "auto" : "auto"}
                        contentHeight={window.innerWidth < 768 ? "auto" : "auto"}
                    />

                </div>
                {showModalReserva && selectedEvent && (
                    <div className="modal">
                        <div className="modal-content xs">
                            <div className="modal-header">
                                <div className="header-title">
                                    <h4> {selectedEvent?.calendarId ? "Editar" : ""} Horario para cita</h4>
                                    <div class="cite-format-time">{formatEventDate(selectedEvent.start, selectedEvent.end)}</div>
                                    <div class="format-time-GMT">{getTimeZone()}</div>
                                </div>
                            </div>
                            <div className="modal-body">

                                {selectedEvent?.calendarId && (
                                    <>
                                        <label className="form-label">Hora de Inicio:</label>
                                        <div className="mt-0" style={{ display: 'flex' }}>
                                            <input
                                                name="startDate"
                                                type="date"
                                                value={editedDateInit ? editedDateInit.toISOString().split('T')[0] : ''}
                                                onChange={(e) => {
                                                    setEditedDateInit(new Date(e.target.value));
                                                }}
                                                className="form-control"
                                            />
                                            <DatePicker
                                                selected={editedTimeInit}
                                                onChange={(date) => {
                                                    setEditedTimeInit(date);
                                                }}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeFormat="HH:mm"
                                                timeIntervals={ajustesHorariosReservada.duracionCitaMin}
                                                timeCaption="Hora"
                                                dateFormat="h:mm aa"
                                                className="time-picker"
                                            />
                                        </div>
                                        <label className="form-label">Hora de fin:</label>
                                        <div className="mb-1" style={{ display: 'flex' }}>
                                            <input
                                                name="numDoc"
                                                type="date"
                                                value={editedDateEnd ? editedDateEnd.toISOString().split('T')[0] : ''}
                                                onChange={(e) => {
                                                    setEditedDateEnd(new Date(e.target.value));
                                                }}
                                                className="form-control"
                                            />
                                            <DatePicker
                                                selected={editedTimeEnd}
                                                onChange={(date) => {
                                                    setEditedTimeEnd(date);
                                                }}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeFormat="HH:mm"
                                                timeIntervals={ajustesHorariosReservada.duracionCitaMin}
                                                timeCaption="Hora"
                                                dateFormat="h:mm aa"
                                                className="time-picker"
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="mb-1">
                                    <label className="form-label">Nombre:</label>
                                    <AutocompletePaciente
                                        useSearchItems={useBuscarPacientes}
                                        onSelected={handleSelected}
                                        toString={toString}
                                        inputValueInitial={paciente?.nombres}
                                    ></AutocompletePaciente>
                                </div>
                                <div className="mb-2">
                                    <label className="form-label">DNI:</label>
                                    <input name="numDoc" type="text" value={paciente.numDoc} onChange={handleChange} className="form-control" placeholder="Ingrese su DNI" />
                                </div>

                                <div className="mb-2">
                                    <label className="form-label">Teléfono:</label>
                                    <input name="telefono" type="tel" value={paciente.telefono} onChange={handleChange} className="form-control" placeholder="Ingrese su teléfono" />
                                </div>
                                <div >
                                    <label className="form-label">Correo:</label>
                                    <input name="correo" type="email" value={paciente.correo} onChange={handleChange} className="form-control" placeholder="Ingrese su correo" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary" onClick={closeModalReserva}>Cerrar</button>
                                <button className="btn btn-primary" onClick={selectedEvent?.calendarId ? handleEditedAppointment
                                    : handleSaveAppointment}>Reservar</button>
                            </div>
                        </div>
                    </div>
                )}
                {showModal && selectedEvent && (
                    <div
                        className="event-modal"
                        style={{
                            position: "absolute",
                            left: selectedEvent.x,
                            top: selectedEvent.y,
                            background: "white",
                            borderRadius: "8px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            border: "1px solid #ddd",
                            padding: "15px",
                            zIndex: 1000,
                        }}
                        onClick={(e) => e.stopPropagation()}>
                        {!selectedEvent?.extendedProps?.isBlocked && (
                            <div className="event-modal-header">
                                <button className="icon-button" onClick={() => handleEdit(selectedEvent)} style={{
                                    background: "white",
                                    border: "none",
                                    cursor: "pointer"
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <path fill="#0284c7" d="m19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6l4.25 4.25Z" />
                                    </svg>
                                </button>
                                <button className="icon-button" onClick={() => handleDeleteClick(selectedEvent)} style={{
                                    background: "white",
                                    border: "none",
                                    cursor: "pointer"
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#0284c7" d="M9 3v1H4v2h1v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1V4h-5V3H9m0 5h2v9H9V8m4 0h2v9h-2V8Z" /></svg>
                                </button>
                            </div>
                        )}
                        <p style={{ margin: "0", fontSize: "11px" }}>
                            <strong>AGENDA DE CITAS PARA RESERVAR</strong>
                        </p>
                        <h4 style={{ margin: "0 0 5px 0" }}>{selectedEvent.title}</h4>
                        <p style={{ margin: "0", fontSize: "14px" }}>
                            <strong>Cita</strong> {formatEventDate(selectedEvent.start, selectedEvent.end)}
                        </p>
                        <div class="event-container">
                            <div aria-hidden="true" class="event-svg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                    <path fill="#000000" d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z" />
                                </svg>
                            </div>
                            <div class="event-control-form">
                                <div class="title">
                                    Formulario de reserva
                                </div>
                                <div class="description">
                                    Nombre · Telefono . Dni · Dirección de correo electrónico
                                </div>
                            </div>
                        </div>
                        <div class="event-container">
                            <div aria-hidden="true" class="event-svg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                    <path fill="#000000" d="M7 10h2v2H7v-2m4 0h2v2h-2v-2m4 0h2v2h-2v-2M7 14h2v2H7v-2m4 0h2v2h-2v-2m4 0h2v2h-2v-2M19 4h-1V2h-2v2H8V2H6v2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6c0-1.1-.9-2-2-2M5 20V9h14v11H5Z" />
                                </svg>
                            </div>
                            <div class="event-control-form">
                                <div class="title">
                                    {selectedEvent?.extendedProps?.attendees?.[0]?.name
                                        ? selectedEvent.extendedProps.attendees[0].name.toUpperCase()
                                        : ''}
                                </div>
                                <div class="description">
                                    Los horarios ya ocupados en este calendario no están disponibles
                                </div>
                            </div>
                        </div>

                    </div>
                )}

            </div >
        </>
    );
};

export default CitaItem;