import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";


export const StyledInput = styled.input`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  line-height: 2.5;
  font-size: 0.75rem;
  width: 12.5rem;
  heigth: 2rem;
  padding-left: 0.5em;
  margin-left: 0.8em;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
    width: 10rem;
  }
`;

export const ClockIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="12" r="10" />
    <path d="M12 6v6l4 2" />
  </svg>
);

export const CrossIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M18 6L6 18" />
    <path d="M6 6l12 12" />
  </svg>
);

export const PlusIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M12 5v14" />
    <path d="M5 12h14" />
  </svg>
);

export const CopyIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
  </svg>
);

export const CalendarGrid = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const TituloInput = styled.input.attrs({
  type: 'text',
  placeholder: 'Agregar título'
})`
  background: #1a1a1a;
  color: #ffffff; // Cambiamos a blanco para mejor visibilidad
  padding: 12px 16px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: text;
  margin-top: 8px;
  transition: all 0.2s ease;
  border: none;
  width: 100%;
  outline: none;
  display: block; // Aseguramos que sea un bloque
  pointer-events: auto; // Aseguramos que reciba eventos

  &:hover {
    background: #242424;
  }

  &:focus {
    background: #242424;
    color: #ffffff;
  }

  &::placeholder {
    color: #7d7d7d;
  }
`;

export const CalendarHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 10px;
  border-bottom: 1px solid #404040;
  text-align: center;
`;

export const DayHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .day-name {
    font-size: 12px;
    color: #888;
  }
  .day-number {
    font-size: 24px;
    color: ${props => props.isToday ? '#8ab4f8' : '#fff'};
    background: ${props => props.isToday ? 'rgba(138, 180, 248, 0.2)' : 'transparent'};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }
`;

export const TimeGrid = styled.div`
  display: grid;
  grid-template-columns: 60px repeat(5, 1fr);
  flex: 1;
  overflow-y: auto;
`;

export const TimeColumn = styled.div`
  border-right: 1px solid #404040;
  position: relative;
`;

export const TimeSlot = styled.div`
  height: 60px;
  border-bottom: 1px solid #404040;
  color: #888;
  padding: 5px;
  font-size: 12px;
`;


export const AvailabilityHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  
  svg {
    color: #888;
  }
`;

export const AvailabilityInfo = styled.div`
  h3 {
    margin: 0;
    font-size: 16px;
  }
  p {
    margin: 5px 0;
    font-size: 14px;
    color: #888;
  }
  a {
    color: #8ab4f8;
    font-size: 14px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const AvailabilityRow = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 16px;
  border-radius: 8px;
  
    color:rgb(0, 0, 0);
  background: rgba(23, 62, 180, 0.05);
  margin: 8px 0;
  
  // Estilizado base de clases
  .day-label {
    min-width: 60px;
    
    color:rgb(0, 0, 0);
    font-weight: 500;
    text-transform: capitalize;
  }

  .time-inputs {
    flex: 1;
    display: flex;
    align-items: center;
    
    color:rgb(0, 0, 0);
    gap: 16px;
    
    input {
      padding: 8px 12px;
      border-radius: 6px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.05);
      
    color:rgb(0, 0, 0);
      &:focus {
        outline: none;
        border-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .actions {
    display: flex;
    gap: 12px;
    margin-left: auto;
    
    button {
      padding: 6px 12px;
      border-radius: 6px;
      transition: all 0.2s ease;
      
    color:rgb(0, 0, 0);
      &:hover {
        transform: translateY(-1px);
      }
    }
  }

  // Escritorio y pantallas grandes
  @media (min-width: 1024px) {
    padding: 16px;
    gap: 24px;

    .day-label {
      min-width: 80px;
      font-size: 1rem;
    }

    .time-inputs {
      gap: 24px;
      
      input {
            color: #000; 
        min-width: 120px;
      }
    }
    
    .actions {
      gap: 16px;
    }
  }

  // Tablets
  @media (max-width: 1023px) and (min-width: 768px) {
    padding: 14px;
    
    .day-label {
      min-width: 70px;
    }

    .time-inputs {
      input {
        min-width: 100px;
      }
    }
  }

  // Móviles
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;

    .day-label {
      width: 100%;
      margin-bottom: 4px;
    }

    .time-inputs {
      width: 100%;
      flex-wrap: wrap;
      gap: 8px;
      
      input {
            color: #000; 
        flex: 1;
              background-color: #fff;

        min-width: 120px;
      }
    }

    .actions {
      width: 100%;
      justify-content: flex-end;
      margin-top: 8px;
    }
  }

  // Móviles pequeños
  @media (max-width: 360px) {
    .time-inputs {
      flex-direction: column;
      align-items: stretch;
      
      input {
        width: 100%;
      }
    }
    
    .actions {
      flex-direction: column;
      align-items: stretch;
      
      button {
        width: 100%;
      }
    }
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  padding: 5px;
  border-radius: 50%;
  color: #888;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #404040;
  }

  svg {
    display: block;
  }
`;

export const Select = styled.select`
  width: 100%;
  background: #333;
  color: #fff;
  border: 1px solid #404040;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 15px;
`;
export const VerticalRibbon = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  width: 40px;
  height: 40px;
  background: rgb(255, 255, 255);
  border-radius: 4px 0 0 4px;
  display: ${({ isOpen }) => (isOpen ? "none" : "block")}; /* Oculta cuando está abierto */
  transition: opacity 0.3s ease; /* Suaviza la transición */
`;
export const MenuIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2">
    <path d="M3 12h18" />
    <path d="M3 6h18" />
    <path d="M3 18h18" />
  </svg>
);

export const ToggleButton = styled.button`
  position: absolute;
  right: 0;
  top: 10px;
  background: rgb(255, 255, 255);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 0 4px 4px 0;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #404040;
  border-left: none;

  &:hover {
    background: rgb(77, 6, 240);
  }
`;

export const SectionTitle = styled.h3`
  margin-bottom: 10px;
`;

export const Option = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

export const InputField = styled.input`
  background: #333;
  color: #ffffff;
  border: none;
  padding: 5px;
  width: 50px;
  text-align: center;
  border-radius: 5px;
// `;

export const ConfigTitle = styled.h4`
  font-size: clamp(1rem, 1.2vw + 0.5rem, 1.25rem);
  margin: 0;
  color: #1a365d;
`;
export const Column = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */

  /* Estilos responsivos opcionales (si se necesitan ajustes específicos) */
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const ScheduleSection = styled.div`
  position: relative;
  width: 100%; /* Mismo ancho que Column */
  margin-top: 20px;
  padding: 16px;
  border: 1.5px solid #0c8de3;
  border-radius: 8px;
  background: #fff;
  box-sizing: border-box; /* Incluye borde y padding en el ancho total */

  /* Alineación perfecta con Column */
  margin-left: -20px; /* Compensa el padding del Column */
  margin-right: -20px;
  width: calc(100% + 40px); /* Neutraliza el padding del padre */

  /* Estilos responsivos para márgenes */
  @media (max-width: 768px) {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(110% + 30px);
  }
`;
// Primero declarar todos los componentes básicos que serán referenciados
export const ConfigHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const TimeConfigContainer = styled.div`
  display: flex;
  gap: clamp(0.5rem, 1.5vw, 1rem);
  align-items: center;
  margin: 1rem 0;
  flex-wrap: wrap;
`;

// Luego el componente principal con las referencias
// Componentes adicionales sin dependencias


export const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
// }}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}
export const ScheduleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const ScheduleTitle = styled.div`
  h3 {
    margin: 0;
    font-size: 16px;
    color: #fff;
  }
  p {
    margin: 4px 0 0;
    font-size: 14px;
    color: #888;
  }
`;


export const RadioOption = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;

  label {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
  }

  p {
    margin: 4px 0 0 24px;
    font-size: 14px;
    color: #888;
  }
`;
export const AdjustedAvailabilitySection = styled.div`
  margin-top: 20px;
  padding: 16px 0;
  border-top: 1px solid #404040;
`;

export const AdjustedAvailabilityHeader = styled.div`
  margin-bottom: 16px;
  h3 {
    margin: 0;
    font-size: 16px;
    color: #fff;
  }
  p {
    margin: 4px 0 0;
    font-size: 14px;
    color: #888;
  }
`;

export const ChangeAvailabilityButton = styled.button`
  width: 100%;
  padding: 12px;
  margin-top: 12px;
  background: transparent;
  border: 1px solid #404040;
  border-radius: 24px;
  color: #8ab4f8;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: rgba(138, 180, 248, 0.1);
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: #2d2d2d;
  border-radius: 8px;
  padding: 20px;
  min-width: 300px;
`;

export const CalendarWrapper = styled.div`
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: white;
  }

  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;
    color: white;
  }

  .calendar-day {
    padding: 8px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background: rgba(138, 180, 248, 0.1);
    }

    &.selected {
      background: #8ab4f8;
      color: #000;
    }

    &.today {
      border: 1px solid #8ab4f8;
    }
  }

  .day-header {
    text-align: center;
    color: #888;
    font-size: 12px;
    padding: 4px;
  }
`;

export const NavigationButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const ConfigSection = styled.div`

    color:rgb(0, 0, 0);
  margin-top: 20px;
  padding: 16px;
  
  border: 2px solid #0c8de3;
  border-radius: 8px;
 
  background: #fff;
`;


export const ConfigItem = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    color: #ffffff;
    margin-bottom: 8px;
  }

  p {
    color: #A0A0A0;
    font-size: 14px;
    margin: 4px 0;
  }
`;


export const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  input[type="checkbox"] {
    margin-top: 4px;
  }
`;

// Styled Components
export const CalendarConfigSection = styled.div`
  margin-top: 20px;
  padding: 16px 0;
  border-top: 1px solid #404040;
`;


export const CalendarInfo = styled(AvailabilityInfo)``;

export const UserSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #404040;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 12px 0;
  cursor: pointer;
`;

export const UserDot = styled.div`
  width: 8px;
  height: 8px;
  background: #1a73e8;
  border-radius: 50%;
`;

export const CheckboxContent = styled.div`
  color: #fff;
  
  p {
    color: #a0a0a0;
    font-size: 14px;
    margin: 4px 0;
  }

  a {
    color: #8ab4f8;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SubCheckbox = styled(CheckboxContainer)`
  margin-left: 24px;
  margin-top: 8px;
`;

export const ConnectMoreSection = styled.div`
  background: #1f1f1f;
  border: 1px solid #404040;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
`;

export const NumberBadge = styled.div`
  color: white;
  background: #ea4335;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 8px;
`;


export const Column1 = styled.div`
  color: rgb(0, 0, 0);
  display: flex;
  border: 2px solid #0c8de3;
  background: #fff;
  flex-direction: column;
  gap: 20px;
  
  margin-top: 40px; /* Ajusta este valor según lo necesites */
`;


export const SaveButton = styled.button`
   && {
    height: 2.25rem;
    margin-top: 1rem;
    margin-left: 2rem;
    border-radius: 1em;      
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    font-size: 0.9rem;
    background-color: #0060b0;
    color: #ffffff;
    font-size: 0.9rem;
    border: none;
    &:hover{
      color:#000000;
      background-color: #0c8de390;
      font-width: bold;
    }
    @media ${dispositivo.mobileS}{
      font-size: 0.9rem;
      padding: 0.1em;
      width: 9rem;
    } 
    @media ${dispositivo.tablet}{
      width: 10rem;
    }  
    @media ${dispositivo.laptop} {      
      width: 12.5rem;
    }
  }
`;


export const SaveIconAlt = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
    <path d="M17 21v-8H7v8M7 3v5h8V3" />
  </svg>
);


export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  width: 50%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;



// Iconos como componentes SVG
export const SyncIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M23 4v6h-6M1 20v-6h6" />
    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
  </svg>
);

export const ArrowRightIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M5 12h14M12 5l7 7-7 7" />
  </svg>
);

// Botón con estilos completos
export const UpdateButton = styled.button`
  background: #2196F3;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background: #1976D2;
  }

  &:active {
    background: #0D47A1;
  }

  &:disabled {
    background: #BDBDBD;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 180px;
    padding: 10px 20px;
    font-size: 14px;
  }

  > span {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  svg {
    flex-shrink: 0;
  }
`;

// ✅ StyledSelect debe ser un <select>
export const StyledSelect = styled.select`
  padding: 8px;
  border-radius: 4px;
  width: 100%;
`;

// ✅ InputGroup debe ser un contenedor (div)
export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

// ✅ TimeInput como input estándar
export const TimeInput = styled.input`
  padding: 6px;
  border: 1px solid #ddd;
`;


export const LoadingSpinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  margin-right: 8px;
  display: inline-block;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const ButtonText = styled.span`
  margin-left: 8px;
`;


export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const TimeRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TimeInputs = styled.div`
  display: flex;
  align-items: center; 
  gap: 8px;
`;

export const AddButton = styled(IconButton)`
  align-self: flex-start;
  margin-top: 8px;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 0.8rem;
`;


export const NumberInput = styled.input`
  width: 50px;
  padding: 10px;
  border: 2px solid #0066cc;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background-color: #f8f9fa;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
    border-color: #004c99;
    box-shadow: 0 0 5px rgba(0, 102, 204, 0.5);
  }
`;



export const SidePanelContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px; /* Ancho máximo para pantallas grandes */
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 14px;
  box-shadow: 2px 2px #88888877;
  
  @media (max-width: 1024px) {
    padding: 15px;
  }
  
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const CalendarContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background: #f5f5f5;
  
  @media (max-width: 768px) {
    padding: 10px;
  }
`;


export const ThreeColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Por defecto tres columnas iguales */
  gap: 20px;
  width: 100%;
  
  /* Tablet */
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr; /* Dos columnas en tablet */
    & > *:last-child {
      grid-column: span 2; /* Última columna ocupa todo el ancho */
    }
  }
  
  /* Móvil */
  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Una columna en móvil */
    gap: 15px; /* Reducimos el espacio entre elementos para móvil */
    & > * {
      grid-column: 1 / -1; /* Fuerza que cada elemento ocupe todo el ancho disponible */
    }
  }
`;
