import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

// Paleta de colores médicos
const colors = {
  primary: "#005EB8",       // Azul institucional médico
  secondary: "#00A3E1",     // Azul claro médico
  accent: "#FF6B6B",        // Rojo coral para énfasis
  background: "#E8F4FE",    // Fondo azul claro
  border: "#2C3E50",        // Borde oscuro
  text: "#2C3E50",          // Texto principal oscuro
  success: "#4CAF50",       // Verde para acciones positivas
  danger: "#E53935"         // Rojo para acciones peligrosas
};

// Añadiendo más breakpoints para mejor responsividad
export const MainContainer = styled.div`
  background: ${colors.background};
  min-height: 100vh;
  padding: 1rem 0.5rem;
  
  @media ${dispositivo.mobileL} {
    padding: 1.5rem 1rem;
  }
  
  @media ${dispositivo.tablet} {
    padding: 2rem;
  }
  
  @media ${dispositivo.laptopL} {
    padding: 3rem;
  }
`;

export const ContainerBody = styled.div`
  background: white;
  border-radius: 8px;
  border: 2px solid ${colors.primary};
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem;
  
  @media ${dispositivo.mobileL} {
    border-radius: 10px;
    padding: 1.5rem;
  }
  
  @media ${dispositivo.tablet} {
    border-radius: 12px;
    padding: 2rem;
  }
`;

export const ContainerTitle = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
  
  @media ${dispositivo.tablet} {
    margin-bottom: 2rem;
  }
`;

export const ContainerTitleText = styled.h1`
  font-size: 1.5rem;
  color: ${colors.primary};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  
  @media ${dispositivo.mobileL} {
    font-size: 1.8rem;
    letter-spacing: 0.8px;
  }
  
  @media ${dispositivo.tablet} {
    font-size: 2.5rem;
    letter-spacing: 1px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  background: #F8FBFF;
  padding: 1rem;
  border-radius: 8px;
  
  @media ${dispositivo.mobileL} {
    gap: 1.3rem;
    padding: 1.5rem;
  }
  
  @media ${dispositivo.tablet} {
    gap: 1.5rem;
    padding: 2rem;
    border-radius: 10px;
  }
`;

export const FormGrid = styled.div`
  display: grid;
  gap: 1.2rem;
  grid-template-columns: 1fr;
  
  @media ${dispositivo.mobileL} {
    gap: 1.3rem;
  }
  
  @media ${dispositivo.tablet} {
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media ${dispositivo.laptop} {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  
  @media ${dispositivo.mobileL} {
    gap: 0.7rem;
  }
  
  @media ${dispositivo.tablet} {
    gap: 0.8rem;
  }
  
  ${({ fullWidth }) => fullWidth && `
    grid-column: 1 / -1;
  `}
`;
export const FormGroup3columnas = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 100%;
  
  @media ${dispositivo.mobileL} {
    gap: 0.7rem;
  }
  
  @media ${dispositivo.tablet} {
    gap: 0.8rem;
  }
  
  @media ${dispositivo.laptop} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  
  ${({ fullWidth }) => fullWidth && `
    grid-column: 1 / -1;
  `}
`;


export const InputGroup = styled.div`
  display: grid;
  gap: 0.7rem;
  grid-template-columns: 1fr;
  align-items: start;
  
  @media ${dispositivo.mobileL} {
    gap: 0.8rem;
    grid-template-columns: 1fr 1.5fr;
    align-items: center;
  }
  
  @media ${dispositivo.tablet} {
    gap: 1rem;
    grid-template-columns: 1fr 2fr;
  }
`;
export const InputGroupF = styled.div`
  display: grid;
  gap: 0.7rem;
  grid-template-columns: 1fr;
  align-items: start;
  width: 100%;
  
  @media ${dispositivo.laptop} {
    grid-template-columns: 1fr;
  }
  
  @media ${dispositivo.mobileL} {
    gap: 0.8rem;
  }
  
  @media ${dispositivo.tablet} {
    gap: 1rem;
  }
`;

export const StyledLabel = styled.label`
  font-weight: 600;
  color: ${colors.text};
  font-size: 0.9rem;
  letter-spacing: 0.3px;
  
  @media ${dispositivo.mobileL} {
    font-size: 0.95rem;
    letter-spacing: 0.4px;
  }
  
  @media ${dispositivo.tablet} {
    font-size: 1.1rem;
    letter-spacing: 0.5px;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 0.7rem;
  border: 2px solid ${colors.border};
  border-radius: 6px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  background: white;
  
  @media ${dispositivo.mobileL} {
    padding: 0.8rem;
    font-size: 0.95rem;
    border-radius: 7px;
  }
  
  @media ${dispositivo.tablet} {
    padding: 0.9rem;
    font-size: 1rem;
    border-radius: 8px;
  }
  
  &:focus {
    outline: none;
    border-color: ${colors.secondary};
    box-shadow: 0 0 0 3px rgba(0,94,184,0.1);
  }
  
  &::placeholder {
    color: #7F8C8D;
    font-style: italic;
  }
`;

export const StyledInputNumber = styled(StyledInput)`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledSelect = styled.select`
  width: 100%;
  padding: 0.7rem;
  border: 2px solid ${colors.border};
  border-radius: 6px;
  background: white;
  font-size: 0.9rem;
  appearance: none;
  transition: all 0.3s ease;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23005EB8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  
  @media ${dispositivo.mobileL} {
    padding: 0.8rem;
    font-size: 0.95rem;
    border-radius: 7px;
  }
  
  @media ${dispositivo.tablet} {
    padding: 0.9rem;
    font-size: 1rem;
    border-radius: 8px;
  }
  
  &:focus {
    outline: none;
    border-color: ${colors.secondary};
    box-shadow: 0 0 0 3px rgba(0,94,184,0.1);
  }
`;
export const StyledSelectFF = styled.select`
  width: 50%;
  padding: 0.7rem;
  border: 2px solid ${colors.border};
  border-radius: 6px;
  background: white;
  font-size: 0.9rem;
  appearance: none;
  transition: all 0.3s ease;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23005EB8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  
  @media ${dispositivo.mobileL} {
    padding: 0.8rem;
    font-size: 0.95rem;
    border-radius: 7px;
  }
  
  @media ${dispositivo.tablet} {
    padding: 0.9rem;
    font-size: 1rem;
    border-radius: 8px;
  }
  
  &:focus {
    outline: none;
    border-color: ${colors.secondary};
    box-shadow: 0 0 0 3px rgba(0,94,184,0.1);
  }
`;

export const InputWithUnit = styled.div`
  position: relative;
  
  input {
    padding-right: 3.5rem !important;
    
    @media ${dispositivo.mobileL} {
      padding-right: 4rem !important;
    }
    
    @media ${dispositivo.tablet} {
      padding-right: 5rem !important;
    }
  }
`;

export const Unit = styled.span`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.text};
  font-weight: 600;
  font-size: 0.8rem;
  
  @media ${dispositivo.mobileL} {
    right: 1.1rem;
    font-size: 0.85rem;
  }
  
  @media ${dispositivo.tablet} {
    right: 1.2rem;
    font-size: 0.9rem;
  }
`;

export const DosisHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 0.5rem;
  
  @media ${dispositivo.mobileL} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ToggleDosisButton = styled.button`
  padding: 0.4rem 1rem;
  border-radius: 20px;
  border: 2px solid ${({ active }) => active ? colors.primary : colors.border};
  background: ${({ active }) => active ? colors.primary : 'transparent'};
  color: ${({ active }) => active ? 'white' : colors.text};
  font-weight: 600;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
  
  @media ${dispositivo.mobileL} {
    padding: 0.45rem 1.1rem;
    font-size: 0.85rem;
    border-radius: 22px;
  }
  
  @media ${dispositivo.tablet} {
    padding: 0.5rem 1.2rem;
    font-size: 0.9rem;
    border-radius: 25px;
  }
  
  &:hover {
    border-color: ${colors.secondary};
    color: ${({ active }) => active ? 'white' : colors.secondary};
  }
`;

export const PreviewBox = styled.div`
  padding: 0.8rem;
  background: ${colors.background};
  border: 2px solid ${colors.primary};
  border-radius: 6px;
  font-size: 0.9rem;
  color: ${colors.text};
  font-weight: 500;
  margin-top: 0.5rem;
  
  @media ${dispositivo.mobileL} {
    padding: 0.9rem;
    font-size: 0.95rem;
    border-radius: 7px;
  }
  
  @media ${dispositivo.tablet} {
    padding: 1rem;
    font-size: 1rem;
    border-radius: 8px;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
  grid-column: 1 / -1;
  
  @media ${dispositivo.mobileL} {
    flex-direction: row;
    justify-content: flex-end;
    gap: 1.2rem;
    margin-top: 1.8rem;
  }
  
  @media ${dispositivo.tablet} {
    gap: 1.5rem;
    margin-top: 2rem;
  }
`;

export const StyledButton = styled.button`
  width: 100%;
  padding: 0.8rem 1.5rem;
  border-radius: 6px;
  font-weight: 700;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  
  @media ${dispositivo.mobileL} {
    width: auto;
    padding: 0.9rem 1.8rem;
    font-size: 1.05rem;
    border-radius: 7px;
    letter-spacing: 0.4px;
  }
  
  @media ${dispositivo.tablet} {
    padding: 1rem 2rem;
    font-size: 1.1rem;
    border-radius: 8px;
    letter-spacing: 0.5px;
  }
  
  &[type="submit"] {
    background: ${colors.primary};
    color: white;
    border: 2px solid ${colors.primary};
    
    &:hover {
      background: #004A94;
      border-color: #004A94;
      transform: translateY(-1px);
    }
  }
`;

export const LinkCancelar = styled(StyledButton)`
  background: white;
  border: 2px solid ${colors.danger};
  color: ${colors.danger};
  
  &:hover {
    background: ${colors.danger};
    color: white;
    transform: translateY(-1px);
  }
`;