import { db, firestorage } from "../Config";

export const saveHorarioEmpresa = async (empresaId, horarioData) => {
  try {
    const horarioRef = db.collection("horariosEspecialistas").doc(empresaId);
    const doc = await horarioRef.get();

    if (doc.exists) {
      // Actualizar si ya existe
      await horarioRef.update({
        ...horarioData,
        updatedAt: new Date() // Campo adicional para tracking
      });
    } else {
      // Crear nuevo registro si no existe
      await horarioRef.set({
        ...horarioData,
        empresaId,
        createdAt: new Date(),
        updatedAt: new Date()
      });
    }

    return true;
  } catch (err) {
    throw err;
  }
};

export const getHorarioEspecialistaById = async (empresa_id) => {
  try {
    const horarioRef = db.collection("horariosEspecialistas").doc(empresa_id);
    const doc = await horarioRef.get();

    if (!doc.exists) {
      throw new Error(`No se encontró el horario con ID: ${empresa_id}`);
    }

    return {
      id: doc.id,
      ...doc.data()
    };
    
  } catch (error) {
    throw new Error(`Error al obtener horario: ${error.message}`);
  }
};
