import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BackWhite from "../../img/back-white.png";
import { calcularEdad } from "../../helpers/persona";
import {
  setModoApp,
  setPacienteSesion,
} from "../../redux/sesion/sesionActions";
import { newOdontograma } from "../../redux/odontograma/odontogramaActions";
import { newPaciente } from "../../redux/paciente/pacienteActions";
import { BuscarPacientes } from "../BuscarPacientes/index";
import { newPrestations } from "../../redux/prestations/prestationsActions";
import { newPrestacionesSesion } from "../../redux/prestacionesSesion/prestacionesSesionActions";
import { newCuenta } from "../../redux/cuenta/cuentaActions";
import {
  newPrestation,
  addTratamiento,
} from "../../redux/prestation/prestationActions";
import { HeaderBar, HeaderWrapper, LinkDatos } from "./styles";
import ResponsiveButton from "../ResponsiveButton";
import Allergies from "../Allergies";

const DatosPaciente = () => {
  const dispatch = useDispatch();
  const paciente = useSelector((state) => state.paciente);
  const cuenta = useSelector((state) => state.cuenta);
  const handleClick = (e) => {
    const modo = e.target.value;
    dispatch(setModoApp(modo));
    //dispatch(newPrestation());
  };
  const history = useHistory();

  const handleSesionPaciente = () => {
    dispatch(setPacienteSesion(false));
    dispatch(newPaciente());
    dispatch(newOdontograma());
    dispatch(newPrestation());
    dispatch(newPrestations());
    
    dispatch(newCuenta());
  };
  
  // Función auxiliar para formatear moneda
  const formatCurrency = (value) => {
    if (value === undefined || value === null) {
      return '0.00';
    }
    return value.toFixed(2);
  };
  
  const allergies = () => {
    // Verificar si patologias existe antes de usar filter
    if (!paciente || !paciente.patologias) {
      return []; // Devolver un array vacío si no hay patologías
    }
    
    let alergias = paciente.patologias.filter(
      (patologia) => patologia.tipo === "ALERGIA"
    );
    return alergias;
  };

  // Verificar si paciente tiene fecha de nacimiento antes de calcular edad
  const getEdad = () => {
    if (!paciente || !paciente.fecNac) {
      return '';
    }
    return `(${calcularEdad(paciente.fecNac)} años)`;
  };

  return (
    <HeaderWrapper>
      <figure>
        <img
          className="header-icon"
          onClick={handleSesionPaciente}
          alt="step back icon"
          src={BackWhite}
        />
      </figure>
      <div className="header">
        <div className="header__user-data">
          <figure className="header__user-data__figure">
            <Link to="/paciente/datos">
              <img
                className="header__user-data__figure__img"
                src="https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png"
                alt="user profile"
              />
            </Link>
          </figure>

          <div className="header__user-data__data">
            <h3 className="header__user-data__data__name">
              <LinkDatos to="/paciente/datos">
                {paciente?.nombres || ''} {paciente?.apellidos || ''}
              </LinkDatos>
              <div className="header__user-data__data__name__span">
                <span> {getEdad()}</span>
              </div>
            </h3>
            <div className="header__user-data__data__socials">
              <div className="header__user-data__data__socials__item">
                <ResponsiveButton
                  iconName="whatsapp"
                  data={paciente?.telefono}
                  patientFullName={`${paciente?.nombres || ''} ${paciente?.apellidos || ''}`}
                />
              </div>

              <div className="header__user-data__data__socials__item">
                <ResponsiveButton iconName="phone" data={paciente?.telefono} />
              </div>

              <div className="header__user-data__data__socials__item">
                <ResponsiveButton
                  iconName="mail"
                  data={paciente?.correo}
                  left={true}
                />
              </div>

              <div className="header__user-data__data__socials__item">
                <ResponsiveButton
                  iconName="dni"
                  data={paciente?.numDoc}
                  left={true}
                />
              </div>
            </div>
          </div>
        </div>

        {allergies().length > 0 ? <Allergies allergies={allergies()} /> : null}

        <div className="header__balances">
          <Link to="/paciente/abonos">
            <div className="header__balances__balance">
              <p className="header__balances__balance__title">Disponible</p>
              <p>S./{formatCurrency(cuenta?.saldo)}</p>
            </div>
          </Link>
          <Link to="/paciente/pagos">
            <div className="header__balances__balance">
              <p className="header__balances__balance__title">Deuda</p>
              <p className="header__balances__balance__debt">
                S./{formatCurrency(cuenta?.adeudado)}
              </p>
            </div>
          </Link>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export const HeaderPaciente = () => {
  const sesion = useSelector((state) => state.sesion);

  if (sesion.paciente)
    return (
      <>
        <HeaderBar>
          <DatosPaciente />
        </HeaderBar>
      </>
    );
  else
    return (
      <>
        <HeaderBar>
          <BuscarPacientes />
        </HeaderBar>
      </>
    );
};