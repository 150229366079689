// styles.js
import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  padding: 15px 0;

  @media ${dispositivo.tablet} {
    padding: 25px 0;
  }
`;

export const ContainerTitle = styled.div`
  text-align: center;
  padding: 15px 0;
`;

export const ContainerTitleText = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
  color: #2c3e50;

  @media ${dispositivo.mobileL} {
    font-size: 1.6rem;
  }
  
  @media ${dispositivo.tablet} {
    font-size: 1.8rem;
  }
`;

export const ContainerBody = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  margin: 15px auto;
  width: 95%;
  padding: 20px;

  @media ${dispositivo.tablet} {
    width: 90%;
    padding: 30px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 20px;

  ${ContainerTitleText} {
    font-size: 1rem;
    
    @media ${dispositivo.mobileL} {
      font-size: 1.1rem;
    }
  }
`;

export const DesktopView = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;

  @media (max-width: 768px) {
    display: none;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #3498db;
    margin: 1rem 0;

    th, td {
      padding: 12px;
      border: 1px solid #3498db;
      text-align: left;
      min-width: 120px;
    }

    th {
      background: #f0f8ff;
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
`;

export const MobileView = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;
export const MedicinesContainer = styled.div`
  overflow-x: auto;
  margin: 25px 0;
  position: relative;

  /* Estilos base para ambas vistas */
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #3498db;
  }

  th, td {
    padding: 12px 10px;
    text-align: left;
    border: 1px solid #3498db;
    font-size: 0.875rem;
  }

  th {
    background: #f0f8ff;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  /* Vista Desktop (predeterminada) */
  .desktop-view {
    display: block;
    
    @media ${dispositivo.mobileL} {
      display: none;
    }
  }

  /* Vista Mobile */
  .mobile-view {
    display: none;
    
    @media ${dispositivo.mobileL} {
      display: block;
      width: 100%;
    }
  }

  /* Estilos específicos para tarjetas móviles */
  .mobile-medicine-card {
    border: 1px solid #3498db;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1rem;
    background: #fff;
    box-shadow: 0 2px 8px rgba(52, 152, 219, 0.1);

    @media ${dispositivo.tablet} {
      padding: 1.2rem;
    }
  }

  .medicine-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }

  .medicine-name {
    font-size: 1.1rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 0.25rem;
  }

  .medicine-concentration {
    font-size: 0.9rem;
    color: #7f8c8d;
    font-weight: 500;
  }

  .medicine-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
    font-size: 0.95rem;
  }

  .detail-label {
    color: #3498db;
    font-weight: 500;
    min-width: 100px;
  }

  .detail-value {
    color: #2c3e50;
    font-weight: 400;
    text-align: right;
    flex-grow: 1;
  }

  .mobile-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 1rem;
    padding-top: 0.5rem;
    border-top: 1px solid #eee;
  }
`;

export const StyledInputButton = styled.input`
  padding: 8px 20px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  background: #3498db;
  color: white;
  transition: background 0.2s ease;

  &:hover {
    background: #2980b9;
  }
`;

export const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  border-radius: 6px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s ease;
  border: 2px solid #3498db;
  color: #3498db;
  background: white;

  &:hover {
    background: #3498db;
    color: white;
  }
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 120px;
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
  resize: vertical;
  transition: all 0.2s ease;
  background-color: #ffffff;
  color: #333333;
  
  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
  
  &::placeholder {
    color: #999999;
  }
  
  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const ContainerLeft = styled.div`
  margin-top: 25px;
  display: flex;
  gap: 15px;
  
  // Versión Desktop
  @media (min-width: 769px) {
    justify-content: space-between;
    
    ${StyledLink} {
      margin-right: auto; // Empuja a la izquierda
    }
    
    ${StyledInputButton} {
      margin-left: auto; // Empuja a la derecha
    }
  }

  // Versión Mobile
  @media (max-width: 768px) {
    flex-direction: column;
    
    ${StyledLink}, ${StyledInputButton} {
      width: 100%;
      text-align: center;
    }
  }
`;


export const DivForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;



export const MobileMedicineCard = styled.div`
  border: 1px solid #3498db;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  background: #fff;
  box-shadow: 0 2px 8px rgba(52, 152, 219, 0.1);
`;

export const MedicineHeader = styled.div`
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
`;

export const MedicineName = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.25rem;
`;

export const MedicineConcentration = styled.div`
  font-size: 0.9rem;
  color: #7f8c8d;
  font-weight: 500;
`;

export const MedicineDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
`;

export const DetailLabel = styled.span`
  color: #3498db;
  font-weight: 500;
  min-width: 100px;
`;

export const DetailValue = styled.span`
  color: #2c3e50;
  font-weight: 400;
  text-align: right;
  flex-grow: 1;
`;

export const MobileActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid #eee;
`;
export const IconButton = styled.button`
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s ease;

  &:hover {
    background: #f0f0f0;
  }

  &:active {
    transform: scale(0.95);
  }
`;
export const ActionButton = styled(IconButton)`
  padding: 6px;
  background: #f8f9fa;
  border-radius: 6px;
  
  &:hover {
    background: #e9ecef;
  }
  
  svg {
    color: #3498db;
    width: 18px;
    height: 18px;
  }
`;















// En styles.js
export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  backdrop-filter: blur(2px);
  padding: 20px 0;
`;

export const ModalContainer = styled.div`
  background: white;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(52, 152, 219, 0.2);
  display: flex;
  flex-direction: column;
`;
export const ModalHeader = styled.h2`
  background: #3498db;
  color: white;
  padding: 1.2rem;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
`;

export const ModalForm = styled.div`
  padding: 1.5rem;
  overflow-y: auto;
  flex-grow: 1;
  .input-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.2rem;

    @media (max-width: 480px) {
      flex-direction: column;
      gap: 0.8rem;
    }
  }

  .input-group {
    flex: 1;
    
    label {
      display: block;
      margin-bottom: 0.6rem;
      font-weight: 500;
      color: #2c3e50;
      font-size: 0.95rem;
    }
  }

  .full-width {
    flex: 0 0 100%;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.5rem;
  border-top: 1px solid #eee;
  background: white;
  position: sticky;
  bottom: 0;
  
  @media (max-width: 480px) {
    flex-direction: column-reverse;
    gap: 0.8rem;
    position: static;
    padding: 1rem;
  }
`;
export const ModalButton = styled.button`
  padding: 0.8rem 1.8rem;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.95rem;

  &.cancel {
    background: #f8f9fa;
    color: #2c3e50;
    border: 2px solid #e0e0e0;

    &:hover {
      background: #f0f0f0;
    }
  }

  &.save {
    background: #3498db;
    color: white;
    
    &:hover {
      background: #2980b9;
    }
  }
`;










export const SearchContainer = styled.div`
  position: relative;
  max-width: 800px;
  margin: 1.5rem auto;

  .search-input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
`;

export const SearchIcon = styled.svg.attrs({
  viewBox: '0 0 24 24',
  children: (
    <>
      <circle cx="11" cy="11" r="8" strokeWidth="2" fill="none"/>
      <path d="M21 21l-4.35-4.35" strokeWidth="2" fill="none"/>
    </>
  )
})`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 15px;
  z-index: 1;
  stroke: #3498db;
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 12px 20px 12px 45px;
  border: 2px solid #3498db;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: white;
  color: #2c3e50;
  
  &:focus {
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
    outline: none;
  }
  
  &::placeholder {
    color: #95a5a6;
  }
`;

export const SuggestionContainer = styled.div`
  position: absolute;
  width: 100%;
  background: white;
  border: 2px solid #3498db;
  border-radius: 8px;
  margin-top: 8px;
  box-shadow: 0 4px 12px rgba(52, 152, 219, 0.15);
  z-index: 1000;

  .loading-message, .no-results {
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 12px;
    color: #2c3e50;
  }
`;

export const SuggestionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  gap: 12px;
  border-bottom: 1px solid #f0f8ff;

  &:hover {
    background: #f8f9fa;
  }

  &:last-child {
    border-bottom: none;
  }

  .med-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .medicamento {
    font-weight: 600;
    color: #2c3e50;
  }

  .concentracion {
    font-size: 0.9rem;
    color: #7f8c8d;
  }
`;

// Iconos
const IconBase = styled.svg`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

export const LoadingSpinner = styled(IconBase).attrs({
  viewBox: '0 0 50 50',
  children: (
    <circle
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke="#3498db"
      strokeWidth="4"
      strokeLinecap="round"
    />
  )
})`
  animation: rotate 1s linear infinite;
  @keyframes rotate { 100% { transform: rotate(360deg); } }
`;

export const InfoIcon = styled(IconBase).attrs({
  viewBox: '0 0 24 24',
  children: (
    <>
      <circle cx="12" cy="12" r="10" strokeWidth="2" fill="none"/>
      <path d="M12 16v-4m0-4h.01" strokeWidth="2" fill="none"/>
    </>
  )
})`
  stroke: #3498db;
`;

export const MedicationIcon = styled(IconBase).attrs({
  viewBox: '0 0 24 24',
  children: (
    <>
      <path d="M12 2v20m-7-7h14M5 15l7-7 7 7" strokeWidth="2" fill="none"/>
    </>
  )
})`
  stroke: #3498db;
`;

export const ArrowIcon = styled(IconBase).attrs({
  viewBox: '0 0 24 24',
  children: <path d="M9 18l6-6-6-6" strokeWidth="2" fill="none"/>
})`
  stroke: #3498db;
`;








// En tu archivo styles.js
export const SpinnerIcon = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid #f0f0f0;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
