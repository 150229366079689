// import React, { useEffect, useState } from "react";]
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLoadScript } from '@react-google-maps/api';


import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Card, Button, InputGroup, Form } from "react-bootstrap";
import { Checkbox } from "../../components/Checkbox";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { setPacienteSesion } from "../../redux/sesion/sesionActions";
import { crearCuenta } from "../../redux/cuenta/cuentaActions";
import { CheckboxText } from "../../components/CheckboxText/index";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import { fechaToInpuHtml } from "../../helpers/fechas";
import {
  guardaPaciente,
  addPaciente,
  setPaciente,
  addPatologia,
  removePatologia,
  updatePatologia,
} from "../../redux/paciente/pacienteActions";

import { updatePacienteInArray } from "../../redux/pacientes/pacientesActions";
import { getPacienteByNumdocAndEmpresaId } from "../../services/firebase/pacientes";
import { TokenPac } from "../../components/TokenPac";

import {
  MainContainer,
  ContainerBody,
  FormStyled,
  STabs,
  STab,
  STabPanel,
  STabList,
  Tabla,
  TablaHeader,
  TablaHeaderLabel,
  TablaHeaderLabelTT,
  StyledInput,
  StyledSelect,
  TablaHeaderText,
  StyledInputTT,
  ContainerPatologias,
  ContainerPatologia,
  StyledButton,
  LabelStyled,
  CotenedorFechaNac,
  ContainerBtn,
  CardBody,
  StyledSelect1,
  StyledInput2,
  StyledInput3,

  PhoneContainer,
  // StyledSelect,
  StyledInputPhone
} from "./style";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useLocation } from 'react-router-dom';
import axios from "axios";

const ALERGIA = "ALERGIA";
const ESTOMATOLOGICA = "ESTOMATOLOGICA";

const MEDICA = "MEDICA";
const usePatologias = (patologiasPaciente) => {
  const [patologias, setPatologias] = React.useState([
    { llave: "Anestesia Local", checked: false, valor: "", tipo: ALERGIA },
    { llave: "Antibióticos", checked: false, valor: "", tipo: ALERGIA },
    { llave: "Antinflamatorios", checked: false, valor: "", tipo: ALERGIA },
    { llave: "Otros", checked: false, valor: "", tipo: ALERGIA },
    {
      llave: "Abcesos y F.D.",
      checked: false,
      valor: "",
      tipo: ESTOMATOLOGICA,
    },
    { llave: "Agenesias Den", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Bruxismo", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Desgaste Den", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Gingivitis", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Halitosis", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Periodontitis", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Piezas", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Prob Oclusión", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    {
      llave: "Sensibilidad Den",
      checked: false,
      valor: "",
      tipo: ESTOMATOLOGICA,
    },
    {
      llave: "Transtornos ATM",
      checked: false,
      valor: "",
      tipo: ESTOMATOLOGICA,
    },
    { llave: "Otros", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Asma", checked: false, valor: "", tipo: MEDICA },
    { llave: "Cancer", checked: false, valor: "", tipo: MEDICA },
    { llave: "Cardiopatias", checked: false, valor: "", tipo: MEDICA },
    { llave: "Diabetes", checked: false, valor: "", tipo: MEDICA },
    { llave: "Gastropatias", checked: false, valor: "", tipo: MEDICA },
    { llave: "Hepatitis", checked: false, valor: "", tipo: MEDICA },
    { llave: "Hepatopatias", checked: false, valor: "", tipo: MEDICA },
    { llave: "Hipertensión", checked: false, valor: "", tipo: MEDICA },
    { llave: "Hipotension", checked: false, valor: "", tipo: MEDICA },
    { llave: "Nefropatias", checked: false, valor: "", tipo: MEDICA },
    { llave: "Tuberculosis", checked: false, valor: "", tipo: MEDICA },
    { llave: "Transt Mentales", checked: false, valor: "", tipo: MEDICA },
    { llave: "Transt Neuralgicos", checked: false, valor: "", tipo: MEDICA },
    { llave: "Transt Psiquiatrico", checked: false, valor: "", tipo: MEDICA },
    { llave: "Transt Hemorragic", checked: false, valor: "", tipo: MEDICA },
    { llave: "VIH SIDA", checked: false, valor: "", tipo: MEDICA },
    { llave: "Otros", checked: false, valor: "", tipo: MEDICA },
  ]);
  const patologiasFiltradas = patologias.map((item) => {
    let ele = patologiasPaciente.find(
      (it) => it.llave === item.llave && it.tipo === item.tipo
    );
    return ele ? ele : item;
  });
  return [patologiasFiltradas, setPatologias];
};


const countryCodes = [
  { name: "Perú", code: "+51" },
  { name: "México", code: "+52" },
  { name: "Argentina", code: "+54" },
  { name: "Chile", code: "+56" },
  { name: "Colombia", code: "+57" },
  { name: "España", code: "+34" },
];
const sexo = [
  { name: "Masculino" },
  { name: "Femenino" },
];

const fechaACadena = (fecha) => {
  let f;
  if (fecha === null || fecha === undefined || fecha === "" || isNaN(fecha))
    f = new Date();
  else f = fecha;
  const a = f.getFullYear();
  const m = (f.getMonth() + 1).toString().padStart(2, "0");
  const d = f.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
};

const GOOGLE_MAPS_API_KEY = 'AIzaSyAprSBZYIYG_Qpr4reb12lt8DcCasp3i4E';
const libraries = ['places'];
export const Paciente = (props) => {

  const paciente = useSelector((state) => state.paciente);
  const [localPaciente, setLocalPaciente] = useState({ ...paciente });
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const pacientes = useSelector((state) => state.pacientes);
  // const cuenta = useSelector((state) => state.cuenta);
  const dispatch = useDispatch();
  const [patologias, setPatologias] = usePatologias(paciente.patologias);
  const { addToast } = useToasts();
  // const editarPaciente = "Editar Paciente";
  // const nuevoPaciente = "Nuevo Paciente";
  const sesion = useSelector((state) => state.sesion);
  const empresaId = sesion.usuario.empresaId
  // const [startDate, setStartDate] = useState(new Date());
  const [token, setToken] = React.useState("")
  const APP_PACIENTE_URL = `${process.env.REACT_APP_APP_PACIENTE_URL}`
  // const endpoint = 'http://127.0.0.1:5001/sysdent-devs/us-central1'
  const API_URL_BASE = `${process.env.REACT_APP_API_URL}`


  const [selectedCode, setSelectedCode] = useState(countryCodes[0].code); // Default Peru (+51)



  const defaultUbicacion = {
    direccion: '',
    pais: '',
    departamento: '',
    provincia: '',
    distrito: '',
    lat: null,
    lng: null,
  };
  const direccion = ''; // Valor por defecto si no existe otra dirección

  const [ubicacion, setUbicacion] = useState(() => {
    // const dir = paciente.direccion;
    const dir = paciente?.direccion ?? direccion;

    console.log('direccion', typeof dir)
    // Si es una cadena, crea un objeto con la estructura nueva
    if (typeof dir === 'string') {
      return { ...defaultUbicacion, direccion: dir };
    }

    // Si es un objeto, combínalo con los valores por defecto
    if (typeof dir === 'object' && dir !== null) {
      console.log('es objeto')
      return { ...defaultUbicacion, ...dir };
    }

    // Si no hay dirección, usa los valores por defecto
    return defaultUbicacion;
  });

  const inputRef = useRef(null);
  const autocompleteRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });
  const [selectedSexo, setSelectedSexo] = useState(sexo[0]); // Default: Hombre

  const handleSexoCodeChange = (e) => {
    setSelectedSexo({ name: e.target.value });
    dispatch(setPaciente({ ...paciente, sexo: e.target.value }));
  };
  const handlePlaceChanged = useCallback(() => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      console.log("Google Place Object:", place); // Para depuración

      if (place?.formatted_address) {
        const nuevaUbicacion = {
          direccion: place.formatted_address,
          pais: '',
          departamento: '',
          provincia: '',
          distrito: '',
          lat: null,
          lng: null
        };

        // Mapeo mejorado para Perú
        place.address_components.forEach(component => {
          const types = component.types;

          // País
          if (types.includes('country')) {
            nuevaUbicacion.pais = component.long_name;
          }

          // Departamento (usando administrative_area_level_1 y locality como respaldo)
          if (types.includes('administrative_area_level_1')) {
            nuevaUbicacion.departamento = component.long_name;
          } else if (types.includes('locality') && !nuevaUbicacion.departamento) {
            nuevaUbicacion.departamento = component.long_name;
          }

          // Provincia (administrative_area_level_2)
          if (types.includes('administrative_area_level_2')) {
            nuevaUbicacion.provincia = component.long_name;
          }

          // Distrito (sublocality_level_1 como prioridad)
          if (types.includes('sublocality_level_1')) {
            nuevaUbicacion.distrito = component.long_name;
          } else if (types.includes('locality')) {
            nuevaUbicacion.distrito = component.long_name;
          }
        });

        if (place.geometry?.location) {
          nuevaUbicacion.lat = place.geometry.location.lat();
          nuevaUbicacion.lng = place.geometry.location.lng();
        }

        console.log("Ubicación procesada:", nuevaUbicacion); // Verifica los datos
        setUbicacion(nuevaUbicacion);
        dispatch(setPaciente({ ...paciente, direccion: nuevaUbicacion }));
      }
    }
  }, [paciente, dispatch]);
  useEffect(() => {
    if (isLoaded && inputRef.current) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ['geocode'],
          componentRestrictions: { country: 'PE' },
          fields: ['address_components', 'formatted_address', 'geometry']
        }
      );

      autocompleteRef.current.addListener('place_changed', () => {
        handlePlaceChanged();
      });

      return () => {
        if (autocompleteRef.current) {
          window.google.maps.event.clearInstanceListeners(autocompleteRef.current);
        }
      };
    }
  }, [isLoaded, handlePlaceChanged]);
  if (!isLoaded) {
    return <div>Cargando...</div>;
  }

  if (loadError) {
    console.error('Error al cargar la API de Google Maps:', loadError);
    return <div>Error al cargar la API de Google Maps</div>;
  }

  const handleCountryCodeChange = (e) => {
    setSelectedCode(e.target.value);
    dispatch(setPaciente({ ...paciente, telefono: e.target.value + " " + paciente.telefono?.split(" ")[1] || "" }));
  };

  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value;
    dispatch(setPaciente({ ...paciente, telefono: phoneNumber }));
  };




  const validar = async () => {
    let emailRegex;
    if (paciente.tipDoc === "DNI" && paciente.numDoc.toString().length != 8)
      throw new Error(`DNI inválido`);
    if (paciente.correo && paciente.correo != "") {
      emailRegex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!emailRegex.test(paciente.correo)) throw new Error("Correo inválido");
    }
    if (!paciente.fecNac) throw new Error(`Ingrese una fecha válida`);
    let fecNac = new Date(paciente.fecNac);
    let hoy = new Date()

    if (fecNac >= hoy) throw new Error("Fecha de nacimiento inválida");
    if (!paciente.id) // se ejecuta solo si se esta creando un nuevo paciente
      await documentoValido(paciente);
  };


  const documentoValido = async () => {
    const localPaciente = await getPacienteByNumdocAndEmpresaId(paciente.numDoc, empresaId)
    if (localPaciente) throw new Error("Paciente ya existe");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Validar los datos antes de continuar
      console.log("Validando paciente", paciente);
      await validar();

      // Asegurarse de que formData.direccion no esté vacío
      if (!ubicacion.direccion) {
        throw new Error("La dirección no puede estar vacía.");
      }
      if (!paciente.sexo) {
        throw new Error("El campo sexo no puede estar vacío.");
      }



      // Crear el objeto direccion que incluye departamento, provincia y distrito
      paciente["direccion"] = {
        direccion: ubicacion.direccion, // Dirección específica
        pais: ubicacion.pais, // pais
        departamento: ubicacion.departamento || "", // Validar que existe departamento
        provincia: ubicacion.provincia || "", // Validar que existe provincia
        distrito: ubicacion.distrito || "", // Validar que existe distrito
        lat: ubicacion.lat,  // Latitude
        lng: ubicacion.lng   // Longitude
      };

      // Añadir otras propiedades al objeto paciente
      paciente["patologias"] = patologias.filter((it) => it.checked);
      paciente.empresaId = sesion.usuario.empresaId;
      // Aquí agregas el campo sexo al objeto paciente si no está presente.

      console.log('Paciente antes de guardar:', paciente);

      // Si el paciente no tiene un id (es nuevo), se añade, si tiene id, se guarda
      if (paciente.id == null || paciente.id === "")
        dispatch(addPaciente(paciente));
      else
        dispatch(guardaPaciente(paciente));

      // Actualizar el estado de paciente y otras acciones relacionadas
      dispatch(setPaciente(paciente));
      dispatch(setPacienteSesion(true));
      dispatch(updatePacienteInArray(paciente));

      // Notificar que los cambios se guardaron con éxito
      addToast("Se guardaron los cambios", { appearance: "success" });
    } catch (err) {
      // Manejar errores de validación o de otros tipos
      addToast(err.message, { appearance: "error" });
    }
  };



  const handleCheckChange = (index, item) => {
    if (item.checked) dispatch(addPatologia(item));
    else dispatch(removePatologia(item));
  };
  const handleTextChange = (index, item) => {
    dispatch(updatePatologia(item));
  };

  const getPatologias = (tipo) => {
    if (patologias == null) return;
    let items = patologias
      .filter((it) => it.tipo == tipo)
      .map((item, index) => (
        <ul key={index} className="list-group">
          <li className="list-group-item">
            <CheckboxText
              index={index}
              item={item}
              onCheckChange={handleCheckChange}
              onTextChange={handleTextChange}
            />
          </li>
        </ul>
      ));

    return <ol>{items}</ol>;
  };

  const handleChange = (e) => {
    let myPac = { ...paciente };
    let { name, value } = e.target;
    let updatedValue = value;
    if (e.target.name == "fecNac") {
      var parts = e.target.value.split("-");
      var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
      dispatch(setPaciente({ ...myPac, [e.target.name]: mydate }));
    }


    // If updating "direccion", sync with ubicacion state too
    if (e.target.name === "direccion") {
      if (updatedValue === "") {

        setUbicacion((prev) => ({ ...prev, direccion: updatedValue, departamento: "", provincia: "", distrito: "", pais: "", lng: "", lat: "" }));
      }
      else {
        setUbicacion((prev) => ({ ...prev, direccion: updatedValue }));
      }



    }

    dispatch(setPaciente({ ...myPac, [e.target.name]: updatedValue }));
  };







  function handleGetToken() {
    fetch(`https://us-central1-odontoplus-4db47.cloudfunctions.net/api/v1/pacientes/token?userid=${paciente.id}&empresaid=${paciente.empresaId}`)
      .then(async (response) => {
        const res = await response.json();
        let myPac = { ...paciente };
        dispatch(setPaciente({ ...myPac, token: `${APP_PACIENTE_URL}?token=${res.token}` }));
        //setToken(`${urlBase}?token=${res.token}`);
      })
  }


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelStyledledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  if (sesion.loading || sesion.loading === undefined)
    return <div>...procesando</div>;
  if (sesion.error) return <div>{`Hubo un error ${sesion.error}`}</div>;
  return (
    <MainContainer>
      <TopBarPaciente />
      <ContainerBody>
        <FormStyled className="form form--simple" onSubmit={handleSubmit}>
          <STabs
            selectedTabClassName="is-selected"
            selectedTabPanelClassName="is-selected"
          >
            <STabList>
              <STab>Datos Generales  </STab>
              <STab>Patologías</STab>
            </STabList>

            {/* Panel de Datos Generales */}
            <STabPanel>
              <Card>
                <CardBody>
                  <Tabla>
                    <TablaHeader>
                      {/* Tipo de Documento */}
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="tipDoc">Tip. Doc.</LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledSelect
                            name="tipDoc"
                            id="tipDoc"
                            className="form__select"
                            value={paciente.tipDoc || ""}
                            onChange={handleChange}
                            required
                          >
                            <option value="DNI">DNI</option>
                            <option value="CE">CE</option>
                            <option value="RUC">RUC</option>
                          </StyledSelect>
                        </TablaHeaderText>
                      </tr>

                      {/* Número de Documento */}
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="numDoc">Num. Doc.</LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            className="form__field"
                            name="numDoc"
                            value={paciente.numDoc || ""}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                          />
                        </TablaHeaderText>
                      </tr>

                      {/* Apellidos */}
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="apellidos">Apellidos</LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            className="form__field"
                            type="text"
                            name="apellidos"
                            value={paciente.apellidos || ""}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                          />
                        </TablaHeaderText>
                      </tr>

                      {/* Nombres */}
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="nombres">Nombres</LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            className="form__field"
                            type="text"
                            name="nombres"
                            value={paciente.nombres || ""}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                          />
                        </TablaHeaderText>
                      </tr>
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="sexo">Sexo</LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledSelect
                            name="sexo"
                            id="sexo"
                            className="form__select"
                            value={paciente.sexo || ""}
                            onChange={handleChange}
                            required
                          >
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>

                          </StyledSelect>
                        </TablaHeaderText>
                      </tr>


                      {/* Dirección */}
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="direccion">Dirección</LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            type="text"
                            name="direccion"
                            id="direccion"
                            ref={inputRef}
                            value={ubicacion.direccion || ""}
                            onChange={handleChange
                            }
                            autoComplete="off"
                            placeholder="Ingrese su dirección"
                          />
                        </TablaHeaderText>
                      </tr>

                      {/* Ubicación */}
                      {(paciente?.direccion || direccion) && ubicacion && (ubicacion.pais || ubicacion.departamento || ubicacion.provincia || ubicacion.distrito) && (
                        <>
                          {ubicacion.pais && (
                            <tr>
                              <TablaHeaderLabel>
                                <LabelStyled>Pais</LabelStyled>
                              </TablaHeaderLabel>
                              <TablaHeaderLabel>{ubicacion.pais}</TablaHeaderLabel>
                            </tr>
                          )}
                          {ubicacion.departamento && (
                            <tr>
                              <TablaHeaderLabel>
                                <LabelStyled>Departamento</LabelStyled>
                              </TablaHeaderLabel>
                              <TablaHeaderLabel>{ubicacion.departamento}</TablaHeaderLabel>
                            </tr>
                          )}
                          {ubicacion.provincia && (
                            <tr>
                              <TablaHeaderLabel>
                                <LabelStyled>Provincia</LabelStyled>
                              </TablaHeaderLabel>
                              <TablaHeaderLabel>{ubicacion.provincia}</TablaHeaderLabel>
                            </tr>
                          )}
                          {ubicacion.distrito && (
                            <tr>
                              <TablaHeaderLabel>
                                <LabelStyled>Distrito</LabelStyled>
                              </TablaHeaderLabel>
                              <TablaHeaderLabel>{ubicacion.distrito}</TablaHeaderLabel>
                            </tr>
                          )}
                        </>
                      )}

                      {/* Fecha de Nacimiento */}
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="fecNac">Fec. Nac.</LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            type="date"
                            name="fecNac"
                            value={paciente.fecNac || ""}
                            onChange={handleChange}
                          />
                        </TablaHeaderText>
                      </tr>

                      {/* Teléfono */}
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="telefono">Teléfono</LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>

                          <StyledInput
                            name="telefono"
                            type="tel"
                            autoComplete="off"
                            value={paciente.telefono}
                            onChange={handlePhoneChange}
                            required
                          />
                        </TablaHeaderText>
                      </tr>

                      {/* Correo */}
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="correo">Correo</LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            type="email"
                            name="correo"
                            value={paciente.correo || ""}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </TablaHeaderText>
                      </tr>
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled
                            className="form__labelStyled"
                            htmlFor="invitar"
                          >
                            Invitacion
                          </LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <TokenPac token={paciente.token} onGetToken={handleGetToken} />
                        </TablaHeaderText>
                      </tr>
                    </TablaHeader>
                  </Tabla>
                  <ContainerBtn>
                    <StyledButton type="submit">Guardar</StyledButton>
                  </ContainerBtn>
                </CardBody>
              </Card>
            </STabPanel>

            {/* Panel de Patologías */}
            <STabPanel>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  Médicas
                </AccordionSummary>
                <AccordionDetails>
                  <ContainerPatologias>
                    <ContainerPatologia>
                      {getPatologias(MEDICA)}
                    </ContainerPatologia>
                  </ContainerPatologias>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography> Alergias/Alertas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ContainerPatologias>
                    <ContainerPatologia>
                      {getPatologias(ALERGIA)}
                    </ContainerPatologia>
                  </ContainerPatologias>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Estomatológicas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ContainerPatologias>
                    <ContainerPatologia>
                      {getPatologias(ESTOMATOLOGICA)}
                    </ContainerPatologia>
                  </ContainerPatologias>
                </AccordionDetails>
              </Accordion>
              <ContainerBtn>
                <StyledButton type="submit" value="Guardar">
                  Guardar
                </StyledButton>
              </ContainerBtn>
            </STabPanel>

          </STabs>
        </FormStyled>
      </ContainerBody>
    </MainContainer>
  );

};