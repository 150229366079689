import { db } from "../Config";

export const addReceta = (receta) => {
  db.collection("recetas")
    .add(receta)
    .then((docRef) => {})
    .catch((err) => {
      console.log("error al agregar documento" + err);
    });
};

export const addItemReceta = (recetaId, item) => {
  db.collection("recetas")
    .doc(recetaId)
    .add(item)
    .then((docRef) => {})
    .catch((err) => {
      console.log("error al guardar documento");
    });
};
export const tieneMedicamentosConfigurados = async (empresaId) => {
  try {
    const medicamentosRef = db.collection("medicamentos");
    const snapshot = await medicamentosRef
      .where("empresaId", "==", empresaId)
      .limit(1)
      .get();

    return !snapshot.empty;
  } catch (error) {
    console.error("Error al verificar medicamentos:", error);
    return false;
  }
};


export const getMedicamentos = async (searchTerm, empresaIdBusqueda) => {
  try {
    if (!searchTerm || !empresaIdBusqueda) {
      return [];
    }
    
    const medicamentosRef = db.collection("medicamentos");
    const snapshot = await medicamentosRef
      .where("empresaId", "==", empresaIdBusqueda)
      .get();
    
    // Filtrar los resultados en memoria
    let results = snapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data(),
        medicamento: doc.data().medicina // Mantener compatibilidad con la propiedad medicamento
      }))
      .filter(med => {
        const searchLower = searchTerm.toLowerCase();
        const medLower = med.medicamento.toLowerCase();
        return medLower.includes(searchLower);
      });
      
    // Eliminar duplicados basados en nombre y concentración
    const uniqueResults = [];
    const seen = new Set();
    
    for (const med of results) {
      // Crear una clave única basada en nombre y concentración
      const key = `${med.medicamento.toLowerCase()}_${med.concentracion}`;
      
      if (!seen.has(key)) {
        seen.add(key);
        uniqueResults.push(med);
      }
    }
    
    // Ordenar por nombre y luego por concentración (mayor primero)
    const sortedResults = uniqueResults.sort((a, b) => {
      // 1. Primero ordenar alfabéticamente por nombre del medicamento
      const compareName = a.medicamento.toLowerCase().localeCompare(b.medicamento.toLowerCase());
      
      // 2. Si los nombres son iguales, ordenar por concentración (de mayor a menor)
      if (compareName === 0) {
        return b.concentracion - a.concentracion;
      }
      
      return compareName;
    })
    .slice(0, 5); // Limitar a 5 resultados
    
    return sortedResults;
  } catch (error) {
    console.error("Error al obtener medicamentos:", error);
    throw error;
  }
};