
import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setReceta } from "../../redux/receta/recetaActions";
import { Receta as RecetaObj } from "../../Entidades/Recetas";
import { traerRecetas } from "../../redux/recetas/recetasActions";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerLeft,
  ContenedorLista,
  Liga,
  ContainerBody,
  StyledLink,
  ContainerLista,
  UlLista,
  StyledTextarea,
  LiLista,
  MedicamentosTablasContainer,
  TablaHeaderRow,
  TablaRow,
  FlexContainer,
  Tabla,
  TablaContainer,
  StyledInput,
  MedicamentoCard,
  MedicamentoCardHeader,
  MedicamentoCardRow,
  MedicamentoCardLabel,
  MedicamentoCardValue,
  TablaHeader,
  TablaBody,
  TablaBodyTexto,
  TablaHeaderTexto,
  FlexContainerRight,
} from "./styles";
import Spinner from "react-bootstrap/Spinner";

const getFecha = (fecha) => {
  const a = fecha.getFullYear();
  const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const d = fecha.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
};

const Receta = ({ receta, url }) => {
  return (
    <div>
      <ContainerTitle>
        <ContainerTitleText>Receta Odontol&oacute;gica</ContainerTitleText>
      </ContainerTitle>

      <ContainerBody>
        <FlexContainer>
          <a href={url} target="_blank" rel="noopener noreferrer">
            Descargar PDF
          </a>
        </FlexContainer>
        <FlexContainer>
          <ContainerTitleText>
            {`Paciente: ${receta.pacienteNombres}`}
          </ContainerTitleText>

          <ContainerTitleText>{`Fecha: ${getFecha(
            receta.fecha
          )}`}</ContainerTitleText>
        </FlexContainer>
  
  <MedicamentosTablasContainer>
      {/* Versión para laptop - visible en tablets y superiores */}
      <div className="tabla-laptop">
        <TablaContainer>
          <Tabla>
            <TablaHeader>
              <TablaHeaderRow>
                <TablaHeaderTexto>Medicina</TablaHeaderTexto>
                <TablaHeaderTexto>Concentración</TablaHeaderTexto>
                <TablaHeaderTexto>Presentación</TablaHeaderTexto>
                <TablaHeaderTexto>Cantidad</TablaHeaderTexto>
                <TablaHeaderTexto>Dosis y frecuencia</TablaHeaderTexto>
                <TablaHeaderTexto>Duración</TablaHeaderTexto>
              </TablaHeaderRow>
            </TablaHeader>
            <TablaBody>
              {receta.items.map((item, index) => (
                <TablaRow key={index}>
                  <TablaBodyTexto>{item.medicina}</TablaBodyTexto>
                  <TablaBodyTexto>{item.concentracion}</TablaBodyTexto>
                  <TablaBodyTexto>{item.formato}</TablaBodyTexto>
                  <TablaBodyTexto>{item.cantidad}</TablaBodyTexto>
                  <TablaBodyTexto>{item.dosisYFrecuencia}</TablaBodyTexto>
                  <TablaBodyTexto>{item.duracion}</TablaBodyTexto>
                </TablaRow>
              ))}
            </TablaBody>
          </Tabla>
        </TablaContainer>
      </div>

      {/* Versión para móvil - visible en dispositivos pequeños */}
      <div className="tabla-movil">
        {receta.items.map((item, index) => (
          <MedicamentoCard key={index}>
            <MedicamentoCardHeader>
              <h3>{item.medicina}</h3>
              <span>{item.concentracion}</span>
            </MedicamentoCardHeader>

            <MedicamentoCardRow>
              <MedicamentoCardLabel>Presentación:</MedicamentoCardLabel>
              <MedicamentoCardValue>{item.formato}</MedicamentoCardValue>
            </MedicamentoCardRow>

            <MedicamentoCardRow>
              <MedicamentoCardLabel>Cantidad:</MedicamentoCardLabel>
              <MedicamentoCardValue>{item.cantidad}</MedicamentoCardValue>
            </MedicamentoCardRow>

            <MedicamentoCardRow>
              <MedicamentoCardLabel>Dosis y frecuencia:</MedicamentoCardLabel>
              <MedicamentoCardValue>{item.dosisYFrecuencia}</MedicamentoCardValue>
            </MedicamentoCardRow>

            <MedicamentoCardRow>
              <MedicamentoCardLabel>Duración:</MedicamentoCardLabel>
              <MedicamentoCardValue>{item.duracion}</MedicamentoCardValue>
            </MedicamentoCardRow>
          </MedicamentoCard>
        ))}
      </div>
      <div className="input-row">
  <div className="input-group">
    <label>Indicaciones Adicionales*</label>
    <StyledTextarea
      value={receta.indicacionesAdicionales || ""}
      readOnly
      rows={4}
    />
  </div>
</div>
    </MedicamentosTablasContainer>
        <FlexContainerRight>
          <ContainerTitleText>
            {`C.D. ${receta.especialistaNombres}`}
          </ContainerTitleText>
        </FlexContainerRight>
        <FlexContainerRight>
          <ContainerTitleText>{`C.O.P. ${receta.especialistaCop}`}</ContainerTitleText>
        </FlexContainerRight>
      </ContainerBody>
    </div>
  );
};
const ListaRecetas = ({ items }) => {
  const dispatch = useDispatch();
  const handleClick = (receta) => {
    dispatch(setReceta(receta));
  };
  return (
    <ContainerLista>
      Recetas
      <UlLista>
        {items.map((receta, index) => (
          <LiLista>
            <Liga onClick={() => handleClick(receta)}>
              {`N°${index} - ${getFecha(receta.fecha)}`}
            </Liga>
          </LiLista>
        ))}
      </UlLista>
    </ContainerLista>
  );
};
export const Recetas = () => {
  const paciente = useSelector((state) => state.paciente);
  const sesion = useSelector((state) => state.sesion);
  const recetas = useSelector((state) => state.recetas);
  const receta = useSelector((state) => state.receta);
  const dispatch = useDispatch();
  const [url, setUrl] = React.useState();
  React.useEffect(() => {
    dispatch(traerRecetas(paciente.id));
  }, []);

  React.useEffect(() => {
     let url = `${process.env.REACT_APP_API_URL}/api/v1/reportes/recetas/${receta.id}`;
    //  let url = `http://localhost:5001/odontoplus-4db47/us-central1/api/v1/reportes/recetas/${receta.id}`;
    //let url = `http://127.0.0.1:5001/sysdent-devs/us-central1/api/v1/reportes/recetas/${receta.id}`;
    // let url = `https://us-central1-sysdent-devs.cloudfunctions.net/api/v1/reportes/recetas/${receta.id}`;
    if (receta.id) setUrl(url);
  }, [receta, sesion]);
  if (sesion.loading || sesion.loading === undefined) {
    return (
      <MainContainer>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </MainContainer>
    );
  } else if (sesion.error) {
    return <div>{`ocurrio un error ${sesion.error}`}</div>;
  } else
    return (
      <>
        <MainContainer>
          <TopBarPaciente />
          <ContainerBody>
            <ContainerLeft>
              <StyledLink to="receta" className="btn">
                NUEVA RECETA
              </StyledLink>
            </ContainerLeft>
            <div>
              <ContenedorLista>
                <ListaRecetas items={recetas.items}></ListaRecetas>
              </ContenedorLista>
              {receta.id && <Receta receta={receta} url={url} />}
              {/* <ContainerIFrame>
                {url && (
                  <IFrame src={url} frameborder="0" scrolling="no"></IFrame>
                )}
              </ContainerIFrame> */}
            </div>
          </ContainerBody>
        </MainContainer>
      </>
    );
};