import React, { useEffect, useState } from "react";
import {
  RegistroWrapper,
  RegistroFormWrapper,
  ContainerTitle,
  ContainerTitleText,
  StyledForm,
  ContainerLeft,
  StyledButton,
  DivInput,
  StyledLabel,
  StyledInput,
} from "./styles";
import { setUser, saveUsuario } from "../../redux/usuario/usuarioActions";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { db, firebaseConfig } from "../../services/firebase/Config";
import firebase from "firebase/app";

import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useLocation } from 'react-router-dom'
import { GoogleLogin } from "react-google-login";
import { usiarioUploadImage } from "../../services/firebase/usuarios/index";

import axios from "axios";

export const Usuario = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const location = useLocation();
  const fullUrl = `${window.location.origin}${location.pathname}${location.search}`;
  const usuario = useSelector((state) => state.usuario);
  const [usuarioEmpresa] = useLocalStorage("usuario");
  const empresa = useSelector((state)=> state.empresa)
  const [isAuthorizeButtonDisabled, setIsAuthorizeButtonDisabled] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL
  
  //let url = `${process.env.REACT_APP_API_URL}/api/v1/calendar/create-tokens`;
  //let url = 'http://127.0.0.1:5001/sysdent-devs/us-central1/api/v1/calendar/create-tokens'
  // let url = 'http://127.0.0.1:5001/sysdent-devs/us-central1/api/v1/calendar/create-tokens'
  //let url = 'http://localhost:3008/v1/auth-google-calendar'
  function handleAuthorize() {
    setIsAuthorizeButtonDisabled(true);
    // Redirect to your backend endpoint
    const additionalParams = {
      empresaId: empresa.id
      // any other parameters you want to pass
    };
    
    const params = new URLSearchParams(additionalParams);
    const authorizeUrl = `${API_URL}/api/v1/calendar/authorize?${params.toString()}`;
    window.location.href = authorizeUrl;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let newUser = { ...usuario.data };
      console.log(' estos datos sin que se gragar',newUser)

      dispatch(saveUsuario(newUser));
      addToast("Usuario guardado satisfactoriamente", {
        appearance: "success",
      });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  
  const handleChange = (e) => {
    dispatch(setUser({ ...usuario.data, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    // Verificar si la URL tiene un parámetro de éxito de autorización
    const urlParams = new URLSearchParams(window.location.search);
    const authSuccess = urlParams.get('authSuccess');

    if (authSuccess) {
      addToast("Tokens guardados satisfactoriamente", {
        appearance: "success",
      });
      setIsAuthorizeButtonDisabled(false);
    }
  }, [addToast]);



  const onFileChange = async (e) => {
    const file = e.target.files[0];
    const fileUrl = await usiarioUploadImage(file);
    dispatch(setUser({ ...usuario.data, firma: fileUrl }));
  };

  if (usuario.loading || usuario.loading === undefined)
    return <div>...procesando</div>;
  if (usuario.error) return <div>{`Hubo un error ${usuario.error}`}</div>;
  return (
    <RegistroWrapper>
      <ContainerTitle>
        <ContainerTitleText>Datos Usuario</ContainerTitleText>
      </ContainerTitle>
      <RegistroFormWrapper>
        <form onSubmit={handleSubmit}>
          <StyledForm>
            <DivInput>
              <StyledLabel>Nombres</StyledLabel>
              <StyledInput
                name="nombres"
                className="form__input"
                placeholder="Nombres"
                type="text"
                value={usuario.data.nombres}
                onChange={handleChange}
                required
              />
            </DivInput>
            <DivInput>
              <StyledLabel>Cargo</StyledLabel>
              <StyledInput
                name="cargo"
                className="form__input"
                placeholder="Cargo"
                type="text"
                value={usuario.data.cargo}
                onChange={handleChange}
                required
              />
            </DivInput>
            <DivInput>
              <StyledLabel>Email</StyledLabel>
              <StyledInput
                name="email"
                className="form__input"
                placeholder="Usuario"
                type="text"
                value={usuario.data.email}
                required
              />
            </DivInput>
            <DivInput>
              <StyledLabel>Perfil</StyledLabel>

              <StyledInput
                name="perfil"
                className="form__input"
                placeholder="Perfil"
                type="text"
                value={usuario.data.perfil}
              />
            </DivInput>

            <DivInput>
              <StyledLabel>COP</StyledLabel>

              <StyledInput
                name="cop"
                className="form__input"
                placeholder="COP"
                type="text"
                value={usuario.data.cop}
                onChange={handleChange}
                required
              />
            </DivInput>

{/*             
            <DivInput>
              <StyledLabel>Seleccione</StyledLabel>
              <div className="button-wrapper">
                <span className="label">
                  {<AddPhotoAlternateIcon />} Logo
                </span>
                <input
                  type="file"
                  className="upload-box"
                  onChange={onFileChange}
                />
              </div>
            </DivInput> */}
          </StyledForm>
          <ContainerLeft>
            <StyledButton className="form__button" type="submit">
              Guardar
            </StyledButton>
           
            <button type="button"  disabled={isAuthorizeButtonDisabled} onClick={handleAuthorize}>Authorize Google Calendar</button>
            

          </ContainerLeft>
        </form>
      </RegistroFormWrapper>
    </RegistroWrapper>
  );
};
