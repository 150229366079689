import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import Layout from "./Layout";
import { AuthProvider } from "./Auth";
import { fetchPacientes } from "../redux/pacientes/pacientesActions";
import { fetchTratamientos } from "../redux/tratamientos/tratamientosActions";
import { getEmpresa } from "../redux/empresa/empresaActions";
import NotFound from "./NotFound";
import Login from "../pages/Login";
import LoginEmail from "../pages/LoginEmail";
import { Register } from "../pages/Register/index";
import { ResetPassword } from "../pages/ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { httpRequest, setFechaSesion } from "../redux/sesion/sesionActions";
import { GlobalStyle } from "../GlobalStyles";
import { PrivateRoute } from "./PrivateRoute";
import { PrivateRoutePaciente } from "./PrivateRoutePaciente";
import { CalendarioGoogle } from "../pages/CalendarioGoogle";
import { getGastosFijos } from "../redux/gastosFijos/gastosFijosActions";
import { fetchSubGrupos } from "../redux/subGrupos/subGruposActions";
import { Paciente } from "../pages/Paciente";
import { OrdenesRx } from "../pages/OrdenesRx";
import { OrdenRx } from "../pages/OrdenRx";
import { Planes } from "../pages/Planes";
//Paciente
import { Prestaciones } from "../pages/Prestaciones";
import { Pagos } from "../pages/Pagos";
import { OrdenesPaciente } from "../pages/OrdenesPaciente";
import { PagoPrestacion } from "../pages/PagoPrestacion";
import { IniciarPrestacion } from "../pages/IniciarPrestacion";
import { Presupuesto } from "../pages/Presupuesto";
import { Odontograma } from "../pages/Odontograma";
import { OdontogramaInicial } from "../pages/OdontogramaInicial";
import { HistoriaClinica } from "../pages/HistoriaClinica";
import { AbonoCuenta } from "../pages/AbonoCuenta";
import { XPrestaciones } from "../pages/Extraordinarias/XPrestaciones";
import { Devolucion } from "../pages/Extraordinarias/Devolucion";
import { Recetas } from "../pages/Recetas";
import { Receta } from "../pages/Receta";
import { Medicamentos } from "../pages/Medicamentos";
import { ReportesPaciente } from "../pages/ReportesPaciente";
import { PublicRoute } from "./PublicRoute";
import { Consentimientos } from "../pages/Consentimientos";
//Clinica
import { Tratamientos } from "../pages/Tratamientos/index";
import { Tarifarios } from "../pages/Tarifarios/index";
import { DeudasLabs } from "../pages/DeudasLabs/index";
import { PagoLab } from "../pages/PagoLab/index";
import { OrdenesLab } from "../pages/OrdenesLab/index";
import { ConfigTratamiento } from "./ConfigTratamiento/index";
import { ConfigMedicamentos } from "./ConfigMedicamentos/index";
import { NuevoTratamiento } from "../pages/NuevoTratamiento/index";
import { NuevoMedicamentos } from "../pages/NuevoMedicamentos/index";
import { Dashboard } from "../pages/Dashboard";
import { Inicio } from "../pages/Inicio";
import { LayoutPaciente } from "./LayoutPaciente/index";
import { Empresa } from "../pages/Empresa/index";
import { GastosFijos } from "../pages/GastosFijos";
import { Metas } from "../pages/Metas";
import { OrdenesRxAtendidasRes } from "../pages/OrdenesRxAtendidasRes";
import { Usuario } from "../pages/Usuario/index";
import { AbonosRpt } from "../pages/ReportesEmpresa/Abonos";
import { DeudoresRpt } from "../pages/ReportesEmpresa/Deudores";
import { Horarios } from "../pages/HorariosEspecialistas/index";
import { Chats } from "../pages/Whatsapp/Chats";
import { Perfiles } from "../pages/Perfiles";
import { getAccesosByEmpresa } from "../redux/accesos/accesosActions";
import {
  setUsuarioSesion,
  httpFailure,
} from "../redux/sesion/sesionActions";
import {
  setUser
} from "../redux/usuario/usuarioActions";
import LogoWaterMark from "./LogoWaterMark/";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { PerfilesUsuarios } from "../pages/PerfilesUsuarios";

const getFecha = () => {
  const fecha = new Date();
  const a = fecha.getFullYear();
  const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const d = fecha.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
};

const MainApp = () => {
  const [usuario] = useLocalStorage('usuario')
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.sesion);
  let { path, url } = useRouteMatch();
  const getDatos = async () => {
    try {
      if (usuario.empresaId) {
        dispatch(setUser(usuario))
        dispatch(httpRequest())
        dispatch(setUsuarioSesion(usuario));
        dispatch(getEmpresa(usuario.empresaId));
        dispatch(getGastosFijos(usuario.empresaId));
        dispatch(getAccesosByEmpresa(usuario.perfil, usuario.empresaId));

      }
    } catch (err) {
      dispatch(httpFailure());
      throw err;
    }
  };

  React.useEffect(() => {
    getDatos();

  }, []);

  //if (!sesion.usu || usuario.loading) return <div>Loading...</div>;
  return (
    <BrowserRouter>
      <LayoutPaciente url={url} path={path}>
        <Switch>
          <Route exact path={`/calendario/`}>
            <CalendarioGoogle />
          </Route>
          <Route
            exact
            path={`/admin/dashboard/mensual`}
            component={Dashboard}
          />
          <PublicRoute exact path={`/admin/usuario`} component={Usuario} />
          <Route
            exact
            path={`/admin/dashboard/bienvenida`}
            component={Inicio}
          />
          <PublicRoute
            exact
            path={`/laboratorio/deudas`}
            component={DeudasLabs}
          />
          <PublicRoute exact path={`/laboratorio/pagar`} component={PagoLab} />
          <PublicRoute
            exact
            path={`/laboratorio/ordenes`}
            component={OrdenesLab}
          />
          <PublicRoute
            exact
            path={`/centrorx/ordenes-atendidas`}
            component={OrdenesRxAtendidasRes}
          />
          <PublicRoute
            exact
            path={`/admin/mantenimiento/tratamientos`}
            component={Tratamientos}
          />
          <PublicRoute
            exact
            path={`/admin/mantenimiento/tarifarios`}
            component={Tarifarios}
          />
          <PublicRoute
            exact
            path={`/admin/mantenimiento/empresa`}
            component={Empresa}
          />

          <PublicRoute
            exact
            path={`/admin/mantenimiento/perfiles`}
            component={Perfiles}
          />
          <PublicRoute
            exact
            path={`/admin/mantenimiento/usuarios`}
            component={PerfilesUsuarios}
          />

          <PublicRoute
            exact
            path={`/admin/mantenimiento/tratamientos/config`}
            component={ConfigTratamiento}
          />
          <PublicRoute
            exact
            path={`/admin/mantenimiento/medicamentos/config`}
            component={ConfigMedicamentos}
          />
          <PublicRoute
            exact
            path={`/admin/mantenimiento/tratamientos/nuevo`}
            component={NuevoTratamiento}
          />
          <PublicRoute
            exact
            path={`/admin/mantenimiento/medicamentos/nuevo`}
            component={NuevoMedicamentos}
          />

          <PublicRoute
            exact
            path={`/admin/finanzas/gastos`}
            component={GastosFijos}
          />
          <PublicRoute
            exact
            path={`/admin/finanzas/metas`}
            component={Metas}
          />
          <PublicRoute
            exact
            path={`/admin/reportes/abonos`}
            component={AbonosRpt}
          />

          <PublicRoute
            exact
            path={`/admin/reportes/deudores`}
            component={DeudoresRpt}
          />

          <PublicRoute
            exact
            path={`/admin/mantenimiento/horario`}
            component={Horarios}
          />
          <PublicRoute
            exact
            path={`/admin/whatsapp/chats`}
            component={Chats}
          />


          <PublicRoute
            exact
            path={`/admin/mantenimiento/medicamentos`}
            component={Medicamentos}
          />


          <PublicRoute exact path={`/admin/dashboard`} component={Dashboard} />
          <PublicRoute exact path={`/admin/planes`} component={Planes} />

          <PrivateRoutePaciente exact path={`/`} component={Paciente} />
          <Route exact path={`/paciente/datos`} component={Paciente} />
          <PrivateRoutePaciente
            exact
            path={`/paciente/pagos`}
            component={Pagos}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/pagos/:prestacionId`}
            component={PagoPrestacion}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/ordenes/laboratorio`}
            component={OrdenesPaciente}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/ordenes/rayosx`}
            component={OrdenesRx}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/ordenes/ordenrx`}
            component={OrdenRx}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/prestacion/inicio`}
            component={IniciarPrestacion}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/prestaciones`}
            component={Prestaciones}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/presupuesto`}
            component={Presupuesto}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/odontograma`}
            component={Odontograma}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/odontogramainicial`}
            component={OdontogramaInicial}
          />

          <PrivateRoutePaciente
            exact
            path={`/paciente/historia`}
            component={HistoriaClinica}
          />

          <PrivateRoutePaciente
            exact
            path={`/paciente/abonos`}
            component={AbonoCuenta}
          />

          <PrivateRoutePaciente
            exact
            path={`/paciente/extraordinarias/xprestaciones`}
            component={XPrestaciones}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/extraordinarias/devolucion`}
            component={Devolucion}
          />


          <PrivateRoutePaciente
            exact
            path={`/paciente/recetas`}
            component={Recetas}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/receta`}
            component={Receta}
          />
          <PrivateRoutePaciente
            exact
            path={`/paciente/reportes/:tipo`}
            component={ReportesPaciente}
          />

          <PrivateRoutePaciente
            exact
            path={`/paciente/generar/consentimientos`}
            component={Consentimientos}
          />

          <Route component={NotFound}></Route>
        </Switch>
        <LogoWaterMark />
      </LayoutPaciente>
    </BrowserRouter>
  );
};

function App() {
  const dispatch = useDispatch();

  dispatch(setFechaSesion(getFecha()));

  return (
    <AuthProvider>
      <BrowserRouter>
        <GlobalStyle />
        <Layout>
          <Switch>
            <Route exact path="/reset" component={ResetPassword} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/demo" component={LoginEmail} />

            <PrivateRoute path="/" component={MainApp} />
            <PrivateRoute path="*" component={NotFound} />
          </Switch>
        </Layout>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
