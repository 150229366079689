import { keyframes } from "styled-components";
import { db } from "../Config";
const fechaACadena = (fecha) => {
  let f;
  if (fecha === null || fecha === undefined || fecha === "" || isNaN(fecha))
    f = new Date();
  else f = fecha;
  const a = f.getFullYear();
  const m = (f.getMonth() + 1).toString().padStart(2, "0");
  const d = f.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
};
export const fetchPacientes = (empresaId) => {
  return new Promise((resolve, reject) => {
    const pacientesCollection = db.collection('pacientes');
    const unsubscribe = pacientesCollection
      .where('empresaId', '==', empresaId)
      .onSnapshot((querySnapshot) => {
        let pacientes = [];

        querySnapshot.forEach((doc) => {
          pacientes.push({
            id: doc.id,
            nombres: doc.data().nombres,
            apellidos: doc.data().apellidos,
            sexo: doc.data().sexo,
            estado: doc.data().estado,
            fecNac: doc.data()?.fecNac ? fechaACadena(doc.data().fecNac.toDate()) : null,
            telefono: doc.data().telefono,
            patologias: doc.data().patologias,
            tipDoc: doc.data().tipDoc,
            direccion: doc.data().direccion,
            numDoc: doc.data().numDoc,
            correo: doc.data().correo,
            empresaId: doc.data().empresaId,
            token: doc.data().token ? doc.data().token : ""
          });

        });
        resolve(pacientes)
        // Dispatch only when there are changes
      }, (error) => {
        reject(error)
        // Handle errors
      });
  })


};

export const getPaciente = (pacienteId) => {
  return new Promise((resolve, reject) => {
    db.collection("pacientes")
      .doc(pacienteId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          resolve(
            {
              id: doc.id,
              nombres: doc.data().nombres,
              apellidos: doc.data().apellidos,
              estado: doc.data().estado,
              fecNac: fechaACadena(doc.data().fecNac.toDate()),
              telefono: doc.data().telefono,
              patologias: doc.data().patologias,
              tipDoc: doc.data().tipDoc,
              direccion: doc.data().direccion,
              numDoc: doc.data().numDoc,
              correo: doc.data().correo,
              empresaId: doc.data().empresaId,
              token: doc.data().token
            })
        }
      })
      .catch((err) => {
        reject(err);
      })
  })
};


export const savePaciente = (paciente) => {
  return new Promise((resolve, reject) => {
    const pacienteRef = db.collection("pacientes").doc();
    paciente.id = pacienteRef.id;
    pacienteRef
      .set(paciente)
      .then(() => {
        resolve({ id: pacienteRef.id, ...paciente });
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const getPacientesByIds = async (pacientesId) => {
  let pacientes = [];
  for (let i = 0; i < pacientesId.length; i++) {
    let paciente = await getPaciente(pacientesId[i]);
    if (paciente)
      pacientes.push(paciente);
  }
  return pacientes;
};


export const getPacienteByNumdocAndEmpresaId = (numDoc, empresaId) => {
  return new Promise((resolve, reject) => {
    const pacientesCollection = db.collection('pacientes');
    pacientesCollection
      .where('empresaId', '==', empresaId)
      .where('numDoc', '==', numDoc)
      .get()
      .then((querySnapshot) => {
        let paciente;

        querySnapshot.forEach((doc) => {
          paciente = {
            id: doc.id,
            nombres: doc.data().nombres,
            apellidos: doc.data().apellidos,
            estado: doc.data().estado,
            fecNac: fechaACadena(doc.data().fecNac.toDate()),
            telefono: doc.data().telefono,
            patologias: doc.data().patologias,
            tipDoc: doc.data().tipDoc,
            direccion: doc.data().direccion,
            numDoc: doc.data().numDoc,
            correo: doc.data().correo,
            empresaId: doc.data().empresaId,
            token: doc.data().token
          };

        });
        resolve(paciente)
        // Dispatch only when there are changes
      }).catch((err) => {
        reject(err);
      });
  })


};




