import { db } from "../../services/firebase/Config";

import { TRAE_RECETAS_PETICION, SET_RECETAS } from "./recetasTypes";

import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";

import { ItemReceta, Receta } from "../../Entidades/Recetas";

export const traeRecetasPeticion = () => {
  return {
    type: TRAE_RECETAS_PETICION,
  };
};

const setRecetas = (recetas) => {
  return {
    type: SET_RECETAS,
    payload: recetas,
  };
};


export const traerRecetas = (pacienteId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let recetas = [];
    db.collection("recetas")
      .where("pacienteId", "==", pacienteId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          recetas.push({
            id: doc.id,
            pacienteId: doc.data().pacienteId,
            fecha: doc.data().fecha.toDate(),
            especialistaNombres: doc.data().especialistaNombres,
            especialistaCop: doc.data().especialistaCop,
            pacienteNombres: doc.data().pacienteNombres,
            indicacionesAdicionales: doc.data().indicacionesAdicionales,
            items: doc.data().items,
          });
        });
        
        // Ordenar recetas por fecha (de menor a mayor)
        recetas.sort((a, b) => b.fecha - a.fecha);
        
        dispatch(httpSuccess());
        dispatch(setRecetas(recetas));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};