import React, { useState, useEffect } from "react";  
import {
  FormField,
  AutocompleteStyle,
  AutocompleteResultStyle,
  Item,
  ScrollDiv,
} from "./styles";

export function AutocompletePaciente({
  useSearchItems,
  onSelected,
  toString,
  inputValueInitial = '',
}) {
  const { query, setQuery, filteredItems, clearFilteredItems } = useSearchItems();
  const [inputValue, setInputValue] = useState(inputValueInitial || query);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setInputValue(inputValueInitial);
  }, [inputValueInitial]);

  function handleChange(e) {
    setInputValue(e.target.value);
    setQuery(e.target.value);
    setSelectedItem(null); // Se limpia la selección al escribir
  }

  function handleClickItem(item) {
    item.onSelected = true;
    const selectedValue = toString(item);
    setInputValue(selectedValue);
    setSelectedItem(item);
    clearFilteredItems();
    onSelected(item);
  }

  function handleBlur() {
    if (!selectedItem && inputValue.trim() !== "") {
      onSelected({ nombres: inputValue, onSelected: false });
    }
  }

  return (
    <AutocompleteStyle>
      <FormField
        type="text"
        placeholder="Buscar Paciente"
        onChange={handleChange}
        onBlur={handleBlur} // Se ejecuta al perder el foco
        value={inputValue}
      />
      {filteredItems.length > 0 && (
        <ScrollDiv>
          <AutocompleteResultStyle>
            {filteredItems.map((item) => (
              <Item key={item.id} onClick={() => handleClickItem(item)}>
                {toString(item)}
              </Item>
            ))}
          </AutocompleteResultStyle>
        </ScrollDiv>
      )}
    </AutocompleteStyle>
  );
}
