import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import SettingsIcon from "@material-ui/icons/Settings";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Spinner from "react-bootstrap/Spinner";

import {
  fetchMedicamentosByEmpresa,
  fetchMedicamentoById,
  copiarMedicamentosGenericos,
  getMedicamentosGenericos,
  tieneMedicamentosConfigurados,
  deleteMedicamento,
} from "../../services/firebase/Medicamentos/index";

import {
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  StyledInput,
  MainContainer,
  modalStyles,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledLabel,
  ContainerButton,
  ButtonAbonar,
  ContainerTitles,
  StyledInputTabla,
} from "./styles";

// Hook de búsqueda de medicamentos
export const useBuscarMedicamentos = (medicamentos) => {
  const [query, setQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState(medicamentos || []);

  useEffect(() => {
    if (!medicamentos) {
      setFilteredItems([]);
      return;
    }

    if (query.length > 0) {
      const result = medicamentos.filter((item) =>
        `${item.medicina || item.medicamento || ""} ${item.concentracion || ""} ${item.formato || ""}`
          .toLocaleLowerCase()
          .includes(query.toLocaleLowerCase())
      );
      setFilteredItems(result);
    } else {
      setFilteredItems(medicamentos);
    }
  }, [medicamentos, query]);

  const clearFilteredItems = () => {
    setFilteredItems([]);
  };

  return { query, setQuery, filteredItems, clearFilteredItems };
};

// Componente para mostrar una fila de medicamento
const FilaMedicamento = ({ item, onClick, onUpdate, onDeleteClick }) => {
  const { addToast } = useToasts();
  const [cambio, setCambio] = useState(false);

  const handleOnBlur = useCallback(() => {
    if (!cambio) return;

    try {
      onUpdate(item);
      setCambio(false);
      addToast("Medicamento actualizado", { appearance: "success" });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  }, [cambio, item, onUpdate, addToast]);

  const handleChange = useCallback((e, item) => {
    try {
      const { name, value, checked } = e.target;

      if (name === "cantidad") {
        setCambio(true);
        const newItem = { ...item, cantidad: Number(value) };
        onUpdate(newItem, false);
      }

      if (name === "estado") {
        const newItem = { ...item, estado: checked };
        onUpdate(newItem);
        addToast("Medicamento actualizado", { appearance: "success" });
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  }, [item, onUpdate, addToast]);

  return (
    <tr>
      <TablaBodyTexto data-label="medicina">{item.medicina || item.medicamento || ""}</TablaBodyTexto>
      <TablaBodyTexto data-label="concentracion">
        {item.concentracion || ""}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="formato">{item.formato || ""}</TablaBodyTexto>
      <TablaBodyTexto data-label="cantidad">
        <StyledInputTabla
          name="cantidad"
          value={item.cantidad || 0}
          type="text"
          onBlur={handleOnBlur}
          onChange={(e) => handleChange(e, item)}
        />
      </TablaBodyTexto>
      <TablaBodyTexto data-label="dosisYFrecuencia">
        {item.dosisYFrecuencia || ""}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="duracion">{item.duracion || ""}</TablaBodyTexto>
      <TablaBodyTexto data-label="Config">
        <IconButton
          style={{ padding: "5px", marginLeft: "8px" }}
          onClick={() => onDeleteClick(item)}
        >
          <Tooltip TransitionComponent={Zoom} placement="top" title="Eliminar">
            <DeleteIcon style={{ color: "red" }} />
          </Tooltip>
        </IconButton>

        <IconButton
          style={{ padding: "5px" }}
          onClick={() => onClick(item)}
        >
          <Tooltip TransitionComponent={Zoom} placement="top" title="Config">
            <SettingsIcon style={{ color: "blue" }} />
          </Tooltip>
        </IconButton>
      </TablaBodyTexto>
    </tr>
  );
};

export const Medicamentos = () => {
  const sesion = useSelector((state) => state.sesion);
  const empresa = useSelector((state) => state.empresa);
  const history = useHistory();
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const [medicamentos, setMedicamentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tieneMedicamentosEmpresa, setTieneMedicamentosEmpresa] = useState(true);
  const [medicamentosGenericos, setMedicamentosGenericos] = useState([]);
const [nuevosIds, setNuevosIds] = useState([]);

  const { query, setQuery, filteredItems } = useBuscarMedicamentos(medicamentos);

  // Función para cargar medicamentos
  const loadMedicamentos = useCallback(async () => {
    if (!empresa || !empresa.id) return;

    setLoading(true);
    try {
      const medicamentosData = await fetchMedicamentosByEmpresa(empresa.id);
      setMedicamentos(medicamentosData);
      setError(null);

    } catch (err) {
      console.error("Error al cargar medicamentos:", err);
      setError(err.message);
      addToast(`Error al cargar medicamentos: ${err.message}`, {
        appearance: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [empresa, addToast]);


  // Gestión del modal de eliminación
  const handleOpenDeleteModal = useCallback((item) => {
    setSelectedItem(item);
    setOpenDeleteModal(true);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setOpenDeleteModal(false);
    setSelectedItem(null);
  }, [])
useEffect(() => {
  const verificarMedicamentos = async () => {
    if (empresa?.id) {
      try {
        const tieneMeds = await tieneMedicamentosConfigurados(empresa.id);
        setTieneMedicamentosEmpresa(tieneMeds);

        // Si no tiene medicamentos configurados, cargar los genéricos y crear copias
        if (!tieneMeds) {
          const genericos = await getMedicamentosGenericos();
          setMedicamentosGenericos(genericos);

          // Crear copias de los medicamentos genéricos para esta empresa
          const idsNuevos = await copiarMedicamentosGenericos(empresa.id);
          setNuevosIds(idsNuevos);
          
          // Recargar la lista de medicamentos después de copiar
          loadMedicamentos();
        }
      } catch (error) {
        console.error("Error al verificar medicamentos:", error);
        setError("Error al configurar medicamentos: " + error.message);
      }
    }
  };

  verificarMedicamentos();
}, [empresa?.id, loadMedicamentos]);
const handleConfirmDelete = useCallback(async () => {
    if (!selectedItem) return;

    try {
      await dispatch(deleteMedicamento(selectedItem.id, empresa.id));
      addToast("Medicamento eliminado correctamente", {
        appearance: "success",
      });
      // Recargar lista de medicamentos
      loadMedicamentos();
    } catch (error) {
      addToast(`Error al eliminar: ${error.message}`, {
        appearance: "error",
      });
    }
    handleCloseDeleteModal();
  }, [selectedItem, dispatch, addToast, loadMedicamentos, handleCloseDeleteModal]);

  // Navegar a la página de configuración con datos transformados
  const handleNavigateToConfig = useCallback(async (item) => {
    try {
      // Obtener los datos completos del medicamento
      let medicamentoCompleto = await fetchMedicamentoById(empresa.id, item.id);

      // Asegurarse de que el medicamento tiene todas las propiedades necesarias
      // para evitar errores al intentar acceder a propiedades indefinidas
      medicamentoCompleto = {
        id: medicamentoCompleto.id || item.id,
        medicina: medicamentoCompleto.medicina || medicamentoCompleto.medicamento || "",
        medicamento: medicamentoCompleto.medicamento || medicamentoCompleto.medicina || "",
        concentracion: medicamentoCompleto.concentracion || "",
        formato: medicamentoCompleto.formato || "",
        cantidad: medicamentoCompleto.cantidad || 0,
        dosisYFrecuencia: medicamentoCompleto.dosisYFrecuencia || "",
        duracion: medicamentoCompleto.duracion || "",
        descripcion: medicamentoCompleto.descripcion || "",
        instrucciones: medicamentoCompleto.instrucciones || "",
        efectosSecundarios: medicamentoCompleto.efectosSecundarios || "",
        contraindicaciones: medicamentoCompleto.contraindicaciones || "",
        estado: medicamentoCompleto.estado !== undefined ? medicamentoCompleto.estado : true,
      };

      // Navegar a la página de configuración con datos completos y validados
      history.push({
        pathname: "/admin/mantenimiento/medicamentos/config",
        state: { data: medicamentoCompleto },
      });
    } catch (error) {
      console.error("Error al cargar medicamento para config:", error);
      addToast(`Error al cargar medicamento: ${error.message}`, {
        appearance: "error",
      });
    }
  }, [empresa, history, addToast]);

  // Cargar medicamentos al montar el componente
  useEffect(() => {
    if (empresa && empresa.id) {
      loadMedicamentos();
    }
  }, [empresa, loadMedicamentos]);

  // Renderizado condicional
  if (sesion.loading || sesion.loading === undefined || loading) {
    return (
      <MainContainer>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </MainContainer>
    );
  }

  if (sesion.error) return <div>error + {sesion.error}</div>;
  if (error) return <div>Error al cargar medicamentos: {error}</div>;

  return (
    <MainContainer>
      {/* Modal de confirmación de eliminación */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        style={modalStyles.modal}
      >
        <div style={modalStyles.paper}>
          <h3 style={{ marginTop: 0 }}>Confirmar eliminación</h3>
          <p>
            ¿Está seguro de borrar el medicamento {selectedItem?.medicina || selectedItem?.medicamento || ""}?
          </p>
          <div style={modalStyles.actionButtons}>
            <Button
              variant="outlined"
              onClick={handleCloseDeleteModal}
              style={{ color: "#666", borderColor: "#666" }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmDelete}
              style={{
                backgroundColor: "#ff4444",
                color: "white",
              }}
            >
              Eliminar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Título y controles */}
      <ContainerTitle>
        <ContainerTitleText>Medicamento</ContainerTitleText>
      </ContainerTitle>
      <ContainerButton>
        <ContainerTitles>
          <StyledLabel>Buscar Medicamento</StyledLabel>
          <StyledInput
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </ContainerTitles>
        <ContainerTitle>
          <ButtonAbonar
            onClick={() => {
              history.push("medicamentos/nuevo");
            }}
          >
            Nuevo
          </ButtonAbonar>
        </ContainerTitle>
      </ContainerButton>

      {/* Tabla de medicamentos */}
      <ContainerBody>
        <Tabla>
          <TablaHeader>
            <tr>
              <TablaHeaderTexto scope="col">Medicina</TablaHeaderTexto>
              <TablaHeaderTexto scope="col">Concentración</TablaHeaderTexto>
              <TablaHeaderTexto scope="col">Presentación</TablaHeaderTexto>
              <TablaHeaderTexto scope="col">Cantidad</TablaHeaderTexto>
              <TablaHeaderTexto scope="col">Dosis Frecuencia</TablaHeaderTexto>
              <TablaHeaderTexto scope="col">Duración</TablaHeaderTexto>
              <TablaHeaderTexto scope="col">Config</TablaHeaderTexto>
            </tr>
          </TablaHeader>
          <TablaBody>
            {filteredItems.map((item) => (
              <FilaMedicamento
                key={item.id}
                item={item}
                onClick={handleNavigateToConfig}
                onDeleteClick={handleOpenDeleteModal}
              />
            ))}
          </TablaBody>
        </Tabla>
      </ContainerBody>
    </MainContainer>
  );
}
