import React, { useEffect, useState } from 'react';
import styles from './perfilesUsuario.module.css';
import {
    ContenedorTarjeta,
    SectionTarjeta,
    TableWrapper,
} from "./styles";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import axios from 'axios';
import { obtenerDatosAccesos, obtenerDatosEmpresa, obtenerDatosUsuarios, deleteUsuario, updateUsuario, createUsuario } from '../../services/firebase/perfilesUsuarios';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { usiarioUploadImage } from "../../services/firebase/usuarios/index";

import ConfirmacionSiNoModal from '../../components/ConfirmacionSiNoModal';
import EditUserModal from './Modales/EditarModal';
import { formatMs } from '@material-ui/core';
import Button from 'react-bootstrap/Button';
import { formatDate } from '@fullcalendar/core';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Card from 'react-bootstrap/Card';
import { useDispatch } from "react-redux";


export const PerfilesUsuarios = () => {
    const [isLoading, setIsLoading] = useState(false);
    const usuario = useLocalStorage('usuario');
    const empresa = useSelector((state) => state.empresa)
    //const usuario = useSelector((state) => state.usuario);
    const [usuariosFirebase, setUsuariosFirebase] = useState([]);
    const membresia = empresa.membresia
    const [showModalEliminar, setShowModalEliminar] = useState(false)
    const [showModalEditar, setShowModalEditar] = useState(false)
    const dispatch = useDispatch();
    
    const perfileOdontologo = ["ODONTOLOGO", "ODONTOLOGO_ADM"]

    const [formData, setFormData] = useState({
        id: '',
        nombres: '',
        email: '',
        especialidad: '',
        cop: '',
        uid: '',
        perfil: '',
        empresaId: '',
    });
    
    const onFileChange = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                console.log("Archivo seleccionado:", file.name);
                // Mostrar mensaje de carga
                addToast('Subiendo imagen...', { appearance: 'info', autoDismiss: true });
                
                const fileUrl = await usiarioUploadImage(file);
                console.log("URL de imagen obtenida:", fileUrl);
                
                if (fileUrl) {
                    // Actualizar el estado con la URL de la imagen
                    setFormData(prevData => ({
                        ...prevData,
                        firma: fileUrl
                    }));
                    
                    addToast('Imagen cargada correctamente', { appearance: 'success', autoDismiss: true });
                } else {
                    throw new Error('No se pudo obtener la URL de la imagen');
                }
            }
        } catch (error) {
            console.error('Error al cargar la imagen:', error);
            addToast('Error al cargar la imagen: ' + error.message, { appearance: 'error', autoDismiss: true });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    }
    
    const handleClearForm = () => {
        setFormData({
            id: '',
            nombres: '',
            email: '',
            especialidad: '',
            cop: '',
            uid: '',
            firma: '',
            perfil: '',
            empresaId: '',
        });
    };


    function validarEmail(email) {
        // Expresión regular para validar un correo electrónico con dominio gmail.com
        const regex = /^[a-zA-Z0-9._-]+@gmail\.com$/;
        return regex.test(email);
    }


    const [showRenewModal, setShowRenewModal] = useState(false);

    const { addToast } = useToasts();


    const endpoint = `${process.env.REACT_APP_API_URL}`;

    // const endpoint = `https://us-central1-sysdent-devs.cloudfunctions.net`;
    // const endpoint = `http://127.0.0.1:5001/sysdent-devs/us-central1`;

    useEffect(() => {
        const usuariosDatos = async () => {
            try {
                if (empresa.id) {
                    const usuariosData = await obtenerDatosUsuarios(empresa.id);
                    setUsuariosFirebase(usuariosData);
                }
            } catch (error) {
                console.error('Error al obtener datos de Firebase:', error);
            }
        };
        setIsLoading(true)
        usuariosDatos();
        setIsLoading(false)
    }, [empresa.id]);


    const validaForm = () => {
        if (!formData.perfil) {
            throw new Error('Seleccione un perfil ')
        }
        if (!validarEmail(formData.email)) {
            throw new Error('El usuario debe ser @gmail.com');
        }
        if (perfileOdontologo.includes(formData.perfil)) {
            if (!formData.especialidad) {
                throw new Error('Seleccione especialidad');

            }
            if (!formData.cop) {
                throw new Error('Ingrese COP');
            }
        }
    }

    const handleSaveUser = async () => {
        try {
            let mensaje = ''
            let usrDb = { ...formData, empresaId: empresa.id }
            
            console.log("Datos a guardar:", usrDb);

            validaForm(usrDb)

            if (usrDb.id) {
                await updateUsuario(usrDb)
                const usuariosData = usuariosFirebase.map((user) => {
                    if (user.id === usrDb.id) {
                        return { ...user, ...usrDb }
                    } else {
                        return user
                    }
                })

                setUsuariosFirebase(usuariosData)

                mensaje = 'Usuario actualizado correctamente'
            } else {
                const newUser = await createUsuario(usrDb);
                const usuariosData = [...usuariosFirebase, newUser]
                setUsuariosFirebase(usuariosData)
                mensaje = 'Usuario creado correctamente'
                handleClearForm()
            }

            handleCloseModal()
            addToast(mensaje, { appearance: 'success', autoDismiss: true });
        } catch (error) {
            console.error("Error al guardar usuario:", error);
            addToast(error.message, { appearance: 'error', autoDismiss: true });
        }
    };

    const handleEditUser = (usuario) => {
        console.log('selected user', usuario)

        setFormData({ ...formData, ...usuario })

        setShowModalEditar(true)
    };

    const handleDeleteUser = (usuario) => {
        console.log('selected user', usuario)

        setFormData({ ...formData, ...usuario })

        setShowModalEliminar(true)
    };

    const handleNewUser = () => {
        handleClearForm()
        setShowModalEditar(true)
    };
    
    const handleCloseModal = (e) => {
        setShowModalEliminar(false)
        setShowModalEditar(false)
    };

    const infoMembresia = () =>
    (<Popover id="popover-basic">
        <Popover.Header as="h3">Membresia</Popover.Header>
        <Popover.Body>
            <TableWrapper>
                <Table responsive size="sm">
                    <thead>
                        <tr>
                            <td>Descripcion</td>
                            <td>Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Usuarios:</td>
                            <td>{membresia.usuarios}</td>
                        </tr>
                        <tr>
                            <td>Perfiles:</td>
                            <td>{membresia?.perfiles?.join()}</td>
                        </tr>

                        <tr>
                            <td>Pacientes:</td>
                            <td>{membresia.pacientes}</td>
                        </tr>
                        <tr>
                            <td>Prestaciones:</td>
                            <td>{membresia.prestaciones}</td>
                        </tr>
                    </tbody>
                </Table>
            </TableWrapper>
        </Popover.Body>
    </Popover>
    )

    const handleSaveDeleteUser = async () => {
        try {
            await deleteUsuario(formData);
            console.log('ID enviado correctamente', formData);
            const updatedUsuarios = usuariosFirebase.filter(user => user.id !== formData.id);
            setUsuariosFirebase(updatedUsuarios);
            handleCloseModal()
            addToast('Usuario eliminado correctamente', { appearance: 'success', autoDismiss: true });
        } catch (error) {
            console.error('Error al eliminar usuario en Firebase:', error);
        }
    };

    if (isLoading || membresia == null)
        return <div>Cargando....</div>;

    return (
        <>
            <div className={styles.mainContainer}>
                <div className="perfiles-usuarios-container">

                    <OverlayTrigger 
                        trigger={['hover', 'focus']} 
                        placement="bottom" 
                        overlay={infoMembresia()}
                    >
                        <h3 className={styles.titleHeader}>Membresia: {membresia.plan}</h3>
                    </OverlayTrigger>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="primary" 
                        onClick={() => handleNewUser()}>+ Nuevo Usuario</Button>
                    </div>

                    <div className="workers-list">
                        <div className={styles.userCards}>
                            {usuariosFirebase.map((usr) => (
                                <div key={usr.id} className={`${styles.userCard} ${formData && formData.id === usr.id ? 'selected' : ''}`}>
                                    {usr.firma ? (
                                        <img 
                                            src={usr.firma} 
                                            alt={usr.nombres} 
                                            width="60" 
                                            height="60" 
                                            style={{ borderRadius: '50%', objectFit: 'cover' }} 
                                        />
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24">
                                            <path fill="#0284c7" d="M12 12q-1.65 0-2.825-1.175T8 8q0-1.65 1.175-2.825T12 4q1.65 0 2.825 1.175T16 8q0 1.65-1.175 2.825T12 12m-8 8v-2.8q0-.85.438-1.562T5.6 14.55q1.55-.775 3.15-1.162T12 13q1.65 0 3.25.388t3.15 1.162q.725.375 1.163 1.088T20 17.2V20z" />
                                        </svg>
                                    )}
                                    <div className={styles.userInfo}>
                                        <span>{usr.nombres}</span>
                                        <br />
                                        <span>{usr.perfil}</span>
                                    </div>

                                    <IconButton
                                        aria-label=""
                                        onClick={() => handleEditUser(usr)}
                                    >
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            placement="top"
                                            title="Editar"
                                        >
                                            <EditIcon style={{ color: "green" }} />
                                        </Tooltip>
                                    </IconButton>

                                    {usr.id !== usuario.id && (
                                        <IconButton
                                            aria-label=""
                                            onClick={() => handleDeleteUser(usr)}
                                        >
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                placement="top"
                                                title="Eliminar"
                                            >
                                                <DeleteIcon style={{ color: "red" }} />
                                            </Tooltip>
                                        </IconButton>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <ConfirmacionSiNoModal
                    mensaje="¿Está seguro que desea eliminar este usuario?"
                    isOpen={showModalEliminar}
                    handleCloseModal={handleCloseModal}
                    onClickYes={handleSaveDeleteUser} />
            </div>

            <EditUserModal
                formData={formData}
                isOpen={showModalEditar}
                handleCloseModal={handleCloseModal}
                handleSaveUser={handleSaveUser}
                handleInputChange={handleInputChange}
                onFileChange={onFileChange}
            />

            {/* Modal de Renovación */}
            {showRenewModal && (
                <div className="modal-renew">
                    <div className="modal-content-renew">
                        <span>¡Ha alcanzado su límite máximo de usuarios. Contáctenos para Escalar a un plan superior.!</span>
                        <button onClick={() => setShowRenewModal(false)}>Cerrar</button>
                    </div>
                </div>
            )}
        </>
    );
};