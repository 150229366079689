import React, { useState, useMemo, useEffect } from "react";
import { fetchPacientes } from "../services/firebase/pacientes";
import { useSelector } from "react-redux";

export const useBuscarPacientes = () => {
  const [query, setQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pacientes, setPacientes] = useState([]);
  const empresa = useSelector((state) => state.empresa);

  // Cargar pacientes solo una vez
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchPacientes(empresa.id);
        setPacientes(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (empresa?.id) {
      fetchData();
    }
  }, [empresa?.id]); // Solo se ejecuta cuando cambia la empresa

  // Filtrar datos localmente en lugar de llamar a la API en cada cambio
  const filteredItemsMemoized = useMemo(() => {
    if (!query) return [];

    return pacientes.filter((item) =>
      `${item.nombres} ${item.apellidos} ${item.numDoc}`
        .toLowerCase()
        .includes(query.toLowerCase())
    );
  }, [query, pacientes]); // Se recalcula solo si cambia query o pacientes

  useEffect(() => {
    setFilteredItems(filteredItemsMemoized);
  }, [filteredItemsMemoized]);

  const clearFilteredItems = () => {
    setFilteredItems([]);
  };

  return { query, setQuery, filteredItems, clearFilteredItems, loading };
};
