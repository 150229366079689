import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  CalendarContainer,
  SidePanelContainer,
  StyledSelect,
  AvailabilityHeader,
  ClockIcon,
  AvailabilityRow,
  UpdateButton,
  TimeInput,
  IconButton,
  CrossIcon,
  PlusIcon,
  SyncIcon,
  ScheduleSection,
  TimeConfigContainer,
  ConfigSection,
  ConfigHeader,
  SaveButton,
  ButtonText,
  LoadingSpinner,
  SaveIconAlt,
  HeaderGroup,
  NumberInput,
  ConfigTitle,
  ThreeColumnLayout,
  Column,
  Column1,
} from "./styles";
import { saveHorarioEmpresa, getHorarioEspecialistaById } from "../../services/firebase/horariosEspecialistas";

export const Horarios = () => {
  const initialDays = [
    'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'
  ].map(label => {
    const isWeekday = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'].includes(label);
    const isSaturday = label === 'Sábado';

    return {
      label,
      available: isWeekday || isSaturday,
      timeRanges: isWeekday
        ? [{ start: '09:00', end: '13:00' }, { start: '15:00', end: '19:00' }]
        : isSaturday
          ? [{ start: '09:00', end: '13:00' }]
          : []
    };
  });

  const [config, setConfig] = useState({
    duracionCitaMin: "15", // ✅ String


    plazos: {
      enabled: true,
      maxDays: 60,
      minHoursEnabled: true,
      minHours: 4
    },
    citasReservadas: {
      duracionPeriodo: {
        enabled: true,
        tiempo: 60
      },
      maxReservas: {
        enabled: true,
        cantidad: 4
      }
    }
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Inicializar en false

  const [days, setDays] = useState(initialDays);
  const [timeErrors, setTimeErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const { addToast } = useToasts();
  const empresa = useSelector((state) => state.empresa);
  const usuario = useSelector((state) => state.usuario);

  const updateConfig = (path, value) => {
    setConfig(prev => {
      const newConfig = { ...prev };
      const keys = path.split('.');
      let current = newConfig;

      for (let i = 0; i < keys.length - 1; i++) {
        current[keys[i]] = { ...current[keys[i]] };
        current = current[keys[i]];
      }

      const lastKey = keys[keys.length - 1];

      // Si es booleano, maneja normalmente
      if (typeof value === 'boolean') {
        current[lastKey] = value;
      }
      // Para valores numéricos
      else {
        // Permitir que el valor esté temporalmente vacío mientras se edita
        current[lastKey] = value;
      }

      return newConfig;
    });
  };
  const validateTimeRange = (start, end, ranges, currentIndex) => {
    const timeRegex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
    const toMinutes = time => {
      const [h, m] = time.split(':').map(Number);
      return h * 60 + m;
    };

    const startMin = toMinutes(start);
    const endMin = toMinutes(end);
    const errors = [];

    if (!timeRegex.test(start) || !timeRegex.test(end)) {
      errors.push("Formato inválido (HH:MM)");
    }
    if (startMin >= endMin) {
      errors.push("La hora inicial debe ser anterior a la final");
    }

    ranges.forEach((range, index) => {
      if (index !== currentIndex) {
        const otherStart = toMinutes(range.start);
        const otherEnd = toMinutes(range.end);
        if ((startMin < otherEnd) && (endMin > otherStart)) {
          errors.push("Superposición con otro horario");
        }
      }
    });

    return errors;
  };

  useEffect(() => {
    const fetchHorario = async () => {
      try {
        setLoading(true);
        if (!empresa?.id?.trim()) throw new Error("ID de empresa no válido");

        const data = await getHorarioEspecialistaById(empresa.id);

        setConfig({
          duracionCitaMin: data?.duracionCitaMin || "15", // ✅ Nombre correcto
          plazos: {
            enabled: data?.franjaProgramacion?.anticipacionMaxima?.habilitado ?? false,
            maxDays: data?.franjaProgramacion?.anticipacionMaxima?.dias || 60,
            minHoursEnabled: data?.franjaProgramacion?.anticipacionMinima?.habilitado ?? false,
            minHours: data?.franjaProgramacion?.anticipacionMinima?.horas || 4
          },
          citasReservadas: {
            duracionPeriodo: {
              enabled: data?.ajustesCitaReservada?.duracionPeriodoEntreCitas?.habilitado ?? false,
              tiempo: data?.ajustesCitaReservada?.duracionPeriodoEntreCitas?.tiempo || 60
            },
            maxReservas: {
              enabled: data?.ajustesCitaReservada?.maximoReservasPorDia?.habilitado ?? false,
              cantidad: data?.ajustesCitaReservada?.maximoReservasPorDia?.cantidad || 4
            }
          }
        });

        setDays(data?.disponibilidad?.map(day => ({
          label: day.dia,
          available: day.disponible,
          timeRanges: day.horarios?.map(range => ({
            start: range.inicio?.slice(0, 5) || "09:00",
            end: range.fin?.slice(0, 5) || "13:00"
          })) || []
        })) || initialDays);

      } catch (err) {
        setError(err.message);
        setConfig({
          duracionCitaMin: '15',
          plazos: {
            enabled: true,
            maxDays: 60,
            minHoursEnabled: true,
            minHours: 4
          },
          citasReservadas: {
            duracionPeriodo: {
              enabled: true,
              tiempo: 60
            },
            maxReservas: {
              enabled: true,
              cantidad: 4
            }
          }
        });
        setDays(initialDays);
      } finally {
        setLoading(false);
        setIsEditing(true);
      }
    };

    fetchHorario();
  }, [empresa?.id]);

  useEffect(() => {
    const newErrors = {};
    days.forEach(day => {
      if (day.available && day.timeRanges.length > 0) {
        day.timeRanges.forEach((range, index) => {
          const errors = validateTimeRange(range.start, range.end, day.timeRanges, index);
          if (errors.length > 0) {
            newErrors[`${day.label}-${index}`] = errors;
          }
        });
      }
    });
    setTimeErrors(newErrors);
  }, [days]);

  const updateTimeRange = (dayLabel, index, type, value) => {
    setDays(prevDays => prevDays.map(day =>
      day.label === dayLabel
        ? {
          ...day,
          timeRanges: day.timeRanges.map((range, i) =>
            i === index ? { ...range, [type]: value } : range
          )
        }
        : day
    ));
  };

  const addTimeRange = (dayLabel) => {
    setDays(prevDays => prevDays.map(day => {
      if (day.label === dayLabel) {
        const newRange = day.timeRanges.length === 0
          ? { start: '09:00', end: '13:00' }
          : { start: '15:00', end: '19:00' };

        return {
          ...day,
          available: true,
          timeRanges: [...day.timeRanges, newRange]
        };
      }
      return day;
    }));
  };

  const deleteTimeRange = (dayLabel, index) => {
    setDays(prevDays => prevDays.map(day =>
      day.label === dayLabel
        ? { ...day, timeRanges: day.timeRanges.filter((_, i) => i !== index) }
        : day
    ));
  };

  const handleSave = async () => {
    if (Object.keys(timeErrors).length > 0) {
      const errorMessage = "¡Existen errores en los horarios! Corrígelos antes de guardar.";
      setError(errorMessage);
      addToast(errorMessage, {
        appearance: "error",
        autoDismiss: true
      });
      return;
    }
    setIsSaving(true);
    try {
      const formData = {
        duracionCitaMin: parseInt(config.duracionCitaMin, 10),

        disponibilidad: days.map(day => ({
          dia: day.label,
          disponible: day.available,
          horarios: day.timeRanges.map(range => ({
            inicio: range.start,
            fin: range.end
          }))
        })),
        empresaId: empresa.id,
        especialistaId: usuario.data.id,
        franjaProgramacion: {
          anticipacionMaxima: {
            dias: config.plazos.maxDays,
            habilitado: config.plazos.enabled
          },
          anticipacionMinima: {
            habilitado: config.plazos.minHoursEnabled,
            horas: config.plazos.minHours
          },
          habilitado: true
        },
        ajustesCitaReservada: {
          duracionPeriodoEntreCitas: {
            tiempo: config.citasReservadas.duracionPeriodo.tiempo,
            unidad: "minutos",
            habilitado: config.citasReservadas.duracionPeriodo.enabled,
          },
          maximoReservasPorDia: {
            habilitado: config.citasReservadas.maxReservas.enabled,
            cantidad: config.citasReservadas.maxReservas.cantidad
          },
          habilitado: true
        },
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      await saveHorarioEmpresa(empresa.id, formData);
      addToast("Se guardaron los cambios satisfactoriamente", {
        appearance: "success",
        autoDismiss: true
      });
      setIsEditing(true);
      setError(null);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setIsSaving(false);
    }
  };

  const renderTimeRange = (day, range, index) => (
    <div key={index} className="time-range-container">
      <div className="time-inputs">
        <TimeInput
          type="time"
          value={range.start}
          onChange={(e) => updateTimeRange(day.label, index, 'start', e.target.value)}
          disabled={!isEditing || loading}
          step="900"
          style={{
            border: `2px solid ${timeErrors[`${day.label}-${index}`] ? '#ff0000' : '#0066cc'}`,
            borderRadius: '4px',
            padding: '4px',
            margin: '2px',
            backgroundColor: timeErrors[`${day.label}-${index}`] ? '#ffe6e6' : 'white'
          }}
        />
        <span>-</span>
        <TimeInput
          type="time"
          value={range.end}
          onChange={(e) => updateTimeRange(day.label, index, 'end', e.target.value)}
          disabled={!isEditing || loading} // Agrega || loading

          step="900"
          style={{
            border: `2px solid ${timeErrors[`${day.label}-${index}`] ? '#ff0000' : '#0066cc'}`,
            borderRadius: '4px',
            padding: '4px',
            margin: '2px',
            backgroundColor: timeErrors[`${day.label}-${index}`] ? '#ffe6e6' : 'white'
          }}
        />
        <IconButton onClick={() => deleteTimeRange(day.label, index)} disabled={!isEditing}>
          <CrossIcon />
        </IconButton>
      </div>
      {timeErrors[`${day.label}-${index}`] && (
        <div className="error-message">
          {timeErrors[`${day.label}-${index}`].map((error, i) => (
            <div key={i}>⚠️ {error}</div>
          ))}
        </div>
      )}
    </div>
  );


  return (
    <CalendarContainer>
      <SidePanelContainer isOpen={true}>
        <h3>Agenda de Citas</h3>

        <ThreeColumnLayout>
          <Column>
            <ScheduleSection>
              <ConfigHeader>
                <HeaderGroup>
                  <span>⏳</span>
                  <ConfigTitle>Duración de la cita</ConfigTitle>
                </HeaderGroup>
              </ConfigHeader>
              <StyledSelect
                value={config.duracionCitaMin}
                onChange={(e) => updateConfig('duracionCitaMin', e.target.value)}
                disabled={!isEditing}
              >
                <option value="15">15 minutos</option>
                <option value="30">30 minutos</option>
                <option value="45">45 minutos</option>
                <option value="60">60 minutos</option>
              </StyledSelect>
            </ScheduleSection>

            <ScheduleSection>
              <ConfigHeader>
                <HeaderGroup>
                  <span>📅</span>
                  <ConfigTitle>Plazo para progrmar citas</ConfigTitle>
                </HeaderGroup>
              </ConfigHeader>

              <span>Tiempo máximo de anticipación con el que se puede reservar una cita</span>
              <TimeConfigContainer>
                <label>
                  <input
                    type="checkbox"
                    checked={config.plazos.enabled}
                    onChange={(e) => updateConfig('plazos.enabled', e.target.checked)}
                    disabled={!isEditing}
                  />
                </label>
                <NumberInput
                  type="number"
                  min="0"
                  value={config.plazos.maxDays}
                  onChange={(e) => updateConfig('plazos.maxDays', e.target.value)}
                  onBlur={(e) => {
                    // Si está vacío o es menor que el mínimo, establece el valor mínimo
                    if (e.target.value === '' || Number(e.target.value) < 0) {
                      updateConfig('plazos.maxDays', '0');
                    }
                  }}
                  onClick={(e) => e.target.select()}
                  // disabled={!isEditing || !config.plazos.enabled}
                  disabled={!isEditing || !config.plazos.enabled}

                />
                <span>En minutos</span>
              </TimeConfigContainer>

              <span>Tiempo mínimo de anticipación con el que se puede reservar una cita</span>
              <TimeConfigContainer>
                <label>
                  <input
                    type="checkbox"
                    checked={config.plazos.minHoursEnabled}
                    onChange={(e) => updateConfig('plazos.minHoursEnabled', e.target.checked)}
                    disabled={!isEditing}
                  />
                </label>
                <NumberInput
                  type="number"
                  min="1"
                  value={config.plazos.minHours}
                  
                  onChange={(e) => updateConfig('plazos.minHours', e.target.value)}
                  onBlur={(e) => {
                    // Si está vacío o es menor que el mínimo, establece el valor mínimo
                    if (e.target.value === '' || Number(e.target.value) < 0) {
                      updateConfig('plazos.minHours', '0');
                    }
                  }}
                  onClick={(e) => e.target.select()}
                  // disabled={!isEditing || !config.plazos.enabled}
                  disabled={!isEditing || !config.plazos.minHoursEnabled} 
                />
                <span>horas</span>
              </TimeConfigContainer>
            </ScheduleSection>
          </Column>

          <Column1>
            <AvailabilityHeader>
              <ClockIcon />
              <h3>Horario Semanal</h3>
            </AvailabilityHeader>

            {days.map((day) => (
              <AvailabilityRow key={day.label}>
                <span className="day-label">{day.label}</span>
                {day.available ? (
                  <div className="vertical-container">
                    {day.timeRanges.map((range, index) => renderTimeRange(day, range, index))}
                    <IconButton
                      onClick={() => addTimeRange(day.label)}
                      disabled={!isEditing}
                      className="add-button"
                    >
                      <PlusIcon /> Añadir otro horario
                    </IconButton>
                  </div>
                ) : (
                  <div className="not-available">
                    <span>No disponible</span>
                    <IconButton
                      onClick={() => addTimeRange(day.label)}
                      disabled={!isEditing}
                    >
                      <PlusIcon />
                    </IconButton>
                  </div>
                )}
              </AvailabilityRow>
            ))}
          </Column1>

          <Column>
            <ScheduleSection>
              <ConfigHeader>
                <HeaderGroup>
                  <span>⚙️</span>
                  <ConfigTitle>Configuracion de citas reservadas</ConfigTitle>
                </HeaderGroup>
              </ConfigHeader>

              <ConfigTitle>Margen de tiempo</ConfigTitle>
              <span>Agrega tiempo entre los horarios disponibles</span>
              <TimeConfigContainer>
                <label>
                  <input
                    type="checkbox"
                    checked={config.citasReservadas.duracionPeriodo.enabled}
                    onChange={(e) => updateConfig('citasReservadas.duracionPeriodo.enabled', e.target.checked)}
                    disabled={!isEditing}
                  />
                </label>
                <NumberInput
                  type="number"
                  min="1"
                  value={config.citasReservadas.duracionPeriodo.tiempo}                  
                  onChange={(e) => updateConfig('citasReservadas.duracionPeriodo.tiempo', e.target.value)}
                  onBlur={(e) => {
                    // Si está vacío o es menor que el mínimo, establece el valor mínimo
                    if (e.target.value === '' || Number(e.target.value) < 0) {
                      updateConfig('citasReservadas.duracionPeriodo.tiempo', '0');
                    }
                  }}
                  onClick={(e) => e.target.select()}
                  // disabled={!isEditing || !config.plazos.enabled}
                  disabled={!isEditing || !config.citasReservadas.duracionPeriodo.enabled} 

                />
                <span>En minutos</span>
              </TimeConfigContainer>

              <ConfigTitle>Máximo de reservas por día</ConfigTitle>
              <span>Limita la cantidad de citas reservadas que se aceptan en un solo día</span>
              <TimeConfigContainer>
                <label>
                  <input
                    type="checkbox"
                    checked={config.citasReservadas.maxReservas.enabled}
                    onChange={(e) => updateConfig('citasReservadas.maxReservas.enabled', e.target.checked)}
                    disabled={!isEditing}
                  />
                </label>
                <NumberInput
                  type="number"
                  min="1"
                  value={config.citasReservadas.maxReservas.cantidad}                  
                  onChange={(e) => updateConfig('citasReservadas.maxReservas.cantidad', e.target.value)}
                  onBlur={(e) => {
                    // Si está vacío o es menor que el mínimo, establece el valor mínimo
                    if (e.target.value === '' || Number(e.target.value) < 0) {
                      updateConfig('citasReservadas.maxReservas.cantidad', '0');
                    }
                  }}
                  onClick={(e) => e.target.select()}
                  // disabled={!isEditing || !config.plazos.enabled}
                  disabled={!isEditing || !config.citasReservadas.maxReservas.enabled} 
                />
                <span>citas</span>
              </TimeConfigContainer>
            </ScheduleSection>

            <div style={{ marginTop: '20px' }}>
              <SaveButton
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <LoadingSpinner />
                    <ButtonText>Guardando...</ButtonText>
                  </>
                ) : (
                  <>
                    <SaveIconAlt />
                    <ButtonText>Guardar Cambios</ButtonText>
                  </>
                )}
              </SaveButton>
            </div>
          </Column>
        </ThreeColumnLayout>
      </SidePanelContainer>
    </CalendarContainer>
  );
};

export default Horarios;