import React, { useState } from 'react';
import { Modal } from '../../../../components/Modal/index';
//import { Modal } from '@material-ui/core';    
import Button from 'react-bootstrap/Button';

import styles from "./EditarModal.module.css"

const EditUserModal = ({ isOpen, handleCloseModal, onFileChange, formData, handleSaveUser, handleInputChange }) => {
    const perfiles = ["ODONTOLOGO", "ODONTOLOGO_ADM", "ASISTENTE", "ADMINISTRADOR"]
    const perfileOdontologo = ["ODONTOLOGO", "ODONTOLOGO_ADM"]

    // Custom styles for modal content with scrolling
    const modalContentStyle = {
        maxHeight: '80vh',
        overflowY: 'auto',
        padding: '1rem'
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleCloseModal}
        >
            <div 
                className={styles['container-derecha']} 
                style={modalContentStyle}
            >
                <div className={styles['title-0']}>
                    <span>{formData.id ? 'Edicion' : 'Registro'} de Usuarios</span>
                </div>

                {/* FORMULARIO DE REGISTRO DE USUARIO */}
                <div className={styles["formulario"]}>
                    <label className={styles['title-1']}>Seleccione el rol del trabajador:</label>
                    <select
                        className={styles["selector"]}
                        name="perfil"
                        value={formData.perfil}
                        onChange={handleInputChange}
                    >
                        <option value="" disabled>Seleccionar un perfil</option>
                        {perfiles.map((perfil, index) => (
                            <option key={index} value={perfil}>
                                {perfil}
                            </option>
                        ))}
                    </select>

                    <div className={styles['title-2']}>
                        <span>Ingrese Datos del Trabajador:</span>
                    </div>

                    <input
                        type="text"
                        placeholder='Nombres'
                        name="nombres"
                        value={formData.nombres}
                        onChange={handleInputChange}
                    />

                    <input
                        type="text"
                        placeholder='Email'
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />

                    {perfileOdontologo.includes(formData.perfil) && (
                        <>
                            <select
                                name="especialidad"
                                value={formData.especialidad}
                                onChange={handleInputChange}
                            >
                                <option value="">Selecciona una especialidad</option>
                                <option value="01">Cirugía Bucal y Maxilofacial</option>
                                <option value="02">Endodoncia</option>
                                <option value="03">Medicina y Patología Estomatológica</option>
                                <option value="04">Odontopediatría</option>
                                <option value="05">Odontogeriatría</option>
                                <option value="06">Odontología Restauradora y Estética.</option>
                                <option value="07">Odontología General.</option>
                                <option value="08">Ortodoncia y Ortopedia Maxilar</option>
                                <option value="09">Periodoncia e Implantología</option>
                                <option value="10">Radiología Bucal y Maxilofacial</option>
                                <option value="11">Rehabilitación Oral</option>
                                <option value="12">Salud Pública Estomatológica</option>
                            </select>

                            <input
                                type="text"
                                placeholder='COP'
                                name="cop"
                                value={formData.cop}
                                onChange={handleInputChange}
                            />
                        </>
                    )}

                    <div className={styles['file-upload-section']}>
                        <label className={styles['file-upload-label']}>
                            Firma:
                        </label>
                        <div className={styles['file-upload-container']}>
                            <input
                                type="file"
                                className={styles['file-input']}
                                onChange={onFileChange}
                                accept="image/*"
                            />
                            {formData.firma && (
                                <div className={styles['preview-image-container']}>
                                    <p>Vista previa:</p>
                                    <img 
                                        src={formData.firma} 
                                        alt="Vista previa" 
                                        className={styles['image-preview']} 
                                        style={{ maxWidth: '150px', maxHeight: '150px', border: '1px solid #ccc', borderRadius: '5px', marginTop: '10px' }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                   
                    <div className={styles['button-container']} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: '10px' }}>
                        <Button 
                            variant="secondary" 
                            onClick={handleCloseModal}
                        >
                            Cancelar
                        </Button>
                        <Button 
                            variant="primary" 
                            onClick={handleSaveUser}
                        >
                            Guardar
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default EditUserModal;