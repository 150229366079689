import { db, firestorage } from "../Config";

export const saveCites = (citas) => {
  return new Promise((resolve, reject) => {
    const citasRef = db.collection("citas").doc();
    citas.id = citasRef.id;

    citasRef
      .set(citas)
      .then(() => {
        resolve({ id: citasRef.id, ...citas });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCiteById = (id, cites) => {
  return new Promise((resolve, reject) => {
    const citasRef = db.collection("citas").doc(id);
    citasRef
      .update(cites) 
      .then(() => {
        resolve({ id, ...cites });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteCiteById = (id) => {
  return new Promise((resolve, reject) => {
    const citasRef = db.collection("citas").doc(id);
    citasRef
      .delete()
      .then(() => resolve({ id, message: "Cita eliminada correctamente" }))
      .catch((err) => reject(err));
  });
};


export const getCitesByBusinessId = (empresaId) => {
  return new Promise((resolve, reject) => {
    db.collection("citas")
      .where("calendarId", "==", empresaId)
      .get()
      .then((querySnapshot) => {
        const cites = querySnapshot.docs.map((doc) => ({
          id: doc.id, 
          ...doc.data(),
        }));
        resolve(cites);
      })
      .catch((err) => reject(err));
  });
};
