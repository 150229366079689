// styles.js - Componentes completos para tabla de medicamentos responsive
import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../../components/Dispositivos";

// Color variables for consistency
const colors = {
  primary: "#0c8de3",
  primaryLight: "#dcf2f9",
  primaryDark: "#0060b0",
  secondary: "#f8f8f8",
  text: "#000000",
  textLight: "#707070",
  white: "#ffffff",
  border: "#dee2e6",
  shadow: "#88888877",
  hoverRow: "rgba(12, 141, 227, 0.1)",
};

// Spacing variables
const spacing = {
  xs: "0.5rem",
  sm: "1rem",
  md: "1.5rem",
  lg: "2rem",
  xl: "3rem",
};

// Typography variables
const typography = {
  small: "12px",
  base: "14px",
  medium: "16px",
  large: "18px",
  xl: "20px",
};

// Contenedor principal que decide qué versión mostrar según el dispositivo
export const MedicamentosTablasContainer = styled.div`
  width: 100%;
  
  // Versión laptop visible por defecto en pantallas más grandes
  .tabla-laptop {
    display: none;
    
    @media ${dispositivo.tablet} {
      display: block;
    }
  }
  
  // Versión móvil visible solo en pantallas pequeñas
  .tabla-movil {
    display: block;
    
    @media ${dispositivo.tablet} {
      display: none;
    }
  }
`;

// Improved Table components with enhanced responsiveness
export const TablaContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: ${spacing.md};
  border-radius: 10px;
  box-shadow: 0 0 15px ${colors.shadow};
  
  &::-webkit-scrollbar {
    height: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${colors.secondary};
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${colors.primary};
    border-radius: 3px;
  }
`;

export const Tabla = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden;
  font-size: ${typography.small};
  table-layout: auto;
  min-width: 600px; // Ancho mínimo para mantener estructura
  background: ${colors.white};
  transition: all 0.3s ease;

  // Mobile Small (hasta 320px)
  @media (max-width: 320px) {
    font-size: 0.7rem;
    border-radius: 8px;
    min-width: 320px;
  }

  // Mobile Medium (321px - 375px)
  @media ${dispositivo.mobileS} {
    font-size: 0.75rem;
    min-width: 340px;
  }

  // Mobile Large (376px - 425px)
  @media ${dispositivo.mobileL} {
    font-size: 0.8rem;
    min-width: 380px;
    border-spacing: 0 2px;
  }

  // Tablet Pequeña (426px - 768px)
  @media ${dispositivo.tablet} {
    font-size: ${typography.base};
    min-width: auto;
    width: 100%;
    table-layout: fixed;
  }

  // Tablet Landscape (769px - 1024px)
  @media ${dispositivo.tabletL} {
    font-size: ${typography.medium};
    border-spacing: 0 4px;
  }

  // Laptop (1025px - 1440px)
  @media ${dispositivo.laptop} {
    font-size: ${typography.large};
    table-layout: auto;
  }

  // Pantallas Grandes (1441px+)
  @media ${dispositivo.desktop} {
    font-size: ${typography.xl};
    border-spacing: 0 6px;
  }

  // Orientación Horizontal
  @media (orientation: landscape) {
    min-width: auto;
    width: 100%;
  }

  // Pantallas de alta densidad (Retina)
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-radius: 12px;
    border: 0.5px solid ${colors.border};
  }
`;

export const TablaHeader = styled.thead`
  background: ${colors.primary};
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const TablaHeaderRow = styled.tr`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const TablaHeaderTexto = styled.th`
  padding: 8px 10px;
  text-align: left;
  color: ${colors.white};
  font-weight: 600;
  white-space: nowrap;
  transition: all 0.2s ease;
  position: relative;
  font-size: ${typography.small};
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:first-child {
    border-top-left-radius: 8px;
  }
  
  &:last-child {
    border-top-right-radius: 8px;
  }

  // Mobile Small (hasta 320px)
  @media (max-width: 320px) {
    padding: 6px 8px;
    font-size: 0.6rem;
    border-radius: 6px;
  }

  // Mobile Medium (321px - 375px)
  @media ${dispositivo.mobileM} {
    padding: 7px 9px;
    font-size: 0.7rem;
  }

  // Mobile Large (376px - 425px)
  @media ${dispositivo.mobileL} {
    padding: 10px 12px;
    font-size: ${typography.small};
  }

  // Tablet (426px - 768px)
  @media ${dispositivo.tablet} {
    padding: 12px 14px;
    font-size: ${typography.base};
    white-space: normal;
  }

  // Tablet Landscape (769px - 1024px)
  @media ${dispositivo.tabletL} {
    padding: 14px 16px;
    font-size: ${typography.medium};
  }

  // Laptop (1025px - 1440px)
  @media ${dispositivo.laptop} {
    padding: 16px 18px;
    font-size: ${typography.large};
  }

  // Pantallas Grandes (1441px+)
  @media ${dispositivo.desktop} {
    padding: 18px 20px;
    font-size: ${typography.xl};
  }

  // Orientación Horizontal
  @media (orientation: landscape) {
    padding: 10px 12px;
    font-size: 0.8rem;
  }
`;

export const TablaBody = styled.tbody`
  background: ${colors.white};
  
  // Mobile Small (hasta 320px)
  @media (max-width: 320px) {
    tr {
      display: block;
      margin-bottom: 10px;
    }
  }

  // Tablet (426px - 768px)
  @media ${dispositivo.tablet} {
    tr:nth-child(even) {
      background: ${colors.primaryLight};
    }
  }

  // Pantallas Grandes (1441px+)
  @media ${dispositivo.desktop} {
    tr:hover {
      background: ${colors.hoverRow};
    }
  }

  // Modo Alto Contraste
  @media (prefers-contrast: more) {
    background: ${colors.white};
    border: 2px solid ${colors.text};
  }
`;

export const TablaRow = styled.tr`
  transition: background-color 0.2s ease;
  
  &:nth-child(odd) {
    background-color: ${colors.white};
  }
  
  &:nth-child(even) {
    background-color: ${colors.primaryLight};
  }
  
  &:hover {
    background-color: ${colors.hoverRow};
  }
  
  &:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  
  &:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
`;

export const TablaBodyTexto = styled.td`
  padding: 10px 16px;
  border-bottom: 1px solid ${colors.border};
  text-overflow: ellipsis;
  white-space: ${props => props.noWrap ? 'nowrap' : 'normal'};
  transition: padding 0.2s ease;
  vertical-align: middle;
  
  @media ${dispositivo.mobileL} {
    padding: 12px 16px;
  }
  
  @media ${dispositivo.tablet} {
    padding: 14px 20px;
  }
  
  @media ${dispositivo.laptop} {
    padding: 16px 20px;
  }
`;

// Componentes específicos para la versión móvil
export const MedicamentoCard = styled.div`
  background: ${colors.white};
  border-radius: 10px;
  box-shadow: 0 0 10px ${colors.shadow};
  margin-bottom: ${spacing.sm};
  padding: ${spacing.sm};
  
  &:nth-child(even) {
    background-color: ${colors.primaryLight};
  }
  
  &:hover {
    box-shadow: 0 5px 15px ${colors.shadow};
    transform: translateY(-2px);
    transition: all 0.3s ease;
  }
`;

export const MedicamentoCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${spacing.xs};
  margin-bottom: ${spacing.xs};
  border-bottom: 2px solid ${colors.primary};
  
  h3 {
    margin: 0;
    font-size: ${typography.medium};
    color: ${colors.primaryDark};
    font-weight: 600;
  }
  
  span {
    font-size: ${typography.small};
    color: ${colors.textLight};
    background: ${colors.primaryLight};
    padding: 2px 6px;
    border-radius: 4px;
  }
`;

export const MedicamentoCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing.xs} 0;
  
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.border};
  }
`;

export const MedicamentoCardLabel = styled.span`
  font-weight: 600;
  color: ${colors.primary};
  margin-right: ${spacing.sm};
  font-size: ${typography.small};
`;

export const MedicamentoCardValue = styled.span`
  text-align: right;
  flex: 1;
  font-size: ${typography.small};
  color: ${colors.text};
`;

// Componentes para manejar la responsive
export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: ${colors.secondary};
`;


export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${spacing.md};
  flex-wrap: wrap;
  
  @media ${dispositivo.mobileL} {
    flex-direction: ${props => props.stackOnMobile ? "column" : "row"};
  }
`;

export const FlexContainerRight = styled(FlexContainer)`
  justify-content: flex-end;
`;

export const ContainerBody = styled.div`
  width: 98%;
  min-height: auto;
  padding: ${spacing.sm};
  margin: 0 auto;
  background: ${colors.white};
  box-shadow: 0 4px 6px ${colors.shadow};
  border-radius: 14px;
  transition: all 0.3s ease;
  
  // Mobile Small (hasta 320px)
  @media (max-width: 320px) {
    width: 100%;
    padding: ${spacing.xs};
    border-radius: 12px;
  }

  // Mobile Medium (321px - 375px)
  @media ${dispositivo.mobileM} {
    width: 99%;
    padding: ${spacing.sm};
  }

  // Mobile Large (376px - 425px)
  @media ${dispositivo.mobileL} {
    width: 95%;
    padding: ${spacing.md};
  }

  // Tablet (426px - 768px)
  @media ${dispositivo.tablet} {
    width: 90%;
    padding: ${spacing.md};
    box-shadow: 0 6px 8px ${colors.shadow};
  }

  // Laptop (769px - 1024px)
  @media ${dispositivo.laptop} {
    width: 85%;
    padding: ${spacing.lg};
    max-width: 1200px;
  }

  // Laptop Large (1025px - 1440px)
  @media ${dispositivo.laptopL} {
    width: 80%;
    padding: ${spacing.lg};
    max-width: 1440px;
  }

  // Desktop (1441px+)
  @media ${dispositivo.desktop} {
    width: 75%;
    padding: ${spacing.xl};
    max-width: 1600px;
  }

  // Orientación landscape
  @media (orientation: landscape) {
    width: 90%;
    padding: ${spacing.md};
  }
`;

export const ContainerTitle = styled.div`
  color: ${colors.text};
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacing.md};
`;

export const ContainerTitleText = styled.h2`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  
  @media ${dispositivo.mobileL} {
    font-size: 16px;
  }
  
  @media ${dispositivo.tablet} {
    font-size: 20px;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: ${spacing.sm} 0;
  
  @media ${dispositivo.tablet} {
    justify-content: flex-end;
  }
`;

export const ContenedorLista = styled.div`
  width: 100%;
  margin-bottom: ${spacing.md};
  
  @media ${dispositivo.tablet} {
    width: 30%;
    margin-right: 2%;
  }
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 120px;
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
  resize: vertical;
  transition: all 0.2s ease;
  background-color: #ffffff;
  color: #333333;
  
  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
  
  &::placeholder {
    color: #999999;
  }
  
  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const ContainerLista = styled.div`
  width: 100%;
  height: 100%;
  padding: ${spacing.sm};
  font-size: 12px;
  background: ${colors.white};
  box-shadow: 2px 2px ${colors.shadow};
  border: 2px solid ${colors.primaryLight};
  border-radius: 8px;
  
  @media ${dispositivo.tablet} {
    font-size: 14px;
    padding: ${spacing.md};
  }
`;

export const UlLista = styled.ul`
  list-style-type: none;
  padding: ${spacing.xs} 0;
  margin: 0;
`;

export const LiLista = styled.li`
  color: ${colors.text};
  padding: ${spacing.xs} 0;
  
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.border};
  }
`;

export const Liga = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.primary};
  font-size: 12px;
  transition: color 0.3s ease;
  
  &:hover {
    color: ${colors.primaryDark};
  }
  
  @media ${dispositivo.tablet} {
    font-size: 14px;
  }
`;

export const StyledLink = styled(Link)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.25rem;
    font-size: 0.9rem;
    width: 10rem;
    border-radius: 0.8rem;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
    background-color: ${colors.primaryDark};
    color: ${colors.white};
    border: none;
    transition: all 0.3s ease;
    
    &:hover {
      color: ${colors.text};
      background-color: ${colors.primaryLight};
    }
    
    @media ${dispositivo.tablet} {
      font-size: 1rem;
    }
    
    @media ${dispositivo.laptop} {
      width: 12.5rem;
    }
  }
`;

// Download button specific for PDF or documents
export const DownloadLink = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: 6px;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${colors.primaryDark};
    color: ${colors.white};
    text-decoration: none;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  &:before {
    content: "📄";
    margin-right: 8px;
  }
  
  @media ${dispositivo.tablet} {
    font-size: 15px;
    padding: 10px 18px;
  }
`;


export const StyledInput = styled.input`
  width: 100%;
  padding: 12px 20px 12px 45px;
  border: 2px solid #3498db;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: white;
  color: #2c3e50;
  
  &:focus {
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
    outline: none;
  }
  
  &::placeholder {
    color: #95a5a6;
  }
`;
