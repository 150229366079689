import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../Dispositivos";

export const AutocompleteStyle = styled.div`
  width: 100%;
  display: relative;
  justify-content: center;
  margin-top: 0%;
  text-align: left; 
`;
export const Item = styled.li`
  display: grid;  
  grid-template-columns: 130px 88px 66px;
  align-items: center;
  cursor: pointer;
  height: 2rem;
  width: 100%;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileM} {
    grid-template-columns: 139px 94px 75px;
  }
  @media ${dispositivo.mobileL} {
    grid-template-columns: 181px 112px 80px;
  }
  @media ${dispositivo.tablet} {
    grid-template-columns: 230px 145px 90px;
  }
  @media ${dispositivo.laptop} {
    grid-template-columns: 250px 160px 90px;
  }
 .spanPrecio{
  padding-right: 0;
  text-align: end;
  border-radius: 5px;
  @media ${dispositivo.mobileM} {
  padding-right: 0.2em;
  }
 }
`;
export const AutocompleteResultStyle = styled.ul`
  position: relative;
  top: 0px;
  z-index: 100;
  background-color: rgb(255, 255, 255);
  width: 100%;
  color: rgb(0, 96, 176);
  list-style-type: none;
  font-size: 16px;
  border-radius: 6px;
  outline: 0px;
  transition: border-color 0.2s;
  box-shadow: rgba(50, 151, 169, 0.3) 0px 4px 10px;
  @media ${dispositivo.mobileM} {
    font-size: 0.8rem;
   }
  @media ${dispositivo.tablet} {
   font-size: 1rem;
  }
`;

export const FormField = styled.input`
  height: 2.4rem;
  border-radius: .25rem;
  border: 1px solid #ced4da;
  outline: none;
  color: #474747;
  margin: 0 0 0.1em;
  padding-left: 12px;
  line-height: 2.5;
  font-size: 1rem;
  width: 100%;

  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
`;

export const ScrollDiv = styled.div`
  flex: 1;
  max-height: 300px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
`;