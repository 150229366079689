import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { guardarReceta } from "../../redux/receta/recetaActions";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getMedicamentos, tieneMedicamentosConfigurados } from "../../services/firebase/recetas";
import {
  ContainerBody,
  ContainerLeft,
  ContainerTitle,
  ContainerTitleText,
  MainContainer,
  StyledLink,
  StyledInputButton,
  DivForm,
  StyledTextarea,
  DesktopView,
  MobileView,
  SearchContainer,
  MedicinesContainer,
  FlexContainer,
  StyledInput,
  SuggestionContainer,
  SuggestionItem,
  ModalBackdrop,
  ModalContainer,
  ModalHeader,
  ModalForm,
  ModalFooter,
  ModalButton,
  /** Agregados */
  MobileMedicineCard,
  MedicineHeader,
  MedicineName,
  MedicineConcentration,
  MedicineDetail,
  DetailLabel,
  DetailValue,
  MobileActions,
  SearchIcon,
  LoadingSpinner,
  SpinnerIcon,
  InfoIcon,
  MedicationIcon,
  ArrowIcon
} from "./styles";


export const Receta = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [medicamentosSugeridos, setMedicamentosSugeridos] = useState([]);
  const [selectedMedicines, setSelectedMedicines] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [empresaIdBusqueda, setEmpresaIdBusqueda] = useState("0");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMedicine, setCurrentMedicine] = useState(null);
  const [editIndex, setEditIndex] = useState(-1);
  const paciente = useSelector((state) => state.paciente);
  const sesion = useSelector((state) => state.sesion);
  const usuario = useSelector((state) => state.usuario);
  const empresa = useSelector((state) => state.empresa);
  const [indicacionesAdicionales, setIndicacionesAdicionales] = useState("");
  

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const history = useHistory();
  const logoUrl = empresa.logoUrl
  console.log('loos datos de empresa ', empresa)
  console.log('loos datos de usuarios ', usuario)

  const textareaRef = useRef(null);
  const cursorPositionRef = useRef(null);

  // Efecto para manejar la posición del cursor después de actualizar el estado
  useEffect(() => {
    if (textareaRef.current && cursorPositionRef.current !== null) {
      const newPosition = cursorPositionRef.current;
      textareaRef.current.focus();
      textareaRef.current.selectionStart = newPosition;
      textareaRef.current.selectionEnd = newPosition;
      cursorPositionRef.current = null; // Resetear para futuros cambios
    }
  }, [indicacionesAdicionales]); // Se ejecuta cuando cambia el texto

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const textarea = e.target;
      const cursorPosition = textarea.selectionStart;
      const currentValue = textarea.value;
      const textBefore = currentValue.substring(0, cursorPosition);
      const textAfter = currentValue.substring(cursorPosition);
      
      // Añadir "* " automáticamente al inicio de cada nueva línea
      const newValue = textBefore + "\n* " + textAfter;
      
      // Guardar la posición donde queremos que esté el cursor después de la actualización
      cursorPositionRef.current = cursorPosition + 3;
      
      // Actualizar el estado
      setIndicacionesAdicionales(newValue);
    }
  };

  useEffect(() => {
    const verificarMedicamentos = async () => {
      if (empresa?.id) {
        const tieneMedicamentos = await tieneMedicamentosConfigurados(empresa.id);
        setEmpresaIdBusqueda(tieneMedicamentos ? empresa.id : "0");
      }
    };
    verificarMedicamentos();
  }, [empresa?.id]);

  const handleSearchChange = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    setIsLoading(true);

    if (term.length > 2) {
      try {
        const medicamentos = await getMedicamentos(term, empresaIdBusqueda);
        setMedicamentosSugeridos(medicamentos);
      } catch (error) {
        addToast("Error al buscar medicamentos", { appearance: "error" });
        setMedicamentosSugeridos([]);
      }
    } else {
      setMedicamentosSugeridos([]);
    }
    setIsLoading(false);
  };

  const handleRemoveItem = (index) => {
    const updatedMedicines = selectedMedicines.filter((_, i) => i !== index);
    setSelectedMedicines(updatedMedicines);
    addToast(`Medicamento eliminado - Quedan ${updatedMedicines.length} registros`, {
      appearance: "success",
      autoDismiss: true
    });
  };

  const handleModalSave = () => {
    const updatedMedicines = [...selectedMedicines];
    updatedMedicines[editIndex] = currentMedicine;
    setSelectedMedicines(updatedMedicines);
    setIsModalOpen(false);
  };

  const handleMedicinaSelect = (medicamento) => {
    const newMedicine = {
      medicina: medicamento.medicina,
      medicinaConfirmada: medicamento.medicina,
      concentracion: medicamento.concentracion,
      formato: medicamento.formato || "",
      dosisYFrecuencia: medicamento.dosisYFrecuencia || "",
      cantidad: medicamento.cantidad || "",
      duracion: medicamento.duracion || "",
    };

    setSelectedMedicines([...selectedMedicines, newMedicine]);
    setSearchTerm("");
    setMedicamentosSugeridos([]);
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setCurrentMedicine(selectedMedicines[index]);
    setIsModalOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowErrors(false);

    if (selectedMedicines.length === 0) {
      addToast("Debe agregar al menos un medicamento antes de guardar", {
        appearance: "error",
        autoDismiss: false
      });
      return;
    }

    const hasEmptyFields = selectedMedicines.some(medicine => (
      !medicine.medicina ||
      !medicine.medicinaConfirmada ||
      (typeof medicine.formato === 'string' && medicine.formato.trim() === "") ||
      (typeof medicine.dosisYFrecuencia === 'string' && medicine.dosisYFrecuencia.trim() === "") ||
      (typeof medicine.cantidad === 'string' && medicine.cantidad.trim() === "")
    ));

    if (hasEmptyFields) {
      setShowErrors(true);
      addToast("Complete todos los campos requeridos", { appearance: "error" });
      return;
    }

    const receta = {

      pacienteId: paciente.id || "",
      pacienteDNI: paciente.numDoc || "",
      pacienteCorreo: paciente.correo || "",
      indicacionesAdicionales: indicacionesAdicionales || "",
      pacienteNombres: `${paciente.nombres || ""} ${paciente.apellidos || ""}`.trim(),
      fecha: new Date(),
      especialista: usuario.data?.email || "",
      especialistaNombres: usuario.data?.nombres || "",
      especialistaCop: usuario.data?.cop || "",
      especialistaId: usuario.data?.id || "",
      especialistaFirma: usuario?.data?.firma || "",
      items: selectedMedicines,
      empresaId: empresa?.id || "",
      empresaLogo: empresa?.logoUrl || "",
      empresaRazonSocial: empresa?.razonSocial || "",
      empresaTelefono: empresa?.telefono || "",
      empresaRuc: empresa?.ruc || "",
    };

    if (!receta.pacienteId) {
      addToast("Error: No se ha seleccionado un paciente", { appearance: "error" });
      return;
    }

    try {
      dispatch(guardarReceta(receta));
      addToast("Receta guardada exitosamente", { appearance: "success" });
      history.push("recetas");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Receta Odontológica</ContainerTitleText>
      </ContainerTitle>

      <ContainerBody>
        <FlexContainer>
          <ContainerTitleText>
            {`Paciente: ${paciente.apellidos} ${paciente.nombres}`}
          </ContainerTitleText>
          <ContainerTitleText>{`Fecha: ${sesion.fecha}`}</ContainerTitleText>
        </FlexContainer>

        <DivForm onSubmit={handleSubmit}>
          <SearchContainer>
            <div className="search-input-container">
              <SearchIcon />
              <StyledInput
                type="text"
                placeholder={empresaIdBusqueda ? "Buscar Medicina" : "Buscar en catálogo general"}
                value={searchTerm}
                onChange={handleSearchChange}
              />
              {isLoading && <LoadingSpinner />}
            </div>

            {isLoading ? (
              <SuggestionContainer>
                <div className="loading-message">
                  <SpinnerIcon />
                  <span>Buscando medicamentos...</span>
                </div>
              </SuggestionContainer>
            ) : (
              searchTerm.length > 2 &&
              (medicamentosSugeridos.length === 0 ? (
                <SuggestionContainer>
                  <div className="no-results">
                    <InfoIcon />
                    <span>No se encontró el medicamento</span>
                  </div>
                </SuggestionContainer>
              ) : (
                <SuggestionContainer>
                  {medicamentosSugeridos.map((med) => (
                    <SuggestionItem key={med.id} onClick={() => handleMedicinaSelect(med)}>
                      <MedicationIcon />
                      <div className="med-info">
                        <span className="medicamento">{med.medicina}</span>
                        <span className="concentracion">{med.concentracion}</span>
                      </div>
                      <ArrowIcon />
                    </SuggestionItem>
                  ))}
                </SuggestionContainer>
              ))
            )}
          </SearchContainer>

          <MedicinesContainer>
            <DesktopView>
              <table>
                <thead>
                  <tr>
                    <th>Medicina</th>
                    <th>Concentración</th>
                    <th>Formato</th>
                    <th>Dosis Y Frecuencia</th>
                    <th>Duración</th>
                    <th>Cantidad</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedMedicines.map((medicine, index) => (
                    <tr key={index}>
                      <td>{medicine.medicinaConfirmada}</td>
                      <td>{medicine.concentracion}</td>
                      <td>{medicine.formato}</td>
                      <td>{medicine.dosisYFrecuencia}</td>
                      <td>{medicine.duracion}</td>
                      <td>{medicine.cantidad}</td>
                      <td>
                        <div style={{ display: 'flex', gap: '8px' }}>
                          <IconButton onClick={() => handleEditClick(index)}>
                            <EditIcon size={18} />
                          </IconButton>
                          <IconButton onClick={() => handleRemoveItem(index)}>
                            <DeleteIcon size={18} />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </DesktopView>

            <MobileView>
              {selectedMedicines.map((medicine, index) => (
                <MobileMedicineCard key={index}>
                  <MedicineHeader>
                    <MedicineName>{medicine.medicinaConfirmada}</MedicineName>
                    <MedicineConcentration>{medicine.concentracion}</MedicineConcentration>
                  </MedicineHeader>

                  <MedicineDetail>
                    <DetailLabel>Cantidad:</DetailLabel>
                    <DetailValue>{medicine.cantidad}</DetailValue>
                  </MedicineDetail>

                  <MedicineDetail>
                    <DetailLabel>Formato:</DetailLabel>
                    <DetailValue>{medicine.formato}</DetailValue>
                  </MedicineDetail>

                  <MedicineDetail>
                    <DetailLabel>Dosis Y Frecuencia:</DetailLabel>
                    <DetailValue>{medicine.dosisYFrecuencia}</DetailValue>
                  </MedicineDetail>

                  <MedicineDetail>
                    <DetailLabel>Duración:</DetailLabel>
                    <DetailValue>
                      {medicine.duracion}
                    </DetailValue>
                  </MedicineDetail>

                  <MobileActions>
                    <IconButton onClick={() => handleEditClick(index)}>
                      <EditIcon size={18} />
                    </IconButton>
                    <IconButton onClick={() => handleRemoveItem(index)}>
                      <DeleteIcon size={18} />
                    </IconButton>
                  </MobileActions>
                </MobileMedicineCard>
              ))}
            </MobileView>
          </MedicinesContainer>

          {isModalOpen && (
            <ModalBackdrop>
              <ModalContainer>
                <ModalHeader>Editamr Medicamento</ModalHeader>
                <ModalForm>
                  <div className="input-row">
                    <div className="input-group full-width">
                      <label>Medicamento</label>
                      <StyledInput
                        type="text"
                        value={currentMedicine?.medicinaConfirmada || ""}
                        readOnly
                        className="read-only"
                      />
                    </div>
                  </div>

                  <div className="input-row">
                    <div className="input-group">
                      <label>Concentración</label>
                      <StyledInput
                        type="text"
                        value={currentMedicine?.concentracion || ""}
                        onChange={(e) => setCurrentMedicine({ ...currentMedicine, concentracion: e.target.value })}
                      />
                    </div>
                    <div className="input-group">
                      <label>Formato</label>
                      <StyledInput
                        type="text"
                        value={currentMedicine?.formato || ""}
                        onChange={(e) => setCurrentMedicine({ ...currentMedicine, formato: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="input-row">
                    <div className="input-group">
                      <label>Dosis Y Frecuencia</label>
                      <StyledInput
                        type="text"
                        value={currentMedicine?.dosisYFrecuencia || ""}
                        onChange={(e) => setCurrentMedicine({ ...currentMedicine, dosisYFrecuencia: e.target.value })}
                      />
                    </div>
                    <div className="input-group" style={{ position: 'relative' }}>
                      <label>Duración</label>
                      <StyledInput
                        type="text"
                        value={currentMedicine?.duracion?.replace(/\D/g, '') || ""}
                        onChange={(e) => {
                          const rawValue = e.target.value.replace(/\D/g, ''); // Eliminar todo excepto números
                          const numericValue = parseInt(rawValue, 10);

                          // Validar valor numérico
                          if (rawValue === "") {
                            setCurrentMedicine({ ...currentMedicine, duracion: "" });
                          } else if (numericValue < 1) {
                            addToast("Solo permitir números del 1 en adelante", { appearance: "error" });
                          } else {
                            setCurrentMedicine({ ...currentMedicine, duracion: `${numericValue} días` });
                          }
                        }}
                        onKeyPress={(e) => {
                          // Bloquear entrada de caracteres no numéricos
                          if (!/\d/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        style={{ paddingRight: '60px' }}
                      />
                      <span style={{
                        position: 'absolute',
                        right: '10px',
                        top: '58%',
                        color: '#666',
                        pointerEvents: 'none',
                        display: currentMedicine?.duracion ? 'block' : 'none'
                      }}

                      >
                        días
                      </span>
                    </div>
                  </div>

                  <div className="input-row">
                    <div className="input-group">
                      <label>Cantidad</label>
                      <StyledInput
                        type="text"
                        value={currentMedicine?.cantidad || ""}
                        onChange={(e) => setCurrentMedicine({ ...currentMedicine, cantidad: e.target.value })}
                      />
                    </div>
                  </div>

                  <ModalFooter>
                    <ModalButton className="cancel" onClick={() => setIsModalOpen(false)}>
                      Cancelar
                    </ModalButton>
                    <ModalButton className="save" onClick={handleModalSave}>
                      Guardar
                    </ModalButton>
                  </ModalFooter>
                </ModalForm>
              </ModalContainer>
            </ModalBackdrop>
          )}
    <div className="input-row">
      <div className="input-group">
        <label>Indicaciones Adicionales</label>
        <StyledTextarea
          ref={textareaRef}
          value={indicacionesAdicionales || ""}
          onChange={(e) => setIndicacionesAdicionales(e.target.value)}
          onKeyDown={handleKeyDown}
          rows={4}
          placeholder="Presiona Enter para añadir nuevos elementos a la lista"
        />
      </div>
    </div>
          <ContainerLeft className="buttons">
            <StyledLink to="recetas">CANCELAR</StyledLink>
            <StyledInputButton
              type="submit"
              value={`GUARDAR  ${selectedMedicines.length} ${selectedMedicines.length !== 1 ? '' : ''}`}
              $active={selectedMedicines.length > 0}
            />
          </ContainerLeft>

          <FlexContainer>
            <ContainerTitleText>{`C.D. ${sesion.usuario.nombres}`}</ContainerTitleText>
            <ContainerTitleText>{`C.O.P. ${usuario.data.cop}`}</ContainerTitleText>
          </FlexContainer>
        </DivForm>
      </ContainerBody>
    </MainContainer>
  );
};