import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  updateMedicamentosM,
  prepararDatosMedicamento,
  fetchMedicamentoById,
} from "../../services/firebase/Medicamentos/index";

import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledForm,
  StyledLabel,
  StyledInput,
  StyledInputNumber,
  StyledSelect,
  StyledButton,
  LinkCancelar,
  FormGrid,
  FormGroup,
  InputGroup,
  DosisHeader,
  ToggleDosisButton,
  PreviewBox,
  InputGroupF,
  InputWithUnit,
  Unit,
  FormGroup3columnas,
  ActionButtons
} from "./styles";

// Constantes
const FORMATOS = ["CAP", "TB", "AMP", "JBE", "SOL", "CRM", "GTS", "SUP", "PLV", "INH", "FR"];
const UNIDADES_CONCENTRACION = ["mg", "g", "mcg", "UI", "mEq", "mg/ml", "%", "mg/g", "mmol"];
const DOSIS_UNIDAD = ["Cap", "TB", "ml", "gotas", "ampolla", "inhalación", "aplicación", "cucharada", "sobre", "cápsula", "parche"];
const FRECUENCIA_OPCIONES = [
  "C/ 4 horas", "C/ 6 horas", "C/ 8 horas", "C/ 12 horas", "C/ 24 horas",
  "1 vez al día", "2 veces al día", "3 veces al día", "4 veces al día",
  "Cada noche", "Según necesidad", "Antes de comidas", "Con las comidas",
  "Después de comidas", "Personalizado"
];
const VIAS_ADMINISTRACION = [
  "V. Oral", "V. Intramuscular", "V. Intravenosa", "V. Subcutánea",
  "V. Tópica", "V. Oftálmica", "V. Ótica", "V. Nasal", "V. Rectal", "Iny"
];

const capitalize = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Función para validar los campos
const validarCampos = (nombre, valor, addToast) => {
  switch (nombre) {
    case "duracion":
    case "cantidad":
      // Validación para campos numéricos
      const numericValue = valor.replace(/\D/g, '');

      if (numericValue.startsWith("0") && numericValue.length > 0) {
        addToast("No se permite escribir 0 como primer carácter", { appearance: "error" });
        return null;
      }
      return numericValue;

    case "concentracionValor":
      return valor.replace(/\D/g, '');

    case "dosisCantidadPersonalizada":
      if (valor === "" || /^\d*$/.test(valor)) {
        return valor;
      }
      return null;

    default:
      return valor;
  }
};

export const ConfigMedicamentos = () => {
  // Hooks y estados básicos
  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();
  const sesion = useSelector(state => state.sesion);
  const empresa = useSelector(state => state.empresa);
  const isEditing = !!location.state?.data;
  const [loading, setLoading] = useState(false);

  // Estados del formulario para medicamento
  const [medicamento, setMedicamento] = useState({
    medicamento: "",
    formato: "",
    concentracionValor: "",
    concentracionUnidad: "mg",
    dosisPersonalizada: "",
    viaAdministracion: "",
    duracion: "",
    cantidad: "",
    estado: "ACTIVO"
  });

  // Estado para datos adicionales
  const [item, setItem] = useState({
    especialidad: "General",
    medicina: "",
    viaAdministracion: ""
  });

  // Estados para dosis
  const [usarDosisPersonalizada, setUsarDosisPersonalizada] = useState(false);
  const [dosisCantidadPersonalizada, setDosisCantidadPersonalizada] = useState("1");
  const [dosisUnidad, setDosisUnidad] = useState("Cap");
  const [frecuencia, setFrecuencia] = useState("C/ 8 horas");
  const [frecuenciaPersonalizada, setFrecuenciaPersonalizada] = useState("");
  const [dosisPersonalizada, setDosisPersonalizada] = useState("");

  // Estados para concentración
  const [concentracionValor, setConcentracionValor] = useState("");
  const [concentracionUnidad, setConcentracionUnidad] = useState("mg");

  // Estados para duración y cantidad
  const [duracionTexto, setDuracionTexto] = useState("");
  const [cantidadTexto, setCantidadTexto] = useState("");

  // Preview de dosis
  const dosisFrecuenciaCompleta = React.useMemo(() => {
    if (usarDosisPersonalizada) {
      return dosisPersonalizada;
    } else {
      const dosisCantidadStr = dosisCantidadPersonalizada || "";
      const frecuenciaFinal = frecuencia === "Personalizado" ? frecuenciaPersonalizada : frecuencia;
      return `${dosisCantidadStr} ${dosisUnidad} ${frecuenciaFinal} `;
    }
  }, [dosisCantidadPersonalizada, dosisUnidad, frecuencia, frecuenciaPersonalizada, usarDosisPersonalizada, dosisPersonalizada]);

  // Validar input numérico
  const handleKeyPress = (e) => {
    if (!/\d/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== 'Tab') {
      e.preventDefault();
      addToast("Solo se permiten números en este campo", { appearance: "warning" });
    }
  };

  // Cambiar entre dosis personalizada y estructurada
  const toggleDosisPersonalizada = () => {
    setUsarDosisPersonalizada(!usarDosisPersonalizada);
  };

  // Cargar datos de medicamento para edición
  useEffect(() => {
    const cargarDatosEdicion = async () => {
      if (isEditing && location.state?.data?.id) {
        try {
          const med = await fetchMedicamentoById(empresa.id, location.state.data.id);

          if (!med) {
            addToast("Error: No se pudo cargar el medicamento", { appearance: "error" });
            return;
          }

          // Procesar datos de concentración
          let valConcentracion = "", uniConcentracion = "mg";
          if (med.concentracion) {
            const partes = med.concentracion.split(' ');
            valConcentracion = partes[0] || "";
            uniConcentracion = partes[1] || "mg";
          }

          // Procesar duración y cantidad
          const duracion = med.duracion ? med.duracion.replace(' días', '') : '';
          const cantidad = med.cantidad ? med.cantidad.toString() : '';

          // Procesar dosis y frecuencia
          const tieneDosisEstructurada = med.dosisYFrecuencia &&
            med.dosisYFrecuencia.match(/^\d+\s+\w+\s+.+$/);

          setUsarDosisPersonalizada(!tieneDosisEstructurada);

          if (tieneDosisEstructurada) {
            // Intentar extraer cantidad, unidad y frecuencia de la dosis estructurada
            const partesDosis = med.dosisYFrecuencia.split(' ');
            if (partesDosis.length >= 3) {
              setDosisCantidadPersonalizada(partesDosis[0] || "1");
              setDosisUnidad(partesDosis[1] || "Cap");
              setFrecuencia(partesDosis.slice(2).join(' ') || "C/ 8 horas");
            }
          } else {
            setDosisPersonalizada(med.dosisYFrecuencia || "");
          }

          // Actualizar estados
          setMedicamento({
            ...medicamento,
            ...med,
            concentracionValor: valConcentracion,
            concentracionUnidad: uniConcentracion,
            duracion,
            cantidad
          });

          setItem({
            ...item,
            medicina: med.medicina || '',
            especialidad: med.especialidad || 'General',
            viaAdministracion: med.viaAdministracion || ''
          });

          setConcentracionValor(valConcentracion);
          setConcentracionUnidad(uniConcentracion);
          setDuracionTexto(duracion);
          setCantidadTexto(cantidad);

        } catch (error) {
          addToast("Error cargando datos: " + error.message, { appearance: "error" });
        }
      }
    };

    cargarDatosEdicion();
  }, [empresa.id, isEditing, location.state?.data?.id]);

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "medicina":
        setItem(prev => ({ ...prev, medicina: capitalize(value) }));
        break;

      case "especialidad":
        setItem(prev => ({ ...prev, especialidad: value }));
        break;

      case "concentracionValor": {
        const valorValidado = validarCampos(name, value, addToast);
        if (valorValidado !== null) {
          setConcentracionValor(valorValidado);
          setMedicamento(prev => ({ ...prev, concentracionValor: valorValidado }));
        }
        break;
      }

      case "concentracionUnidad":
        setConcentracionUnidad(value);
        setMedicamento(prev => ({ ...prev, concentracionUnidad: value }));
        break;

      case "dosisCantidadPersonalizada": {
        const valorValidado = validarCampos(name, value, addToast);
        if (valorValidado !== null) {
          setDosisCantidadPersonalizada(valorValidado);
        }
        break;
      }

      case "dosisUnidad":
        setDosisUnidad(value);
        break;

      case "frecuencia":
        setFrecuencia(value);
        if (value !== "Personalizado") {
          setFrecuenciaPersonalizada("");
        }
        break;

      case "frecuenciaPersonalizada":
        setFrecuenciaPersonalizada(value);
        break;

      case "dosisPersonalizada":
        setDosisPersonalizada(value);
        break;

      case "viaAdministracion":
        setItem(prev => ({ ...prev, viaAdministracion: value }));
        break;

      case "duracion": {
        const valorValidado = validarCampos(name, value, addToast);
        if (valorValidado !== null) {
          setDuracionTexto(valorValidado);
          setMedicamento(prev => ({ ...prev, duracion: valorValidado }));
        }
        break;
      }

      case "cantidad": {
        const valorValidado = validarCampos(name, value, addToast);
        if (valorValidado !== null) {
          setCantidadTexto(valorValidado);
          setMedicamento(prev => ({ ...prev, cantidad: valorValidado }));
        }
        break;
      }

      default:
        // Para campos que pertenecen directamente a medicamento
        if (name in medicamento) {
          setMedicamento(prev => ({ ...prev, [name]: value }));
        } else {
          setItem(prev => ({ ...prev, [name]: value }));
        }
        break;
    }
  };


  const handleActualizar = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {

      // Preparar datos para guardar
      const data = prepararDatosMedicamento({
        ...medicamento,
        ...item,
        concentracion: `${concentracionValor} ${concentracionUnidad}`,
        dosisYFrecuencia: usarDosisPersonalizada
          ? dosisPersonalizada
          : `${dosisCantidadPersonalizada} ${dosisUnidad} ${frecuencia === "Personalizado" ? frecuenciaPersonalizada : frecuencia
          }`,
        duracion: `${duracionTexto} días`,
        cantidad: parseInt(cantidadTexto) || 0
      }, empresa.id);

      let medicamentoId = location?.state?.data?.id;

      // Actualizar el medicamento seleccionado
      await updateMedicamentosM(medicamentoId, empresa.id, data);

      addToast("Medicamento actualizado");
      history.push("/admin/mantenimiento/medicamentos");
    } catch (error) {
      addToast(error.message, { appearance: "error" });
    } finally {
      setLoading(false);
    }
  };

  // Renderizado condicional para carga inicial
  if (sesion.loading) return <div>Cargando...</div>;
  if (!empresa?.id) return <div>Seleccione una empresa primero</div>;

  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Actualiza Medicamento</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <StyledForm onSubmit={handleActualizar}>
          <FormGrid>
            {/* Medicina */}
            <FormGroup>
              <StyledLabel htmlFor="medicina">Medicina</StyledLabel>
              <StyledInput
                name="medicina"
                placeholder="Ej: Amoxicilina"
                value={item.medicina}
                onChange={handleChange}
                required
              />
            </FormGroup>

            {/* Concentración */}
            <FormGroup>
              <StyledLabel>Concentración</StyledLabel>
              <InputGroup>
                <StyledInputNumber
                  name="concentracionValor"
                  placeholder="Valor"
                  value={concentracionValor}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  required
                />
                <StyledSelect
                  name="concentracionUnidad"
                  value={concentracionUnidad}
                  onChange={handleChange}
                >
                  {UNIDADES_CONCENTRACION.map(unidad => (
                    <option key={unidad} value={unidad}>{unidad}</option>
                  ))}
                </StyledSelect>
              </InputGroup>
            </FormGroup>

            {/* Formato */}
            <FormGroup>
              <StyledLabel htmlFor="especialidad">Formato</StyledLabel>
              <StyledSelect
                name="especialidad"
                value={item.especialidad}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Seleccione formato</option>
                {FORMATOS.map(formato => (
                  <option key={formato} value={formato}>{formato}</option>
                ))}
              </StyledSelect>
            </FormGroup>

            {/* Dosis y Frecuencia */}
            <FormGroup fullWidth>
              <DosisHeader>
                <StyledLabel>Dosis y Frecuencia</StyledLabel>
                <ToggleDosisButton
                  type="button"
                  onClick={toggleDosisPersonalizada}
                  active={usarDosisPersonalizada}
                >
                  {usarDosisPersonalizada ? "Asistente" : "Personalizar"}
                </ToggleDosisButton>
              </DosisHeader>

              {usarDosisPersonalizada ? (
                <StyledInput
                  name="dosisPersonalizada"
                  placeholder="Ej: 1 Cap C/ 8 horas / V. Oral"
                  value={dosisPersonalizada}
                  onChange={handleChange}
                  required
                />
              ) : (
                <FormGroup3columnas>
                  <InputGroup>
                    <StyledInputNumber
                      name="dosisCantidadPersonalizada"
                      value={dosisCantidadPersonalizada}
                      onChange={handleChange}
                      placeholder="Cantidad"
                    />
                    <StyledSelect
                      name="dosisUnidad"
                      value={dosisUnidad}
                      onChange={handleChange}
                    >
                      {DOSIS_UNIDAD.map(unidad => (
                        <option key={unidad} value={unidad}>{unidad}</option>
                      ))}
                    </StyledSelect>
                  </InputGroup>

                  <InputGroupF>
                    <StyledSelect
                      name="frecuencia"
                      value={frecuencia}
                      onChange={handleChange}
                    >
                      {FRECUENCIA_OPCIONES.map(opcion => (
                        <option key={opcion} value={opcion}>{opcion}</option>
                      ))}
                    </StyledSelect>

                    {frecuencia === "Personalizado" && (
                      <StyledInput
                        name="frecuenciaPersonalizada"
                        value={frecuenciaPersonalizada}
                        onChange={handleChange}
                        placeholder="Frecuencia personalizada"
                        required
                      />
                    )}
                  </InputGroupF>
                </FormGroup3columnas>
              )}
              <PreviewBox>
                <strong>Vista previa:</strong> {dosisFrecuenciaCompleta}
              </PreviewBox>
            </FormGroup>

            {/* Vía Administración */}
            <FormGroup>
              <StyledLabel>Vía de Administración</StyledLabel>
              <StyledSelect
                name="viaAdministracion"
                value={item.viaAdministracion}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Seleccione vía</option>
                {VIAS_ADMINISTRACION.map(via => (
                  <option key={via} value={via}>{via}</option>
                ))}
              </StyledSelect>
            </FormGroup>

            {/* Duración y Cantidad */}
            <FormGroup>
              <StyledLabel>Duración</StyledLabel>
              <InputWithUnit>
                <StyledInputNumber
                  name="duracion"
                  value={duracionTexto}
                  onChange={handleChange}
                  placeholder="Ej: 5"
                  required
                />
                <Unit>días</Unit>
              </InputWithUnit>
            </FormGroup>

            <FormGroup>
              <StyledLabel>Cantidad</StyledLabel>
              <InputWithUnit>
                <StyledInputNumber
                  name="cantidad"
                  value={cantidadTexto}
                  onChange={handleChange}
                  placeholder="Ej: 10"
                  required
                />
                <Unit>unidades</Unit>
              </InputWithUnit>
            </FormGroup>
          </FormGrid>

          <ActionButtons>
            <LinkCancelar type="button" onClick={() => history.goBack()}>
              Cancelar
            </LinkCancelar>
            <StyledButton type="submit" disabled={loading}>
              Guardar
            </StyledButton>
          </ActionButtons>
        </StyledForm>
      </ContainerBody>
    </MainContainer>
  );
};