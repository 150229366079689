import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  ContainerLeft,
  StyledForm,
  DivInput,
  StyledLabel,
  StyledInput,
  StyledInputNumber,
  StyledSelect,
  StyledButton,
  LinkCancelar,
  FormGrid,
  FormGroup,
  InputGroup,
  DosisHeader,
  ToggleDosisButton,
  PreviewBox,
  InputGroupF,
  InputWithUnit,
  Unit,
  FormGroup3columnas,
  ActionButtons
} from "./styles";

import { addMedicamentosM } from "../../services/firebase/Medicamentos/index";
import { useLocation } from "react-router-dom";

// Constantes de opciones para el formulario
const FORMATOS = [
  "CAP", // Cápsulas
  "TB",  // Tabletas
  "AMP", // Ampollas
  "JBE", // Jarabe
  "SOL", // Solución
  "CRM", // Crema
  "GTS", // Gotas
  "SUP", // Supositorios
  "PLV", // Polvo
  "INH", // Inhalador
  "FR",  // Frasco
];

const UNIDADES_CONCENTRACION = [
  "mg", "g", "mcg", "UI", "mEq", "mg/ml", "%", "mg/g", "mmol"
];

const DOSIS_UNIDAD = [
  "Cap", "TB", "ml", "gotas", "ampolla", "inhalación",
  "aplicación", "cucharada", "sobre", "cápsula", "parche"
];

const FRECUENCIA_OPCIONES = [
  "C/ 4 horas", "C/ 6 horas", "C/ 8 horas", "C/ 12 horas", "C/ 24 horas",
  "1 vez al día", "2 veces al día", "3 veces al día", "4 veces al día",
  "Cada noche", "Según necesidad", "Antes de comidas",
  "Con las comidas", "Después de comidas", "Personalizado"
];

const VIAS_ADMINISTRACION = [
  "V. Oral", "V. Intramuscular", "V. Intravenosa", "V. Subcutánea",
  "V. Tópica", "V. Oftálmica", "V. Ótica", "V. Nasal", "V. Rectal", "Iny"
];

// Función de utilidad para capitalizar texto
const capitalize = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Hook personalizado para tratamiento
const useTratamiento = (tratamiento) => {
  if (!tratamiento.color) {
    tratamiento.color = "#000";
    tratamiento.afectaOdonto = false;
    tratamiento.grupo = "NONE";
    tratamiento.codtra = "";
  }

  const [item, setItem] = React.useState(tratamiento);
  return [item, setItem];
};

export const NuevoMedicamentos = () => {
  // Acceso al estado global
  const sesion = useSelector((state) => state.sesion);
  const subGrupos = useSelector((state) => state.subGrupos);
  const empresa = useSelector((state) => state.empresa);
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();

  // Estado para tarifarios
  const [tarifario, setTarifario] = React.useState([]);

  // Estado inicial del tratamiento
  const tratamiento = {
    id: "",
    codtra: "",
    especialidad: "", // Este campo se usará para el formato/presentación
    tratamiento: "", // Nombre del medicamento
    tipo: "", // Concentración (valor completo)
    precio: 0, // Cantidad
    costo: 0,
    tiempo: 0, // Duración en días
    empresaId: "",
    codLab: "",
    estado: "",
    subGrupoId: "", // Este se usará para la dosis y frecuencia
    viaAdministracion: "", // Nueva propiedad para vía de administración
    readOnly: false
  };

  // Estados para componentes de formulario
  const [item, setItem] = useTratamiento(tratamiento);
  const [concentracionValor, setConcentracionValor] = React.useState("");
  const [concentracionUnidad, setConcentracionUnidad] = React.useState("mg");
  const [dosisCantidad, setDosisCantidad] = React.useState("1");
  const [dosisUnidad, setDosisUnidad] = React.useState("Cap");
  const [frecuencia, setFrecuencia] = React.useState("C/ 8 horas");
  const [dosisPersonalizada, setDosisPersonalizada] = React.useState("");
  const [usarDosisPersonalizada, setUsarDosisPersonalizada] = React.useState(false);
  const [duracionTexto, setDuracionTexto] = React.useState("");
  const [cantidadTexto, setCantidadTexto] = React.useState("");
  const [dosisCantidadPersonalizada, setDosisCantidadPersonalizada] = React.useState("1");
  const [frecuenciaPersonalizada, setFrecuenciaPersonalizada] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const medicamentoData = location.state?.data;
  const isEditing = !!medicamentoData;
  // Cargar tarifarios al inicio
  const [currentMedId, setCurrentMedId] = React.useState("");

  // Corregido: Eliminado el error de expresión no utilizada
  React.useEffect(() => {
    if (isEditing && medicamentoData) {
      const { id, medicina, formato, concentracion, dosisYFrecuencia, viaAdministracion, duracion, cantidad, estado } = medicamentoData;
      
      setCurrentMedId(id);
      setItem(prev => ({
        ...prev,
        tratamiento: medicina,
        especialidad: formato,
        viaAdministracion: viaAdministracion,
        precio: cantidad,
        tiempo: parseInt(duracion.replace(' días', '')),
        estado: estado || "ACTIVO"
      }));

      const [valor, unidad] = concentracion.split(' ');
      setConcentracionValor(valor);
      setConcentracionUnidad(unidad || 'mg');
      setDuracionTexto(duracion.replace(' días', ''));
      setCantidadTexto(cantidad.toString());

      if (dosisYFrecuencia.includes('/')) {
        const [dosisPart] = dosisYFrecuencia.split(' / ');
        const dosisMatch = dosisPart.match(/(\d+)\s+(\w+)\s+(.*)/);
        
        if (dosisMatch) {
          const [, cantidad, unidad, frecuencia] = dosisMatch;
          setDosisCantidadPersonalizada(cantidad);
          setDosisUnidad(unidad);

          // CORRECCIÓN PRINCIPAL: Reemplazado el operador ternario problemático
          if (FRECUENCIA_OPCIONES.includes(frecuencia)) {
            setFrecuencia(frecuencia);
          } else {
            setFrecuencia("Personalizado");
            setFrecuenciaPersonalizada(frecuencia);
          }
        }
      } else {
        setUsarDosisPersonalizada(true);
        setDosisPersonalizada(dosisYFrecuencia);
      }
    }
  }, [isEditing, medicamentoData]);

  // Actualizar concentración cuando cambian los componentes
  React.useEffect(() => {
    if (concentracionValor) {
      setItem({ ...item, tipo: `${concentracionValor} ${concentracionUnidad}` });
    }
  }, [concentracionValor, concentracionUnidad]);

  // Calcular dosis y frecuencia completa
  const dosisFrecuenciaCompleta = React.useMemo(() => {
    if (usarDosisPersonalizada) {
      return dosisPersonalizada;
    } else {
      const dosisCantidadStr = dosisCantidadPersonalizada || "";
      const frecuenciaFinal = frecuencia === "Personalizado" ? frecuenciaPersonalizada : frecuencia;
      return `${dosisCantidadStr} ${dosisUnidad} ${frecuenciaFinal} `;
    }
  }, [dosisCantidadPersonalizada, dosisUnidad, frecuencia, frecuenciaPersonalizada, usarDosisPersonalizada, dosisPersonalizada]);

  // Manejar cambios en campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "precio":
        setItem({ ...item, [name]: Number(value) });
        break;

      case "especialidad":
        setItem({ ...item, [name]: value.toUpperCase() });
        break;

      case "tratamiento":
        setItem({ ...item, [name]: capitalize(value) });
        break;

      case "concentracionValor":
        setConcentracionValor(value.replace(/\D/g, ''));
        break;

      case "concentracionUnidad":
        setConcentracionUnidad(value);
        break;

      case "dosisCantidad":
        setDosisCantidad(value);
        setDosisCantidadPersonalizada(value);
        break;

      case "dosisCantidadPersonalizada":
        if (value === "" || /^\d*$/.test(value)) {
          setDosisCantidadPersonalizada(value);
        }
        break;

      case "dosisUnidad":
        setDosisUnidad(value);
        break;

      case "frecuencia":
        setFrecuencia(value);
        if (value !== "Personalizado") {
          setFrecuenciaPersonalizada("");
        }
        break;

      case "frecuenciaPersonalizada":
        setFrecuenciaPersonalizada(value);
        break;

      case "dosisPersonalizada":
        setDosisPersonalizada(value);
        break;

      case "viaAdministracion":
        setItem({ ...item, viaAdministracion: value });
        break;

      case "duracion": {
        const duracionRaw = value.replace(/\D/g, ''); // Eliminar no numéricos

        // Bloquear 0 como primer carácter
        if (duracionRaw.startsWith("0") && duracionRaw.length > 0) {
          addToast("No se permite escribir 0 como primer carácter", { appearance: "error" });
          return; // Detener la ejecución
        }

        // Si el valor está vacío
        if (duracionRaw === "") {
          setDuracionTexto("");
          setItem({ ...item, tiempo: 0 });
          return;
        }

        const numericValue = parseInt(duracionRaw, 10);

        // Validar número válido
        if (numericValue < 1) {
          addToast("Solo números mayores o iguales a 1", { appearance: "error" });
        } else {
          setDuracionTexto(duracionRaw);
          setItem({ ...item, tiempo: numericValue });
        }
        break;
      }

      case "cantidad": {
        const cantidadRaw = value.replace(/\D/g, ''); // Eliminar no numéricos

        // Bloquear 0 como primer carácter
        if (cantidadRaw.startsWith("0") && cantidadRaw.length > 0) {
          addToast("No se permite escribir 0 como primer carácter", { appearance: "error" });
          return; // Detener la ejecución
        }

        // Si el valor está vacío
        if (cantidadRaw === "") {
          setCantidadTexto("");
          setItem({ ...item, precio: 0 });
          return;
        }

        const numericValue = parseInt(cantidadRaw, 10);

        // Validar número válido
        if (numericValue < 1) {
          addToast("Solo números mayores o iguales a 1", { appearance: "error" });
        } else {
          setCantidadTexto(cantidadRaw);
          setItem({ ...item, precio: numericValue });
        }
        break;
      }

      default:
        setItem({ ...item, [name]: value });
        break;
    }
  };

  React.useEffect(() => {
    if (isEditing && medicamentoData) {
      // Parsear datos del medicamento al estado del formulario
      const {
        medicina,
        formato,
        concentracion,
        dosisYFrecuencia,
        viaAdministracion,
        duracion,
        cantidad,
        estado
      } = medicamentoData;

      // Actualizar estado principal
      setItem(prev => ({
        ...prev,
        tratamiento: medicina,
        especialidad: formato,
        viaAdministracion: viaAdministracion,
        precio: cantidad,
        tiempo: parseInt(duracion.replace(' días', '')),
        estado: estado || "ACTIVO"
      }));

      // Procesar concentración
      const [valor, unidad] = concentracion.split(' ');
      setConcentracionValor(valor);
      setConcentracionUnidad(unidad || 'mg');

      // Procesar duración y cantidad
      setDuracionTexto(duracion.replace(' días', ''));
      setCantidadTexto(cantidad.toString());

      // Procesar dosis y frecuencia
      if (dosisYFrecuencia.includes('/')) {
        const [dosisPart, viaPart] = dosisYFrecuencia.split(' / ');
        const dosisMatch = dosisPart.match(/(\d+)\s+(\w+)\s+(.*)/);

        if (dosisMatch) {
          const [, cantidad, unidad, frecuencia] = dosisMatch;
          setDosisCantidadPersonalizada(cantidad);
          setDosisUnidad(unidad);

          const frecuenciaIndex = FRECUENCIA_OPCIONES.findIndex(
            f => f === frecuencia
          );

          if (frecuenciaIndex !== -1) {
            setFrecuencia(frecuencia);
          } else {
            setFrecuencia("Personalizado");
            setFrecuenciaPersonalizada(frecuencia);
          }
        }
      } else {
        setUsarDosisPersonalizada(true);
        setDosisPersonalizada(dosisYFrecuencia);
      }
    }
  }, [isEditing, medicamentoData]);
  // Validación de teclas para campos numéricos
  const handleKeyPress = (e) => {
    if (!/\d/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== 'Tab') {
      e.preventDefault();
      addToast("Solo se permiten números en este campo", { appearance: "warning" });
    }
  };

  // Cambiar entre dosis personalizada y estructurada
  const toggleDosisPersonalizada = () => {
    setUsarDosisPersonalizada(!usarDosisPersonalizada);
  };
  const validarCampos = () => {
    if (!item.tratamiento || !item.especialidad || !item.viaAdministracion) {
      addToast("Por favor complete todos los campos obligatorios", { appearance: "error" });
      return false;
    }
    
    if (usarDosisPersonalizada && !dosisPersonalizada) {
      addToast("Por favor ingrese la dosis y frecuencia", { appearance: "error" });
      return false;
    }
    
    if (!usarDosisPersonalizada && !dosisUnidad) {
      addToast("Por favor seleccione una unidad de dosis", { appearance: "error" });
      return false;
    }
    
    if (!usarDosisPersonalizada && frecuencia === "Personalizado" && !frecuenciaPersonalizada) {
      addToast("Por favor ingrese la frecuencia personalizada", { appearance: "error" });
      return false;
    }
    
    if (!duracionTexto || parseInt(duracionTexto) < 1) {
      addToast("Por favor ingrese una duración válida", { appearance: "error" });
      return false;
    }
    
    if (!cantidadTexto || parseInt(cantidadTexto) < 1) {
      addToast("Por favor ingrese una cantidad válida", { appearance: "error" });
      return false;
    }
    
    return true;
  };
  
  // Guardar formulario
  const handleGuardar = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!validarCampos()) {
        setLoading(false);
        return;
      }
      
      // Preparar datos para guardar en Medicamentos
      const medicamentoData = {
        medicina: item.tratamiento,
        formato: item.especialidad,
        concentracion: `${concentracionValor} ${concentracionUnidad}`,
        dosisYFrecuencia: dosisFrecuenciaCompleta,
        viaAdministracion: item.viaAdministracion,
        duracion: `${duracionTexto} días`,
        cantidad: parseInt(cantidadTexto)
      };
      await dispatch(addMedicamentosM(empresa.id, medicamentoData));
      addToast("Medicamento guardado correctamente", { appearance: "success" });
      
      // Redirigir al usuario
      history.push("/admin/mantenimiento/medicamentos");
    } catch (err) {
      console.error("Error al guardar medicamento:", err);
      addToast(`Error: ${err.message || "Ocurrió un error al guardar"}`, { appearance: "error" });
      setLoading(false);
    }
  };
  // Mostrar carga o error
  if (sesion.loading || sesion.loading === undefined)
    return <div>...procesando</div>;
  if (sesion.error)
    return <div>{`Hubo un error ${sesion.error}`}</div>;


  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Nuevo Medicamento</ContainerTitleText>

      </ContainerTitle>
      <ContainerBody>
        <StyledForm onSubmit={handleGuardar}>

          <FormGrid>
            {/* Medicina */}
            <FormGroup>
              <StyledLabel htmlFor="tratamiento">Medicina</StyledLabel>
              <StyledInput
                name="tratamiento"
                placeholder="Ej: Amoxicilina"
                value={item.tratamiento}
                onChange={handleChange}
                required
              />
            </FormGroup>

            {/* Concentración */}
            <FormGroup>
              <StyledLabel>Concentración</StyledLabel>
              <InputGroup>
                <StyledInputNumber
                  name="concentracionValor"
                  placeholder="Valor"
                  value={concentracionValor}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  required
                />
                <StyledSelect
                  name="concentracionUnidad"
                  value={concentracionUnidad}
                  onChange={handleChange}
                >
                  {UNIDADES_CONCENTRACION.map(unidad => (
                    <option key={unidad} value={unidad}>{unidad}</option>
                  ))}
                </StyledSelect>
              </InputGroup>
            </FormGroup>

            {/* Formato */}
            <FormGroup>
              <StyledLabel htmlFor="especialidad">Formato</StyledLabel>
              <StyledSelect
                name="especialidad"
                value={item.especialidad}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Seleccione formato</option>
                {FORMATOS.map(formato => (
                  <option key={formato} value={formato}>{formato}</option>
                ))}
              </StyledSelect>
            </FormGroup>

            {/* Dosis y Frecuencia */}
            {/* Dosis y Frecuencia */}
            <FormGroup fullWidth>
              <DosisHeader>
                <StyledLabel>Dosis y Frecuencia</StyledLabel>
                <ToggleDosisButton
                  type="button"
                  onClick={toggleDosisPersonalizada}
                  active={usarDosisPersonalizada}
                >
                  {usarDosisPersonalizada ? "Asistente" : "Personalizar"}
                </ToggleDosisButton>
              </DosisHeader>

              {usarDosisPersonalizada ? (
                <StyledInput
                  name="dosisPersonalizada"
                  placeholder="Ej: 1 Cap C/ 8 horas / V. Oral"
                  value={dosisPersonalizada}
                  onChange={handleChange}
                  required
                />
              ) : (
                <FormGroup3columnas>
                  <InputGroup>
                    <StyledInputNumber
                      name="dosisCantidadPersonalizada"
                      value={dosisCantidadPersonalizada}
                      onChange={handleChange}
                      placeholder="Cantidad"
                    />
                    <StyledSelect
                      name="dosisUnidad"
                      value={dosisUnidad}
                      onChange={handleChange}
                    >
                      {DOSIS_UNIDAD.map(unidad => (
                        <option key={unidad} value={unidad}>{unidad}</option>
                      ))}
                    </StyledSelect>
                  </InputGroup>

                  <InputGroupF>
                    <StyledSelect
                      name="frecuencia"
                      value={frecuencia}
                      onChange={handleChange}
                    >
                      {FRECUENCIA_OPCIONES.map(opcion => (
                        <option key={opcion} value={opcion}>{opcion}</option>
                      ))}
                    </StyledSelect>

                    {frecuencia === "Personalizado" && (
                      <StyledInput
                        name="frecuenciaPersonalizada"
                        value={frecuenciaPersonalizada}
                        onChange={handleChange}
                        placeholder="Frecuencia personalizada"
                        required
                      />
                    )}
                  </InputGroupF>
                </FormGroup3columnas>
              )}
              <PreviewBox>
                <strong>Vista previa:</strong> {dosisFrecuenciaCompleta}
              </PreviewBox>
            </FormGroup>

            {/* Vía Administración */}
            <FormGroup>
              <StyledLabel>Vía de Administración</StyledLabel>
              <StyledSelect
                name="viaAdministracion"
                value={item.viaAdministracion}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Seleccione vía</option>
                {VIAS_ADMINISTRACION.map(via => (
                  <option key={via} value={via}>{via}</option>
                ))}
              </StyledSelect>
            </FormGroup>

            {/* Duración y Cantidad */}
            <FormGroup>
              <StyledLabel>Duración</StyledLabel>
              <InputWithUnit>
                <StyledInputNumber
                  name="duracion"
                  value={duracionTexto}
                  onChange={handleChange}
                  placeholder="Ej: 5"
                  required
                />
                <Unit>días</Unit>
              </InputWithUnit>
            </FormGroup>

            <FormGroup>
              <StyledLabel>Cantidad</StyledLabel>
              <InputWithUnit>
                <StyledInputNumber
                  name="cantidad"
                  value={cantidadTexto}
                  onChange={handleChange}
                  placeholder="Ej: 10"
                  required
                />
                <Unit>unidades</Unit>
              </InputWithUnit>
            </FormGroup>
          </FormGrid>

          <ActionButtons>
            <LinkCancelar type="button" onClick={() => history.goBack()}>
              Cancelar
            </LinkCancelar>
            <StyledButton type="submit" disabled={loading}>
              Guardar
            </StyledButton>
          </ActionButtons>
        </StyledForm>
      </ContainerBody>
    </MainContainer>
  );
};